import React, {
  useEffect,
  useState,
} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IoReload } from "react-icons/io5";
import { CiWarning } from "react-icons/ci";
import h1 from "../images/h1.png";
import h2 from "../images/h2.png";
import Dropzone from "react-dropzone";
import { IoMdFemale } from "react-icons/io";
import { IoMdMale } from "react-icons/io";
import { IoMaleFemaleSharp } from "react-icons/io5";
import {useDispatch,useSelector,
} from "react-redux";
import { FaAngleDown } from "react-icons/fa";
import InputRadioCustom from "../components/InputRadioCustom";
import {delImg, resetImages,uploadImg,
} from "../features/upload/uploadSlice";
import { DatePicker,} from "antd";
import { addRegisterExprience, getAllExprience } from "../features/registerExprience/resExpriSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { base_url } from "../utils/base_url";
import { config } from "../utils/axiosconfig";
import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;
export default function RegisterExperience({
  t,
}) {
  const nhomtuoi = [
    {
      title: t("treem"),
      value: "Trẻ em",
      id: t("treem"),
    },
    {
      title: "10" + " " + t("u"),
      value: "10 tuổi",
      id: "10" + " " + t("u"),
    },
    {
      title: "20" + " " + t("u"),
      value: "20 tuổi",
      id: "20" + " " + t("u"),
    },
    {
      title: "30" + " " + t("u"),
      value: "30 tuổi",
      id: "30" + " " + t("u"),
    },
    {
      title: "40" + " " + t("u"),
      value: "40 tuổi" ,
      id: "40" + " " + t("u"),
    },
    {
      title:
        "50" +
        " " +
        t("u") +
        t("trolen"),
      value:
        "50 tuổi",
      id:
        "50" +
        " " +
        t("u") +
        t("trolen"),
    },
  ];
  const gioitinh = [
    {
      title: (
        <>
          <IoMdFemale /> {t("nu")}
        </>
      ),
      value: t("nu"),
      id: t("nu"),
    },
    {
      title: (
        <>
          <IoMdMale /> {t("nam")}{" "}
        </>
      ),
      value: t("nam"),
      id: t("nam"),
    },
    {
      title: (
        <>
          <IoMaleFemaleSharp />{" "}
          {t("khong_phan_biet")}
        </>
      ),
      value: t("khong_phan_biet"),
      id: t("khong_phan_biet"),
    },
  ];
  const phamvi = [
    {
      title: t("tn_rv_nb"),
      value: t("tn_rv_nb"),
      id: t("tn_rv_nb"),
    },
    {
      title: t("tn_SNS"),
      value: t("tn_SNS"),
      id: t("tn_SNS"),
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(getAllExprience())
  }, []);
  const [isDirty, setIsDirty] = useState(false);
  const [check,setCheck] = useState(false)

  const user = useSelector(state=>state.auth.user)
  const experian = useSelector(state=>state.registerPri.resExpri?.getAll) 
  const getAll = experian?.filter(e=>e?.type ==="Bản nháp" && e?.registerBy?._id === user?._id)
  console.log(getAll)
  const [selectedAgeMT, setSelectedAgeMT] = useState([]);
  const [
    selectedGioitinh,
    setSelectedGioitinh,
  ] = useState(t("nu"));
  const [
    selectedPhamvi,
    setSelectedPhamvi,
  ] = useState(t("tn_rv_nb"));
  const [type, setType] = useState(
    "Bản chính"
  );
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedAgeMT(prevSelectedAges => {
      const newSelectedAges = checked
        ? [...prevSelectedAges, value]
        : prevSelectedAges.filter(age => age !== value);

      formik.setFieldValue('age', newSelectedAges);

      return newSelectedAges;
    });
  };
  let userSchema = Yup.object().shape({
    titleProject: Yup.string().required(
      t("yc_nhap_ten_da")
    ),
    products: Yup.array()
      .of(
        Yup.object().shape({
          brand: Yup.string().required(
            t("yc_nhap_thuong_hieu")
          ),
          typeProduct:
            Yup.string().required(
              t("yc_nhap_loai_sp")
            ),
          titleProduct:
            Yup.string().required(
              t("yc_nhap_ten_sp")
            ),
          trongluong:
            Yup.string().required(
              t("yc_nhap_trong_luong")
            ),
          theloai:
            Yup.string().required(
              t("yc_nhap_the_loai")
            ),
        })
      )
      .min(1, t("yc_them_sp")),
    count: Yup.string().required(
      t("yc_nhap_so_luong")
    ),
  });
  const auth = useSelector(
    (state) => state.auth.user
  );
  const up = async (
    acceptedFiles,
    productIndex
  ) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) =>
      formData.append("images", file)
    ); // Make sure the field name matches

    try {
      const res = await axios.post(
        `${base_url}upload/upload`,
        formData,
        config
      );
      const updatedImages = res.data;
      formik.setFieldValue(
        `products[${productIndex}].imageProduct`,
        updatedImages
      );
      console.log(res.data);
      // Handle response, e.g., update formik field
    } catch (error) {
      console.error(
        "Error uploading file:",
        error
      );
      toast.error(t("upload_failed"));
    }
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        const message = 'Tất cả nội dung bạn đã viết sẽ bị xóa. Bạn vẫn tiếp tục?';
        event.returnValue = message; // Standard for modern browsers
        console.log(message)
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);
  const handleRemoveLastProduct = () => {
    // Kiểm tra nếu có ít nhất một sản phẩm
    if (formik.values.products.length > 0) {
      // Loại bỏ phần tử cuối cùng
      const updatedProducts = formik.values.products.slice(0, -1);
      formik.setFieldValue("products", updatedProducts);
    }
  };
  
  const formik = useFormik({
    initialValues: {
      titleProject:  "",
      products: [
        {
          brand: "",
          typeProduct: t("skincare"),
          titleProduct: "",
          trongluong: "",
          theloai: "",
          imageProduct: "",
        },
      ],
      count: "",
      startDate:  "",
      endDate:"",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      console.log("da ",values)
      if (auth !== null) {
        const hasImagesInProducts =
          values.products.every(
            (product) =>
              product.imageProduct
                .length > 0
          );
        if (!hasImagesInProducts) {
          toast.warning(
            t("ban_chua_chon_anh")
          );
          return; // Prevent form submission
        }
        if(values.count !=="" || values.titleProject !=="" || values.endDate !=="" || values.startDate !==""){
          setCheck(true)
        }else{
          setCheck(false)
        }
          const dataRes = {
            titleProject:
              values.titleProject,
            infoProduct:
              values.products,
            count: values.count,
            age: selectedAgeMT,
            sex: selectedGioitinh,
            phamvi: selectedPhamvi,
            schedule: {
              startDate:
                values.startDate,
              endDate: values.endDate,
            },
            type: type,
          };
          console.log("dtaa ",dataRes)
          dispatch(
            addRegisterExprience(dataRes)
          );
          setIsDirty(false)
          setTimeout(() => {
            formik.resetForm();
            dispatch(resetImages());
            toast.success(t("save"));
            setSelectedAgeMT(
              []
            );
            setSelectedGioitinh(
              t("nu")
            );
            setSelectedPhamvi(
              t("tn_rv_nb")
            );
          }, 300);
    
      } else {
        toast.warning(t("ban_chua_dn"));
      }
    },
  });

  const onOk = (value) => {
    console.log("onOk: ", value);
  };
  const naviagate = useNavigate()
  const options = getAll && getAll?.length > 0
  ? getAll?.map(draft => (
      <option key={draft._id} value={draft.titleProject}>
        {draft.titleProject}
      </option>
    ))
  : <option value="">{t("tao_moi")}</option>;
  return (
    <>
      <section className="container py-5">
        <div className="row text-white">
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: 1 }}>
            <h1 className="py-3 display-4" style={{fontSize:"70px"}}>
              {t("xin_chao")}
            </h1>
            <p
              className="py-4 fs-5"
              style={{fontSize:"40px"}}
              dangerouslySetInnerHTML={{
                __html: t(
                  "ban_muon_dang_ky"
                ),
              }}></p>
            <div className="d-flex align-items-end" onClick={()=>naviagate(`/truyxuat-register`)}>
              <button className="btn_cus d-flex align-items-center">
                <IoReload className="me-3" />
                <span className="d-block">
                  {t("truy_xuat")}
                </span>
              </button>
              <span className="ps-3">
                {t(
                  "truy_xuat_thong_tin"
                )}
              </span>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: "0" }}>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: "47px",
              }}>
              <img
                src={h1}
                alt=""
                width="80%"
              />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: "40px",
              }}>
              <img src={h2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <hr className="text-white"/>
      <section className="container py-4 text-white">
        <form
          onSubmit={
            formik.handleSubmit
          }>
          <div className="row py-3">
            <h1 className="pb-4" style={{fontSize:"60px"}}>
              {t("thong_tin_du_an")}
            </h1>

            <div className="col-lg-8 col-md-8 position-relative">
              <div className="d-flex flex-column align-items-center mb-3 w100">
                <div
                  className="gap-3 align-items-center justify-content-between w100"
                  style={{
                    height: "50px",
                  }}>
                  <div className="d-flex gap-3 align-items-center justify-content-between">
                    <label
                      htmlFor={t(
                        "ten_du_an"
                      )}
                      className="me-3 mb-2"
                      style={{
                        flex: 1,
                      }}>
                      {t("ten_du_an")}
                    </label>
                    <div
                      style={{
                        position:
                          "relative",
                        display:
                          "inline-block",
                        width: "40%",
                      }}>
                      <select
                        className="form-select input_custom"
                        aria-label="Default select example"
                        style={{
                          flex: 3,
                          width: "100%",
                          borderRadius:
                            "24px",
                          background:
                            "#1a1a1a",
                          color:
                            "white",
                          outline:
                            "none",
                          border:
                            "2px solid #fff",
                          appearance:
                            "none", // Hide the default dropdown arrow
                          paddingRight:
                            "2.5rem", // Add space for the icon
                        }}>
                         <option
                          selected>
                          {t("tao_moi")}
                        </option>
                        <option
                          value={t(
                            "tao_cu"
                          )}>
                          {t("tao_cu")}
                        </option>
                        <option
                          value={t(
                            "ban_nhap"
                          )}>
                          {t(
                            "ban_nhap"
                          )}
                        </option>
                      </select>
                      <div
                        style={{
                          position:
                            "absolute",
                          top: "50%",
                          right: "1rem", // Adjust the position from the right
                          transform:
                            "translateY(-50%)",
                          pointerEvents:
                            "none", // Allow clicks to pass through to the select element
                        }}>
                        <FaAngleDown />
                      </div>
                    </div>
                    <input
                      style={{
                        flex: 3,
                      }}
                      type="text"
                      placeholder={t(
                        "ten_du_an"
                      )}
                      className="input_custom"
                      value={
                        formik.values
                          .titleProject
                      }
                      onChange={formik.handleChange(
                        "titleProject"
                      )}
                      onBlur={formik.handleBlur(
                        "titleProject"
                      )}
                    />
                  </div>
                </div>
                <div
                  className="error mb-3 d-flex justify-content-end"
                  style={{
                    top: "100%",
                  }}>
                  {formik.touched
                    .titleProject &&
                  formik.errors
                    .titleProject ? (
                    <div>
                      {
                        formik.errors
                          .titleProject
                      }
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4"></div>
          </div>
          <div className="py-5">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h1 className="mb-3" style={{fontSize:"60px"}}>
                {t("nhap_thong_tin_sp")}
              </h1>
              <div className="">
                <div className="boxed">
                  <button
                    className="btn_add"
                    onClick={() => {
                      const newProduct = {
                        brand: "",
                        typeProduct:
                          t("skincare"),
                        titleProduct: "",
                        trongluong: "",
                        theloai: "",
                        imageProduct: "",
                      };
                      formik.setFieldValue(
                        "products",
                        [
                          ...formik.values
                            .products,
                          newProduct,
                        ]
                      );
                    }}>
                    {t("them_san_pham")}
                  </button>
                </div>
                <button
                  className="btn btn-primary mt-3"
                  style={{width:"178px",borderRadius:"24px"}}
                  onClick={handleRemoveLastProduct}
                >
                  {t("xoa_san_pham")}
                </button>
              </div>
            </div>
          </div>
          {formik.values.products?.map(
            (product, index) => (
              <div key={index}>
                <h3 className="pb-4 mb-5" style={{fontSize:"60px"}}>
                  {t("thong_tin_sp")}{" "}
                  {index + 1}
                </h3>
                <div className="row pb-4 ">
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].brand`}>
                      {t(
                        "ten_thuong_hieu"
                      )}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        style={{
                          width: "100%",
                        }}
                        placeholder={t(
                          "ten_thuong_hieu"
                        )}
                        className="input_custom"
                        value={
                          product.brand
                        }
                        onChange={formik.handleChange(
                          `products[${index}].brand`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].brand`
                        )}
                        name={`products[${index}].brand`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.brand &&
                      formik.errors
                        .products?.[
                        index
                      ]?.brand ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].brand
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3 ">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].typeProduct`}>
                      {t("loai_sp")}
                    </label>
                    <div
                      className="w70"
                      style={{
                        position:
                          "relative",
                      }}>
                      <select
                        className="form-select input_custom"
                        aria-label="Default select example"
                        defaultValue={
                          "Skincare"
                        }
                        style={{
                          width: "100%",
                          borderRadius:
                            "24px",
                          background:
                            "#1a1a1a",
                          color:
                            "white",
                          outline:
                            "none",
                          border:
                            "2px solid #fff",
                        }}
                        value={
                          product.typeProduct
                        }
                        onChange={
                          formik.handleChange
                        }
                        onBlur={
                          formik.handleBlur
                        }
                        name={`products[${index}].typeProduct`}>
                        <option
                          value={t(
                            "skincare"
                          )}>
                          {t(
                            "skincare"
                          )}
                        </option>
                        <option
                          value={t(
                            "doan"
                          )}>
                          {t("doan")}
                        </option>
                        <option
                          value={t(
                            "dogiadung"
                          )}>
                          {t(
                            "dogiadung"
                          )}
                        </option>
                        <option
                          value={t(
                            "sach"
                          )}>
                          {t("sach")}
                        </option>
                      </select>
                      <div
                        style={{
                          position:
                            "absolute",
                          top: "50%",
                          right: "1rem", // Adjust the position from the right
                          transform:
                            "translateY(-50%)",
                          pointerEvents:
                            "none", // Allow clicks to pass through to the select element
                        }}>
                        <FaAngleDown />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3 ">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].titleProduct`}>
                      {t("ten_sp")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        style={{
                          width: "100%",
                        }}
                        placeholder={t(
                          "ten_sp"
                        )}
                        className="input_custom"
                        value={
                          product.titleProduct
                        }
                        onChange={formik.handleChange(
                          `products[${index}].titleProduct`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].titleProduct`
                        )}
                        name={`products[${index}].titleProduct`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.titleProduct &&
                      formik.errors
                        .products?.[
                        index
                      ]
                        ?.titleProduct ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ]
                              .titleProduct
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].trongluong`}>
                      {t("trong_luong")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        style={{
                          width: "100%",
                        }}
                        placeholder={t(
                          "trong_luong"
                        )}
                        className="input_custom"
                        value={
                          product.trongluong
                        }
                        onChange={formik.handleChange(
                          `products[${index}].trongluong`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].trongluong`
                        )}
                        name={`products[${index}].trongluong`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.trongluong &&
                      formik.errors
                        .products?.[
                        index
                      ]?.trongluong ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].trongluong
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].theloai`}>
                      {t("the_loai")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        style={{
                          width: "100%",
                        }}
                        placeholder={t(
                          "theloai_"
                        )}
                        className="input_custom"
                        value={
                          product.theloai
                        }
                        onChange={
                          formik.handleChange
                        }
                        onBlur={
                          formik.handleBlur
                        }
                        name={`products[${index}].theloai`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.theloai &&
                      formik.errors
                        .products?.[
                        index
                      ]?.theloai ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].theloai
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].imageProduct`}>
                      {t("them_ha")}
                    </label>
                    <div
                      className="w70 d-flex justify-content-between"
                      style={{
                        height: "100%",
                      }}>
                      <div
                        className="img_post w70 d-flex align-items-center justify-content-between text-center"
                        style={{
                          height:
                            "100%",
                        }}>
                      <section className="image-section">
                        {product.imageProduct &&
                          product.imageProduct.map((i, j) => {
                            const fileName = i.url.split('/').pop().split('?')[0];
                            return (
                              <div className="position-relative" key={j}>
                                <button
                                  type="button"
                                  onClick={() => dispatch(delImg(i.public_id))}
                                  className="btn-close position-absolute"
                                  style={{
                                    top: "10px",
                                    right: "10px",
                                  }}
                                ></button>
                                <p className="file-name mb-0">{fileName}</p>
                              </div>
                            );
                          })}
                      </section>


                      </div>
                      <Dropzone
                        onDrop={(
                          acceptedFiles
                        ) => {
                          if (
                            auth !==
                            null
                          ) {
                            up(
                              acceptedFiles,
                              index
                            );
                          } else {
                            toast.warning(
                              t(
                                "ban_chua_dn"
                              )
                            );
                          }
                        }}>
                        {({
                          getRootProps,
                          getInputProps,
                        }) => (
                          <div className="">
                            <div
                              {...getRootProps()}
                              className="upload-button">
                              <input
                                {...getInputProps()}
                              />
                              <button
                                type="button"
                                className="btn_upload">
                                {t(
                                  "tai_len"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
          <hr className="text-white" />
          <div className="py-4 mt-5">
            <div>
              <h1 style={{fontSize:"60px"}}>{t("i4_muctieu")}</h1>
              <p>
                {t("vui_long_chon")}
              </p>
            </div>
            <div>
              <h3 className="pb-3">
                {t("nhom_tuoi")}
              </h3>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
              {nhomtuoi.map((e, index) => (
                  <InputRadioCustom
                    key={index}
                    type="checkbox"
                    title={e.title}
                    id={e.id}
                    value={e.value}
                    onChange={handleCheckboxChange}
                    checked={selectedAgeMT.includes(e.value)}
                  />
                ))}
              </div>
            </div>
            <div className="mt-5">
              <h3 className="pb-3">
                {t("gioitinh")}
              </h3>
              <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                {gioitinh.map(
                  (e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      title={e.title}
                      id={e.id}
                      value={e.value}
                      onChange={(e) => {
                        setSelectedGioitinh(
                          e.target.value
                        );
                      }}
                      checked={
                        selectedGioitinh ===
                        e.value
                      }
                    />
                  )
                )}
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <hr className="text-white" />
            <div className="mt-5">
              <h1 style={{fontSize:"60px"}}>
                {t("quy_mo_quy_pham")}
              </h1>
              <p>
                {t("vui_long_nhap")}
              </p>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <h3 className="py-3">
                    {t("so_luong")}
                  </h3>
                  <div className="d-flex align-items-center gap-3">
                    <div className="w70">
                      <input
                        type="number"
                        placeholder={t(
                          "so_luong"
                        )}
                        className="input_custom w50"
                        value={
                          formik.values
                            .count
                        }
                        onChange={formik.handleChange(
                          "count"
                        )}
                        onBlur={formik.handleBlur(
                          "count"
                        )}
                      />
                      <div className="error mb-3">
                        {formik.touched
                          .count &&
                        formik.errors
                          .count ? (
                          <div>
                            {
                              formik
                                .errors
                                .count
                            }
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <h3 className="py-3">
                    {t("pham_vi")}
                  </h3>
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    {phamvi.map(
                      (e, index) => (
                        <InputRadioCustom
                          key={index}
                          type="radio"
                          title={
                            e.title
                          }
                          value={
                            e.value
                          }
                          id={e.id}
                          onChange={(
                            e
                          ) => {
                            setSelectedPhamvi(
                              e.target
                                .value
                            );
                            console.log(
                              selectedPhamvi
                            );
                          }}
                          checked={
                            selectedPhamvi ===
                            e.value
                          }
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr className="text-white" />
            <div className="mt-5">
              <h1 style={{fontSize:"60px"}}>{t("lich_trinh")}</h1>
              <p>
                {t(
                  "vui_long_nhap_khoan_tg"
                )}
              </p>
              <RangePicker
                className=" custom-range-picker bg-dark text-white w25"
                format="YYYY-MM-DD"
                placeholder={[
                  t(
                    "thoi_gian_bat_dau"
                  ),
                  t(
                    "thoi_gian_ket_thuc"
                  ),
                ]}
                onChange={(
                  value,
                  dateString
                ) => {
                  console.log(
                    "Formatted Selected Time: ",
                    dateString
                  );
                  formik.setFieldValue(
                    "startDate",
                    dateString[0]
                  );
                  formik.setFieldValue(
                    "endDate",
                    dateString[1]
                  );
                }}
                onOk={onOk}
              />

              <div className="mt-5">
              {
                formik.values.count ==="" || formik.values.titleProject ==="" || formik.values.endDate==="" ||
                formik.values.startDate ==="" ? (
                <p className="warning-box mb-0">
                  <div className="d-flex align-items-center">
                    <CiWarning
                      style={{
                        color:
                          "#FF0099",
                      }}
                      className="me-3"
                    />
                    <span className="d-block">
                      {t("chon_mot_so")}
                    </span>
                  </div>
                </p>
                ):""
              }
                <div className="d-flex gap-3 mt-3">
                  <button
                    style={{
                      width: "15%",
                    }}
                    className="btn_submit"
                    type="submit"
                    onClick={() => {
                      setType(
                        "Bản chính"
                      );
                    }}>
                    {t("gui_don")}
                  </button>
                  <button
                    style={{
                      width: "15%",
                    }}
                    className="btn_note"
                    type="submit"
                    onClick={() => {
                      setType(
                        "Bản nháp"
                      );
                    }}>
                    {t("luu_nhap")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
