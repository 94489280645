import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import liveService from "./liveService";

export const addLive = createAsyncThunk("live/live", async (data, thunkApi) => {
  try {
    return await liveService.addLive(data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
export const getAllLive = createAsyncThunk("live/all-live", async ( thunkApi) => {
  try {
    return await liveService.getAllLive();
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
export const getIdLive = createAsyncThunk("live/id-live", async ( id,thunkApi) => {
  try {
    return await liveService.getIdLive(id);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
const initialState = {
  live: [],
  idLive:{},
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const liveSlice = createSlice({
  name: "live",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addLive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addLive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(addLive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getAllLive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.live = action.payload;
      })
      .addCase(getAllLive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.live = [];
        state.message = action.error;
      })


      .addCase(getIdLive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIdLive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.idLive = action.payload;
      })
      .addCase(getIdLive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.idLive = {};
        state.message = action.error;
      });
  },
});
export const { resetImages } = liveSlice.actions;
export default liveSlice.reducer;
