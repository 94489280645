import React from "react";
import { useTranslation } from "react-i18next";
export default function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <hr className="bg-white" style={{ paddingTop: "1px" }} />
      <footer
        style={{
          background: "#000",
        }}
      >
        <div className="container py-5 text-center text-white">
          <div className="">
            <h4>비엣티 Viety</h4>
            <p
              className="mb-1"
              style={{
                fontSize: "13px",
              }}
            >
              {t("nguoi_dai_dien")}
            </p>
            <p
              className="mb-1"
              style={{
                fontSize: "13px",
              }}
            >
              {t("tel")}
            </p>
            <p
              className="mb-1"
              style={{
                fontSize: "13px",
              }}
            >
              {t("so_dkkd")}
            </p>
            <div className="pt-5">
              <p>{t("dieu_khoan")}</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
