import React from "react";

export default function ManagerBuyer({ t }) {
  return (
    <section className="container py-5 text-white">
      {/* tên dự án */}
      <div className="py-4">
        <h6 className="mb-3">{t("ban_hang_ky_gui")}</h6>
        <h1 className="mb-3">{t("ten_du_an")}</h1>
        <h6 className="mb-5">
          {t("thoigian")} : 0000-00-00 00:00 ~ 0000-00-00 00:00
        </h6>
      </div>
      <div>
        <h3 className="pb-4 mb-5">{t("i4_thuonghieu")}</h3>
        <div className="row pb-4">
          <div className="col-md-4 d-flex justify-content-between mb-3">
            <label className="me-3" htmlFor={t("ten_cty")}>
              {t("ten_cty")}
            </label>
            <div className="">
              <input
                type="text"
                placeholder={t("ten_cty")}
                className="input_custom"
                // value={
                //   formik.values
                //     .brand
                // }
                // onChange={formik.handleChange(
                //   "brand"
                // )}
                // onBlur={formik.handleBlur(
                //   "brand"
                // )}
                // name="brand" // Ensure you include the name attribute
              />
              {/* {formik.touched
                    .brand &&
                  formik.errors
                    .brand ? (
                    <div className="error mb-3">
                      {
                        formik.errors
                          .brand
                      }
                    </div>
                  ) : null} */}
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-between mb-3">
            <label className="me-3" htmlFor={t("loai_sp")}>
              {t("loai_sp")}
            </label>
            <div className="">
              <select
                className="form-select input_custom"
                aria-label="Default select example"
                // defaultValue={
                //   "Skincare"
                // }
                // style={{
                //   width: "200px",
                //   borderRadius:
                //     "24px",
                //   background:
                //     "#1a1a1a",
                //   color: "white",
                //   outline: "none",
                //   border:
                //     "2px solid #fff",
                // }}
                // value={
                //   formik.values
                //     .typeProduct
                // }
                // onChange={formik.handleChange(
                //   "typeProduct"
                // )}
                // onBlur={formik.handleBlur(
                //   "typeProduct"
                // )}
                name="typeProduct"
              >
                <option value={t("skincare")}>{t("skincare")}</option>
                <option value={t("doan")}>{t("doan")}</option>
                <option value={t("dogiadung")}>{t("dogiadung")}</option>
                <option value={t("sach")}>{t("sach")}</option>
              </select>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-between mb-3">
            <label className="me-3" htmlFor={t("sdt")}>
              {t("sdt")}
            </label>
            <div className="">
              <input
                type="number"
                placeholder={t("sdt")}
                className="input_custom"
                // value={
                //   formik.values
                //     .titleProduct
                // }
                // onChange={formik.handleChange(
                //   "titleProduct"
                // )}
                // onBlur={formik.handleBlur(
                //   "titleProduct"
                // )}
              />
              {/* {formik.touched
                    .titleProduct &&
                  formik.errors
                    .titleProduct ? (
                    <div className="error mb-3">
                      {
                        formik.errors
                          .titleProduct
                      }
                    </div>
                  ) : null} */}
            </div>
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-md-4 d-flex justify-content-between mb-3">
            <label className="me-3" htmlFor={t("nguoi_phu_trach")}>
              {t("nguoi_phu_trach")}
            </label>
            <div className="">
              <input
                type="text"
                placeholder={t("nguoi_phu_trach")}
                className="input_custom"
                // value={
                //   formik.values
                //     .trongluong
                // }
                // onChange={formik.handleChange(
                //   "trongluong"
                // )}
                // onBlur={formik.handleBlur(
                //   "trongluong"
                // )}
              />
              {/* {formik.touched
                    .trongluong &&
                  formik.errors
                    .trongluong ? (
                    <div className="error mb-3">
                      {
                        formik.errors
                          .trongluong
                      }
                    </div>
                  ) : null} */}
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-between mb-3">
            <label className="me-3" htmlFor={t("mail")}>
              {t("mail")}
            </label>
            <div className="">
              <input
                type="text"
                placeholder={t("mail")}
                className="input_custom"
                // value={
                //   formik.values
                //     .theloai
                // }
                // onChange={formik.handleChange(
                //   "theloai"
                // )}
                // onBlur={formik.handleBlur(
                //   "theloai"
                // )}
              />
              {/* {formik.touched
                    .theloai &&
                  formik.errors
                    .theloai ? (
                    <div className="error mb-3">
                      {
                        formik.errors
                          .theloai
                      }
                    </div>
                  ) : null} */}
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-between mb-3">
            <label className="me-3" htmlFor={t("gpkd")}>
              {t("gpkd")}
            </label>
            <div className="img_post d-flex align-items-center justify-content-end text-center">
              <input
                type="text"
                className="input_custom"
                placeholder="asdasd.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="bg-white mb-4 mt-0 p-0" />

      {/* thông tin */}
      <div>
        <h3 className="pb-4">{t("thong_tin_sp1")}</h3>
        <div className="row pb-4">
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("ten_thuong_hieu")}>
              {t("ten_thuong_hieu")}
            </label>
            <input
              type="text"
              placeholder={t("ten_thuong_hieu")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("loai_sp")}>
              {t("loai_sp")}
            </label>
            <select
              className="form-select input_custom"
              aria-label="Default select example"
              style={{
                width: "200px",
                borderRadius: "24px",
                background: "#1a1a1a",
                color: "white",
                outline: "none",
                border: "2px solid #fff",
              }}
            >
              <option value={t("skincare")}>{t("skincare")}</option>
              <option value={t("doan")}>{t("doan")}</option>
              <option value={t("dogiadung")}>{t("dogiadung")}</option>
              <option value={t("sach")}>{t("sach")}</option>
            </select>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("ten_sp")}>
              {t("ten_sp")}
            </label>
            <input
              type="text"
              placeholder={t("ten_sp")}
              className="input_custom"
            />
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("trong_luong")}>
              {t("trong_luong")}
            </label>
            <input
              type="number"
              placeholder={t("trong_luong")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("the_loai")}>
              {t("the_loai")}
            </label>
            <input
              type="text"
              placeholder={t("the_loai")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("them_ha")}>
              {t("them_ha")}
            </label>
            <div className="text-end d-flex justify-content-end">
              <input
                type="text"
                placeholder={t("them_ha")}
                className="input_custom me-2"
              />
            </div>
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-2">
            <label className="me-3" htmlFor={t("gia_cai")}>
              {t("gia_cai")}
            </label>
            <input
              type="number"
              placeholder={t("gia_cai")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-2">
            <label className="me-3" htmlFor={t("hsd")}>
              {t("hsd")}
            </label>
            <input
              type="date"
              placeholder="example@example.com"
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-2">
            <label className="me-3" htmlFor={t("hinh_nen_trang")}>
              {t("hinh_nen_trang")}
            </label>
            <div className="text-end d-flex justify-content-end">
              <input
                type="text"
                placeholder={t("hinh_nen_trang")}
                className="input_custom me-2"
              />
            </div>
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-2">
            <label className="me-3" htmlFor={t("so_to")}>
              {t("so_to")}
            </label>
            <input
              type="text"
              placeholder={t("vl_nhapso")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
        </div>
      </div>
      <hr className="bg-white mb-4 mt-0 p-0" />

      <div className="py-4">
        <div className="py-3">
          <h3>{t("kq_du_an")}</h3>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="d-flex gap-30">
              <p>{t("ten_du_an")}</p>
              <p>{t("ban_hang_ky_gui")}</p>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex gap-30">
              <p>{t("hinh_thai")}</p>
              <p>
                {t("online")}/{t("offline")}
              </p>
            </div>
          </div>
          <div className="col-4"></div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="d-flex gap-30">
              <p>{t("trang_thai")}</p>
              <p>{t("tham_gia_day")}</p>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex gap-30">
              <p>{t("hd_tg")}</p>
              <p>2020-00-00 ~ 2020-00-00 </p>
            </div>
          </div>
          <div className="col-4"></div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="d-flex gap-30">
              <p>{t("cua_hang")}</p>
              <div className="d-flex gap-20 flex-wrap">
                <p>Shopee</p>
                <p>Lotte mart</p>
              </div>
            </div>
          </div>
          <div className="col-4"></div>
          <div className="col-4"></div>
        </div>
      </div>
    </section>
  );
}
