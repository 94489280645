import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/users/userSlice";
import uploadReducer from "../features/upload/uploadSlice";
import publicProductReducer from "../features/publicProduct/publicProductSlice";
import registerExpriReducer from "../features/registerExprience/resExpriSlice";
import registerSaleReducer from "../features/registerSale/resSaleSlice";
import maketingReducer from "../features/maketing/maketingSlice";
import liveReducer from "../features/live/liveSlice";
import priceReducer from "../features/createPrice/createPriceSlice";
import companyReducer from "../features/company/companySlice";
import addProductReducer from "../features/addProduct/addProductSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    upload: uploadReducer,
    registerPri: registerExpriReducer,
    publicProduct: publicProductReducer,
    registerSale: registerSaleReducer,
    maketing: maketingReducer,
    live: liveReducer,
    price: priceReducer,
    company:companyReducer,
    product:addProductReducer,
  },
});
