import React from 'react'

export default function NotFound() {
  return (
    <div className='container py-5'>
      <h1 className='text-center' style={{fontSize:"7rem"}}>404</h1>
      <h1 className='text-center' style={{fontSize:"1rem"}}>NOT FOUND</h1>
    </div>
  )
}
