import React, { useState, useEffect } from "react";
import logo from "../images/on-1.svg";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { FaRegUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import vietnam from "../images/icons/vietnam.png";
import korea from "../images/icons/korea.png";
import { useTranslation } from "react-i18next";
export default function Header() {
  const initialImage = localStorage.getItem("image") || "korea";
  const [image, setImage] = useState(
    initialImage === "korea" ? korea : vietnam
  );
  const { t, i18n } = useTranslation();

  // Thay đổi ngôn ngữ ban đầu dựa trên giá trị của initialImage
  useEffect(() => {
    if (initialImage === "korea") {
      i18n.changeLanguage("ko"); // ngôn ngữ Hàn
    } else {
      i18n.changeLanguage("vi"); // ngôn ngữ Việt
    }
  }, [initialImage, i18n]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    localStorage.setItem("image", image === korea ? "korea" : "vietnam");
  }, [image]);

  const handleClick = () => {
    const newImage = image === vietnam ? korea : vietnam;
    setImage(newImage);
    changeLanguage(newImage === korea ? "ko" : "vi"); // ngôn ngữ Hàn khi hình ảnh là Korea
    setTimeout(() => {
      window.location.reload();
    }, 100); // Cho phép trạng thái cập nhật trước khi reload
  };
  const userState = useSelector((state) => state.auth.user);
  const items = [
    {
      label: (
        <span
          onClick={() => {
            sessionStorage.removeItem("user");
            window.location.reload();
          }}
          style={{
            display: "block",
            width: "100%",
            zIndex: "100000000",
          }}
        >
          {t("dang_xuat")}
        </span>
      ),

      key: "0",
    },
  ];

  const [showRegisterTabs, setShowRegisterTabs] = useState(false);
  const handleRegisterClick = () => {
    setShowRegisterTabs(!showRegisterTabs);
  };
  const handleTabClick = (tab) => {
    setShowRegisterTabs(false);
  };

  return (
    <>
      <div
        className=""
        style={{
          position: "relative",
          zIndex: 10000,
          width: "100%",
          top: 0,
          left: 0,
          background: "rgba(0,0,0,.3)",
        }}
      >
        <nav className="navbar navbar-expand-lg" style={{ zIndex: 1 }}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <GiHamburgerMenu className="text-white" />
            </button>
            <div
              className="collapse navbar-collapse d-flex justify-content-between"
              id="navbarNav"
            >
              {userState?.role === "admin" ? (
                <>
                  <ul className="navbar-nav me-auto text-center">
                    <li className="nav-item position-relative">
                      <Link
                        className="nav-link active text-white tab_header"
                        aria-current="page"
                        to="/admin/manager-register-expirience"
                      >
                        {t("dang_ky_trai_nghiem")}
                      </Link>
                    </li>
                    <li className="nav-item position-relative">
                      <Link
                        className="nav-link active text-white tab_header"
                        aria-current="page"
                        to="/admin/manager-public-product"
                      >
                        {t("cong_bo_san_pham_header")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link text-white tab_header"
                        to="/admin/manager-sale"
                      >
                        {t("ban_hang_ky_gui_header")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link text-white tab_header"
                        to="/admin/manager-maketing"
                      >
                        {t("influencer_marketing_header")}
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        className="nav-link text-white tab_header"
                        to="/admin/manager-live">
                        Live Commerce
                      </Link>
                    </li> */}
                  </ul>
                  <ul className="navbar-nav">
                    {userState !== null ? (
                      <li
                        className="nav-link text-white"
                        style={{
                          zIndex: 1000000,
                        }}
                      >
                        <Dropdown
                          menu={{
                            items,
                          }}
                          trigger={["click"]}
                        >
                          <a
                            onClick={(e) => e.preventDefault()}
                            className="nav-link text-white"
                          >
                            <Space
                              style={{
                                border: "1px solid black",
                                padding: "8px 20px",
                                borderRadius: "50px",
                                display: "flex",
                                color: "black",
                                alignItems: "center",
                              }}
                            >
                              <FaRegUserCircle className="fs-4" />
                              {userState?.name || "Tài khoản"}
                            </Space>
                          </a>
                        </Dropdown>
                      </li>
                    ) : (
                      <li className="nav-item">
                        <Link
                          className="nav-link text-white tab_header"
                          to="/login"
                        >
                          {t("login")}
                        </Link>
                      </li>
                    )}
                  </ul>
                </>
              ) : (
                <>
                  <ul className="navbar-nav d-flex align-items-center">
                    <li className="nav-item position-relative">
                      <Link
                        className="nav-link active text-white tab_header"
                        aria-current="page"
                        to="#"
                        onClick={handleRegisterClick}
                      >
                        {t("dang_ky")}
                      </Link>
                      {showRegisterTabs && (
                        <div
                          className="position-absolute menu_header bg-black"
                          style={{
                            zIndex: 999999,
                          }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <ul className="nav d-flex align-items-center justify-content-around">
                                <li
                                  className="nav-item"
                                  style={{
                                    zIndex: 100000,
                                  }}
                                >
                                  <NavLink
                                    className="item_menu"
                                    activeClassName="active"
                                    to="/experience"
                                    onClick={() => handleTabClick("experience")}
                                  >
                                    {t("dang_ky_trai_nghiem")}
                                  </NavLink>
                                </li>
                                <li
                                  className="nav-item"
                                  style={{
                                    zIndex: 100000,
                                  }}
                                >
                                  <NavLink
                                    className="item_menu"
                                    activeClassName="active"
                                    to="/declare"
                                    onClick={() => handleTabClick("declare")}
                                  >
                                    {t("cong_bo_san_pham_header")}
                                  </NavLink>
                                </li>
                                <li
                                  className="nav-item"
                                  style={{
                                    zIndex: 100000,
                                  }}
                                >
                                  <NavLink
                                    className=" item_menu"
                                    activeClassName="active"
                                    to="/sale"
                                    onClick={() => handleTabClick("sale")}
                                  >
                                    {t("ban_hang_ky_gui_header")}
                                  </NavLink>
                                </li>
                                <li
                                  className="nav-item"
                                  style={{
                                    zIndex: 100000,
                                  }}
                                >
                                  <NavLink
                                    className="item_menu"
                                    activeClassName="active"
                                    to="/influencer-marketing"
                                    onClick={() =>
                                      handleTabClick("influencer-marketing")
                                    }
                                  >
                                    {t("influencer_marketing_header")}
                                  </NavLink>
                                </li>
                                <li
                                  className="nav-item"
                                  style={{
                                    zIndex: 100000,
                                  }}
                                >
                                  <NavLink
                                    className=" item_menu"
                                    activeClassName="active"
                                    to="/live-commerce"
                                    onClick={() =>
                                      handleTabClick("live-commerce")
                                    }
                                  >
                                    {t("live_commerce_header")}
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                    <li
                      className="nav-item"
                      style={{
                        zIndex: 100000,
                      }}
                    >
                      <Link
                        className="nav-link text-white tab_header"
                        to="/create-pricing"
                      >
                        {t("bao_gia")}
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      style={{
                        zIndex: 100000,
                      }}
                    >
                      <Link
                        className="nav-link text-white tab_header"
                        to="/my-page"
                      >
                        {t("trang_cua_toi")}
                      </Link>
                    </li>
                  </ul>
                  <ul className="navbar-nav  d-flex align-items-center justify-content-end ">
                    <li
                      className="nav-item "
                      style={{
                        zIndex: 100000,
                      }}
                    >
                      <Link
                        className="nav-link text-white tab_header "
                        to="/about"
                      >
                        {t("about")}
                      </Link>
                    </li>
                    {userState !== null ? (
                      <li
                        className="nav-link   text-white"
                        style={{
                          zIndex: 1000000,
                        }}
                      >
                        <Dropdown
                          menu={{
                            items,
                          }}
                          // getPopupContainer={() =>
                          //   dropdownContainerRef.current
                          // }
                          trigger={["click"]}
                        >
                          <a
                            onClick={(e) => e.preventDefault()}
                            className="nav-link text-white"
                          >
                            <Space
                              style={{
                                border: "1px solid black",
                                padding: "8px 20px",
                                borderRadius: "50px",
                                display: "flex",
                                color: "black",
                                alignItems: "center",
                              }}
                            >
                              <FaRegUserCircle className="fs-4" />
                              {userState?.name || t("tai_khoan")}
                            </Space>
                          </a>
                        </Dropdown>
                      </li>
                    ) : (
                      <>
                        <li
                          className="nav-item "
                          style={{
                            zIndex: 100000,
                          }}
                        >
                          <Link
                            className="nav-link text-white tab_header"
                            to="/login"
                          >
                            {t("login")}
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </>
              )}
            </div>
            <div>
              <button onClick={handleClick}>
                <img src={image} alt="Language Icon" />
              </button>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
