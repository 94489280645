import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import companyService from "./companyService";

export const getNamecompany = createAsyncThunk(
  "company/get-name",
  async (data, thunkApi) => {
    try {
      return await companyService.getNamePrice(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getIdcompany = createAsyncThunk(
  "company/get-id",
  async (id, thunkApi) => {
    try {
      return await companyService.getIdCompany(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const updatecompany = createAsyncThunk(
  "company/update",
  async (data, thunkApi) => {
    try {
      return await companyService.updateCompany(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
const initialState = {
  company: [],
  idcompany:{},
  id:{},
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const resExpriSlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNamecompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNamecompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.idcompany = action.payload;
      })
      .addCase(getNamecompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.idcompany = {};
      })

      .addCase(getIdcompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIdcompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.id = action.payload;
      })
      .addCase(getIdcompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.id = {};
      })

      .addCase(updatecompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatecompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(updatecompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
  },
});
export const { resetImages } = resExpriSlice.actions;
export default resExpriSlice.reducer;
