import React, { useEffect, useState, useCallback } from "react";
import { Table, Select, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getAllPublic,
  updateProcessPublic,
} from "../features/publicProduct/publicProductSlice";

const { Option } = Select;

const handlePDF = (fileName) => {
  const url = `https://api.viety.net/files/${fileName}`;
  window.open(url, "_blank");
};

const getFileNameFromUrl = (url) => {
  if (typeof url !== "string") {
    return "Unknown file";
  }
  const parts = url.split("-");
  return parts.length > 1 ? parts.slice(-1)[0] : url.split("/").pop();
};

export default function AdminRegisterPublicProduct({ t }) {
  const columns = [
    {
      title: "No.",
      dataIndex: "key",
      width: 50,
    },
    {
      title: t("tien_trinh"),
      dataIndex: "process",
      filters: [
        { text: t("cho"), value: "Chờ" },
        {
          text: t("dang_tien_hanh"),
          value: "Đang tiến hành",
        },
        {
          text: t("hoan_thanh"),
          value: "Hoàn thành",
        },
      ],
      onFilter: (value, record) =>
        record.process.props.defaultValue.includes(value),
      width: 150,
    },
    {
      title: t("ten_du_an"),
      dataIndex: "titleProject",
    },
    {
      title: t("sdt"),
      dataIndex: "phone",
    },
    {
      title: t("mail"),
      dataIndex: "email",
    },
    {
      title: t("ten_sp"),
      dataIndex: "titleProduct",
    },
    {
      title: t("bang_thanh_phan"),
      dataIndex: "bangThanhPhan",
      render: (text, record) => {
        const fileName = Array.isArray(record.bangThanhPhan)
          ? record.bangThanhPhan[0]
          : record.bangThanhPhan;
        return fileName ? (
          <Button onClick={() => handlePDF(fileName)}>
            {getFileNameFromUrl(fileName)}
          </Button>
        ) : null;
      },
      width: 200,
    },
    {
      title: t("phap_nhan"),
      dataIndex: "dangKyPhapNhan",
      render: (text, record) => {
        const fileName = Array.isArray(record.dangKyPhapNhan)
          ? record.dangKyPhapNhan[0]
          : record.dangKyPhapNhan;
        return fileName ? (
          <Button onClick={() => handlePDF(fileName)}>
            {getFileNameFromUrl(fileName)}
          </Button>
        ) : null;
      },
      width: 200,
    },
    {
      title: t("giay_chung_nhan"),
      dataIndex: "giayChungNhanBanHangSanXuat",
      render: (text, record) => {
        const fileName = Array.isArray(record.giayChungNhanBanHangSanXuat)
          ? record.giayChungNhanBanHangSanXuat[0]
          : record.giayChungNhanBanHangSanXuat;
        return fileName ? (
          <Button onClick={() => handlePDF(fileName)}>
            {getFileNameFromUrl(fileName)}
          </Button>
        ) : null;
      },
      width: 200,
    },
    {
      title: t("giay_kinh_doanh"),
      dataIndex: "giayDangKyKinhDoanh",
      render: (text, record) => {
        const fileName = Array.isArray(record.giayDangKyKinhDoanh)
          ? record.giayDangKyKinhDoanh[0]
          : record.giayDangKyKinhDoanh;
        return fileName ? (
          <Button onClick={() => handlePDF(fileName)}>
            {getFileNameFromUrl(fileName)}
          </Button>
        ) : null;
      },
      width: 200,
    },
    {
      title: t("giay_uy_quyen"),
      dataIndex: "giayUyQuyen",
      render: (text, record) => {
        const fileName = Array.isArray(record.giayUyQuyen)
          ? record.giayUyQuyen[0]
          : record.giayUyQuyen;
        return fileName ? (
          <Button onClick={() => handlePDF(fileName)}>
            {getFileNameFromUrl(fileName)}
          </Button>
        ) : null;
      },
      width: 200,
    },
    {
      title: t("xac_nhan_lsq"),
      dataIndex: "xacNhanCuaLanhSuQuan",
      render: (text, record) => {
        const fileName = Array.isArray(record.xacNhanCuaLanhSuQuan)
          ? record.xacNhanCuaLanhSuQuan[0]
          : record.xacNhanCuaLanhSuQuan;
        return fileName ? (
          <Button onClick={() => handlePDF(fileName)}>
            {getFileNameFromUrl(fileName)}
          </Button>
        ) : null;
      },
      width: 200,
    },
  ];
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const res = useSelector((state) => state.publicProduct.publicProduct.getAll);

  useEffect(() => {
    dispatch(getAllPublic());
    window.scroll(0, 0);
  }, [dispatch]);

  const reversedRes = Array.isArray(res) ? [...res].reverse() : [];

  const handleOnchangeOrder = useCallback(
    (id, value) => {
      const data = {
        _id: id,
        status: value,
      };
      dispatch(updateProcessPublic(data));
      dispatch(getAllPublic()); // Re-fetch data to reflect changes
    },
    [dispatch]
  );

  useEffect(() => {
    if (Array.isArray(reversedRes) && reversedRes.length > 0) {
      const formattedData = reversedRes.map((item, index) => {
        const infoProduct = JSON.parse(item?.infoProduct[0] || "{}");
        return {
          key: index + 1,
          schedule: `${moment(item?.schedule?.startDate).format(
            "DD/MM/YYYY"
          )} ~ ${moment(item?.schedule?.endDate).format("DD/MM/YYYY")}`,
          process: (
            <Select
              style={{
                width: "150px",
              }}
              defaultValue={item?.process}
              onChange={(value) => handleOnchangeOrder(item?._id, value)}
            >
              <Option value="Chờ">{t("cho")}</Option>
              <Option value="Đang tiến hành">{t("dang_tien_hanh")}</Option>
              <Option value="Hoàn thành">{t("hoan_thanh")}</Option>
            </Select>
          ),
          titleProject: item?.titleProject,
          phone: item?.registerBy?.mobile,
          email: item?.registerBy?.email,
          titleProduct: (
            <>
              {infoProduct?.map((e, index) => (
                <p>{` ${index + 1}: ${e?.titleProduct}`}</p>
              ))}
            </>
          ),
          bangThanhPhan: Array.isArray(item?.bangThanhPhan)
            ? item?.bangThanhPhan[0]
            : item?.bangThanhPhan,
          xacNhanCuaLanhSuQuan: Array.isArray(item?.xacNhanCuaLanhSuQuan)
            ? item?.xacNhanCuaLanhSuQuan[0]
            : item?.xacNhanCuaLanhSuQuan,
          dangKyPhapNhan: Array.isArray(item?.dangKyPhapNhan)
            ? item?.dangKyPhapNhan[0]
            : item?.dangKyPhapNhan,
          giayChungNhanBanHangSanXuat: Array.isArray(
            item?.giayChungNhanBanHangSanXuat
          )
            ? item?.giayChungNhanBanHangSanXuat[0]
            : item?.giayChungNhanBanHangSanXuat,
          giayDangKyKinhDoanh: Array.isArray(item?.giayDangKyKinhDoanh)
            ? item?.giayDangKyKinhDoanh[0]
            : item?.giayDangKyKinhDoanh,
          giayUyQuyen: Array.isArray(item?.giayUyQuyen)
            ? item?.giayUyQuyen[0]
            : item?.giayUyQuyen,
        };
      });

      // Only update the state if there's a change to avoid infinite loops
      setData((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(formattedData)) {
          return formattedData;
        }
        return prevData;
      });
    }
  }, [reversedRes, handleOnchangeOrder]);

  return (
    <div className="container py-5">
      <div className="py-5 text-white">
        <h1>{t("cong_bo_san_pham_header")}</h1>
      </div>
      <div className="mt-5">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: "max-content" }}
          pagination={{ pageSize: 10 }}
        />
      </div>
    </div>
  );
}
