import React, { useEffect, useState } from "react";
import { IoReload } from "react-icons/io5";
import { CiWarning } from "react-icons/ci";
import h1 from "../images/m1.png";
import h2 from "../images/m2.png";
import tt from "../images/tt.png";
import ig from "../images/ig.png";
import yt from "../images/yt.png";
import fb from "../images/fb.png";
import { FaAngleDown } from "react-icons/fa";
import { IoMdFemale } from "react-icons/io";
import { IoMdMale } from "react-icons/io";
import { IoMaleFemaleSharp } from "react-icons/io5";
import { phamvimongmuong } from "../data/data";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputRadioCustom from "../components/InputRadioCustom";
import InputLableCustom from "../components/InputLableCustom";
import InputChoiceNT from "../components/InputChoiceNT";
import {
  delImg,
  delImgbg,
  resetImages,
  uploadImg,
  uploadImgBg,
} from "../features/upload/uploadSlice";
import { addMaketing } from "../features/maketing/maketingSlice";
import axios from "axios";
import { config } from "../utils/axiosconfig";
import { base_url } from "../utils/base_url";
import { DatePicker, Space } from "antd";
import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;

export default function Marketing({ t }) {
  const onOk = (value) => {
    console.log("onOk: ", value);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const [selectedAgeMT, setSelectedAgeMT] = useState([]);
  const [selectedGioitinh, setSelectedGioitinh] = useState(t("nu"));
  const [selectedLinhvuc, setSelectedLinhvuc] = useState(t("lamdep"));
  const [selectedBanquyen, setSelectedBanquyen] = useState(t("thang"));
  const [selectedFollower, setSelectedFollower] = useState(t("muoi_nam"));
  const [selectedChoiceNT, setSelectedChoiceNT] = useState(t("tiktok"));
  const [selectedPhamvimongmuon, setSelectedPhamvimongmuon] = useState(
    t("xhdg")
  );
  const [selectedPhamvitieuchuan, setSelectedPhamvitieuchuan] = useState(
    t("vdn")
  );
  const dispatch = useDispatch();
  const [type, setType] = useState(t("ban_chinh"));
  const nhomtuoi = [
    {
      title: t("treem"),
      value: "Trẻ em",
      id: t("treem"),
    },
    {
      title: "10" + " " + t("u"),
      value: "10 tuổi",
      id: "10" + " " + t("u"),
    },
    {
      title: "20" + " " + t("u"),
      value: "20 tuổi",
      id: "20" + " " + t("u"),
    },
    {
      title: "30" + " " + t("u"),
      value: "30 tuổi",
      id: "30" + " " + t("u"),
    },
    {
      title: "40" + " " + t("u"),
      value: "40 tuổi" ,
      id: "40" + " " + t("u"),
    },
    {
      title:
        "50" +
        " " +
        t("u") +
        t("trolen"),
      value:
        "50 tuổi",
      id:
        "50" +
        " " +
        t("u") +
        t("trolen"),
    },
  ];
  const gioitinh = [
    {
      title: (
        <>
          <IoMdFemale /> {t("nu")}
        </>
      ),
      value: t("nu"),
      id: t("nu"),
    },
    {
      title: (
        <>
          <IoMdMale /> {t("nam")}{" "}
        </>
      ),
      value: t("nam"),
      id: t("nam"),
    },
    {
      title: (
        <>
          <IoMaleFemaleSharp /> {t("khong_phan_biet")}
        </>
      ),
      value: t("khong_phan_biet"),
      id: t("khong_phan_biet"),
    },
  ];

  const choiceNentang = [
    {
      title: t("ins"),
      value: t("ins"),
      id: t("ins"),
      img: ig,
    },
    {
      title: t("yt"),
      value: t("yt"),
      id: t("yt"),
      img: yt,
    },
    {
      title: t("fb"),
      value: t("fb"),
      id: t("fb"),
      img: fb,
    },
    {
      title: t("tiktok"),
      value: t("tiktok"),
      id: t("tiktok"),
      img: tt,
    },
  ];
  const linhvuc = [
    {
      title: t("lamdep"),
      value: t("lamdep"),
      id: t("lamdep"),
    },
    {
      title: t("doisong"),
      value: t("doisong"),
      id: t("doisong"),
    },
    {
      title: t("thoitrang"),
      value: t("thoitrang"),
      id: t("thoitrang"),
    },
    {
      title: t("dadang"),
      value: t("dadang"),
      id: t("dadang"),
    },
  ];
  const countFollower = [
    {
      title: t("muoi_nam"),
      value: t("muoi_nam"),
      id: t("muoi_nam"),
    },
    {
      title: t("nam_muoi"),
      value: t("nam_muoi"),
      id: t("nam_muoi"),
    },
    {
      title: t("muoi_2muoi"),
      value: t("muoi_2muoi"),
      id: t("muoi_2muoi"),
    },
    {
      title: t("haitram_nam"),
      value: t("haitram_nam"),
      id: t("haitram_nam"),
    },
    {
      title: t("nammuoi_tram"),
      value: t("nammuoi_tram"),
      id: t("nammuoi_tram"),
    },
    {
      title: t("tramtrolen"),
      value: t("tramtrolen"),
      id: t("tramtrolen"),
    },
  ];
  const banquyen = [
    {
      title: t("thang"),
      value: t("thang"),
      id: t("thang"),
    },
    {
      title: t("thang3"),
      value: t("thang3"),
      id: t("thang3"),
    },
    {
      title: t("thang6"),
      value: t("thang6"),
      id: t("thang6"),
    },
    {
      title: t("khsd"),
      value: t("khsd"),
      id: t("khsd"),
    },
  ];
  const phamvivideo = [
    {
      title: t("vdn"),
      value: t("vdn"),
      id: t("vdn"),
    },
    {
      title: t("vdtc"),
      value: t("vdtc"),
      id: t("vdtc"),
    },
    {
      title: t("hatc"),
      value: t("hatc"),
      id: t("hatc"),
    },
  ];
  const phamvimongmuong = [
    {
      title: t("xhdg"),
      value: t("xhdg"),
      id: t("xhdg"),
    },
    {
      title: t("gtsp"),
      value: t("gtsp"),
      id: t("gtsp"),
    },
    {
      title: t("ndsd"),
      value: t("ndsd"),
      id: t("ndsd"),
    },
    {
      title: t("ndsd1"),
      value: t("ndsd1"),
      id: t("ndsd1"),
    },
  ];
  const handleRemoveLastProduct = () => {
    // Kiểm tra nếu có ít nhất một sản phẩm
    if (formik.values.products.length > 0) {
      // Loại bỏ phần tử cuối cùng
      const updatedProducts = formik.values.products.slice(0, -1);
      formik.setFieldValue("products", updatedProducts);
    }
  };
  
  let userSchema = Yup.object().shape({
    titleProject: Yup.string().required(t("yc_nhap_ten_da")),
    products: Yup.array()
      .of(
        Yup.object().shape({
          brand: Yup.string().required(t("yc_nhap_thuong_hieu")),
          typeProduct: Yup.string().required(t("yc_nhap_loai_sp")),
          titleProduct: Yup.string().required(t("yc_nhap_ten_sp")),
          trongluong: Yup.string().required(t("yc_nhap_trong_luong")),
          theloai: Yup.string().required(t("yc_nhap_the_loai")),
          price: Yup.string().required(t("yc_nhap_gia_tien")),
          countNK: Yup.string().required(t("yc_nhap_sl_nhap_khau")),
          HSD: Yup.string().required(t("yc_chon_hang_sd")),
          hoahong: Yup.string().required(t("yc_nhap_hoa_hong")),
          priceHQ: Yup.string().required(t("yc_nhap_tien_hq")),
          priceVN: Yup.string().required(t("yc_nhap_tien_vn")),
        })
      )
      .min(1, t("yc_them_sp")),
  });
  const auth = useSelector((state) => state.auth.user);
  const up = async (acceptedFiles, productIndex) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => formData.append("images", file)); // Make sure the field name matches

    try {
      const res = await axios.post(
        `${base_url}upload/upload`,
        formData,
        config
      );
      const updatedImages = res.data;
      formik.setFieldValue(
        `products[${productIndex}].imageProduct`,
        updatedImages
      );
      console.log(res.data);
      // Handle response, e.g., update formik field
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(t("upload_failed"));
    }
  };
  const [check,setCheck]=useState(false)
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedAgeMT(prevSelectedAges => {
      const newSelectedAges = checked
        ? [...prevSelectedAges, value]
        : prevSelectedAges.filter(age => age !== value);

      formik.setFieldValue('age', newSelectedAges);

      return newSelectedAges;
    });
  };
  const upbg = async (acceptedFiles, productIndex) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => formData.append("images", file)); // Make sure the field name matches

    try {
      const res = await axios.post(
        `${base_url}upload/upload`,
        formData,
        config
      );
      const updatedImages = res.data;
      formik.setFieldValue(`products[${productIndex}].imageBg`, updatedImages);
      console.log(res.data);
      // Handle response, e.g., update formik field
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(t("upload_failed"));
    }
  };
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      titleProject: "",
      products: [
        {
          brand: "",
          typeProduct: t("skincare"),
          titleProduct: "",
          trongluong: "",
          theloai: "",
          imageProduct: "",
          imageBg: "",
          countNK: "",
          price: "",
          HSD: "",
          hoahong: "",
          priceHQ: "",
          priceVN: "",
        },
      ],
      nentang: [],
      storeOnline: "",
      storeOffline: "",

      startDate: "",
      endDate: "",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (auth !== null) {
        const hasImagesInProducts = values.products.every(
          (product) => product.imageProduct.length > 0
        );
        const hasImagesInProductBg = values.products.every(
          (product) => product.imageBg.length > 0
        );

        if (!hasImagesInProducts && !hasImagesInProductBg) {
          toast.warning(t("ban_chua_chon_anh"));
          return; // Prevent form submission
        } else {
          let data = {
            titleProject: values.titleProject,
            infoProduct: values.products,
            age: selectedAgeMT,
            sex: selectedGioitinh,
            nentang: selectedChoiceNT,
            linhvuc: selectedLinhvuc,
            follower: selectedFollower,
            banquyen: selectedBanquyen,
            phamvi: {
              selectedPhamvitieuchuan,
              selectedPhamvimongmuon,
            },
            schedule: {
              startDate: values.startDate,
              endDate: values.endDate,
            },
            type: type,
          };
          try {
            dispatch(addMaketing(data));

            setTimeout(() => {
              formik.resetForm();
              dispatch(resetImages());
              toast.success(t("save"));
              setSelectedAgeMT([]);
              setSelectedBanquyen(t("thang1"));
              setSelectedChoiceNT(t("tiktok"));
              setSelectedFollower(t("nam_muoi"));
              setSelectedGioitinh(t("nu"));
              setSelectedLinhvuc(t("lamdep"));
              setSelectedPhamvimongmuon(t("xhdg"));
            }, 300);
          } catch (error) {
            toast.error(t("dk_tb"));
          }
        }
      } else {
        toast.warning(t("ban_chua_dn"));
      }
    },
  });
  return (
    <>
      <section className="container py-5">
        <div className="row text-white">
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: 1 }}>
            <h1 className="py-3 display-4" style={{fontSize:"70px"}}>
              {t("xin_chao")}
            </h1>
            <p
              style={{fontSize:"50px"}}
              className="py-4 fs-5"
              dangerouslySetInnerHTML={{
                __html: t(
                  "ban_muon_dang_ky"
                ),
              }}></p>
            <div className="d-flex align-items-end" onClick={()=>navigate(`/truyxuat-maketing`)}>
              <button className="btn_cus d-flex align-items-center">
                <IoReload className="me-3" />
                <span className="d-block">
                  {t("truy_xuat")}
                </span>
              </button>
              <span className="ps-3">
              {t(
                  "truy_xuat_thong_tin"
                )}
              </span>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: "0" }}>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: "-60px",
              }}>
              <img
                src={h1}
                alt=""
                width="80%"
              />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}>
              <img src={h2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <hr className="text-white my-5" />
      <section className="container py-4 text-white">
        <form
          action=""
          onSubmit={
            formik.handleSubmit
          }>
          <div className="row py-3">
            <h1 className="pb-4" style={{fontSize:"50px"}}>
              {t("thong_tin_du_an")}
            </h1>

            <div className="col-lg-8 col-md-8 position-relative">
              <div className="d-flex flex-column align-items-center mb-3 w100">
                <div
                  className="gap-3 align-items-center justify-content-between w100"
                  style={{
                    height: "50px",
                  }}>
                  <div className="d-flex gap-3 align-items-center justify-content-between">
                    <label
                      htmlFor={t(
                        "ten_du_an"
                      )}
                      className="me-3 mb-2"
                      style={{
                        flex: 1,
                      }}>
                      {t("ten_du_an")}
                    </label>
                    <div
                      style={{
                        position:
                          "relative",
                        display:
                          "inline-block",
                        width: "40%",
                      }}>
                      <select
                        className="form-select input_custom"
                        aria-label="Default select example"
                        style={{
                          flex: 3,
                          width: "100%",
                          borderRadius:
                            "24px",
                          background:
                            "#1a1a1a",
                          color:
                            "white",
                          outline:
                            "none",
                          border:
                            "2px solid #fff",
                          appearance:
                            "none", // Hide the default dropdown arrow
                          paddingRight:
                            "2.5rem", // Add space for the icon
                        }}>
                        <option
                          selected>
                          {t("tao_moi")}
                        </option>
                        <option
                          value={t(
                            "tao_cu"
                          )}>
                          {t("tao_cu")}
                        </option>
                        <option
                          value={t(
                            "ban_nhap"
                          )}>
                          {t(
                            "ban_nhap"
                          )}
                        </option>
                      </select>
                      <div
                        style={{
                          position:
                            "absolute",
                          top: "50%",
                          right: "1rem", // Adjust the position from the right
                          transform:
                            "translateY(-50%)",
                          pointerEvents:
                            "none", // Allow clicks to pass through to the select element
                        }}>
                        <FaAngleDown />
                      </div>
                    </div>
                    <input
                      style={{
                        flex: 3,
                      }}
                      type="text"
                      placeholder={t(
                        "ten_du_an"
                      )}
                      className="input_custom"
                      value={
                        formik.values
                          .titleProject
                      }
                      onChange={formik.handleChange(
                        "titleProject"
                      )}
                      onBlur={formik.handleBlur(
                        "titleProject"
                      )}
                    />
                  </div>
                </div>
                <div
                  className="error mb-3 d-flex justify-content-end"
                  style={{
                    top: "100%",
                  }}>
                  {formik.touched
                    .titleProject &&
                  formik.errors
                    .titleProject ? (
                    <div>
                      {
                        formik.errors
                          .titleProject
                      }
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4"></div>
          </div>
          <div className="py-5">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h1 className="mb-3">
                {t("nhap_thong_tin_sp")}
              </h1>
              <div className="">
                <div className="boxed">
                  <button
                    className="btn_add"
                    onClick={() => {
                      const newProduct = {
                        brand: "",
                        typeProduct:
                          t("skincare"),
                        titleProduct: "",
                        trongluong: "",
                        theloai: "",
                        imageProduct: "",
                        imageBg: "",
                        countNK: "",
                        price: "",
                        HSD: "",
                        hoahong: "",
                        priceHQ: "",
                        priceVN: "",
                      };
                      formik.setFieldValue(
                        "products",
                        [
                          ...formik.values
                            .products,
                          newProduct,
                        ]
                      );
                    }}>
                    {t("them_san_pham")}
                  </button>
                </div>
                <button
                  className="btn btn-primary mt-3"
                  style={{width:"178px",borderRadius:"24px"}}
                  onClick={handleRemoveLastProduct}
                >
                  {t("xoa_san_pham")}
                </button>
                </div>
            </div>
          </div>
          {formik.values.products?.map(
            (product, index) => (
              <div key={index}>
                <h3 className="pb-4" style={{fontSize:"40px"}}>
                  {t("thong_tin_sp")}{" "}
                  {index + 1}
                </h3>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].brand`}>
                      {t(
                        "ten_thuong_hieu"
                      )}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "ten_thuong_hieu"
                        )}
                        className="input_custom w100"
                        value={
                          product.brand
                        }
                        onChange={formik.handleChange(
                          `products[${index}].brand`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].brand`
                        )}
                        name={`products[${index}].brand`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.brand &&
                      formik.errors
                        .products?.[
                        index
                      ]?.brand ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].brand
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].typeProduct`}>
                      {t("loai_sp")}
                    </label>
                    <div
                      className="w70"
                      style={{
                        position:
                          "relative",
                      }}>
                      <select
                        className="form-select input_custom w100"
                        aria-label="Default select example"
                        defaultValue={
                          "Skincare"
                        }
                        style={{
                          borderRadius:
                            "24px",
                          background:
                            "#1a1a1a",
                          color:
                            "white",
                          outline:
                            "none",
                          border:
                            "2px solid #fff",
                        }}
                        value={
                          product.typeProduct
                        }
                        onChange={
                          formik.handleChange
                        }
                        onBlur={
                          formik.handleBlur
                        }
                        name={`products[${index}].typeProduct`}>
                        <option
                          value={t(
                            "skincare"
                          )}>
                          {t(
                            "skincare"
                          )}
                        </option>
                        <option
                          value={t(
                            "doan"
                          )}>
                          {t("doan")}
                        </option>
                        <option
                          value={t(
                            "dogiadung"
                          )}>
                          {t(
                            "dogiadung"
                          )}
                        </option>
                        <option
                          value={t(
                            "sach"
                          )}>
                          {t("sach")}
                        </option>
                      </select>
                      <div
                        style={{
                          position:
                            "absolute",
                          top: "50%",
                          right: "1rem", // Adjust the position from the right
                          transform:
                            "translateY(-50%)",
                          pointerEvents:
                            "none", // Allow clicks to pass through to the select element
                        }}>
                        <FaAngleDown />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].titleProduct`}>
                      {t("ten_sp")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "ten_sp"
                        )}
                        className="input_custom w100"
                        value={
                          product.titleProduct
                        }
                        onChange={formik.handleChange(
                          `products[${index}].titleProduct`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].titleProduct`
                        )}
                        name={`products[${index}].titleProduct`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.titleProduct &&
                      formik.errors
                        .products?.[
                        index
                      ]
                        ?.titleProduct ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ]
                              .titleProduct
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].trongluong`}>
                      {t("trong_luong")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "trong_luong"
                        )}
                        className="input_custom w100"
                        value={
                          product.trongluong
                        }
                        onChange={formik.handleChange(
                          `products[${index}].trongluong`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].trongluong`
                        )}
                        name={`products[${index}].trongluong`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.trongluong &&
                      formik.errors
                        .products?.[
                        index
                      ]?.trongluong ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].trongluong
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].theloai`}>
                      {t("the_loai")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "theloai_"
                        )}
                        className="input_custom w100"
                        value={
                          product.theloai
                        }
                        onChange={
                          formik.handleChange
                        }
                        onBlur={
                          formik.handleBlur
                        }
                        name={`products[${index}].theloai`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.theloai &&
                      formik.errors
                        .products?.[
                        index
                      ]?.theloai ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].theloai
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].imageProduct`}>
                      {t("them_ha")}
                    </label>
                    <div
                      className="w70 d-flex justify-content-between"
                      style={{
                        height: "100%",
                      }}>
                      <div
                        className="img_post w70 d-flex align-items-center justify-content-between text-center"
                        style={{
                          height:
                            "100%",
                        }}>
                        <section className="image-section">
                        {product.imageProduct &&
                            product.imageProduct?.map((i, j) => {
                              const fileName = i.url.split('/').pop().split('?')[0];
                              return (
                                <div className="position-relative" key={j}>
                                  <button
                                    type="button"
                                    onClick={() => dispatch(delImg(i.public_id))}
                                    className="btn-close position-absolute"
                                    style={{
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></button>
                                  <p className="file-name mb-0">{fileName}</p>
                                </div>
                              );
                            })}
                        </section>
                      </div>
                      <Dropzone
                        onDrop={(
                          acceptedFiles
                        ) => {
                          if (
                            auth !==
                            null
                          ) {
                            up(
                              acceptedFiles,
                              index
                            );
                          } else {
                            toast.warning(
                              t(
                                "ban_chua_dn"
                              )
                            );
                          }
                        }}>
                        {({
                          getRootProps,
                          getInputProps,
                        }) => (
                          <div className="">
                            <div
                              {...getRootProps()}
                              className="upload-button">
                              <input
                                {...getInputProps()}
                              />
                              <button
                                type="button"
                                className="btn_upload">
                                {t(
                                  "tai_len"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].price`}>
                      {t("gia_cai")}
                    </label>
                    <div className="w70">
                      <input
                        type="number"
                        placeholder={t(
                          "gia_cai"
                        )}
                        className="input_custom w100"
                        value={
                          product.price
                        }
                        onChange={formik.handleChange(
                          `products[${index}].price`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].price`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.price &&
                      formik.errors
                        .products?.[
                        index
                      ]?.price ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].price
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={t(
                        `products[${index}].HSD`
                      )}>
                      {t("hsd")}
                    </label>
                    <div className="w70">
                      <input
                        type="date"
                        placeholder="example@example.com"
                        className="input_custom w100"
                        value={
                          product.HSD
                        }
                        onChange={formik.handleChange(
                          `products[${index}].HSD`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].HSD`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.HSD &&
                      formik.errors
                        .products?.[
                        index
                      ]?.HSD ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].HSD
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label className="me-3 w30">
                      {t(
                        "hinh_nen_trang"
                      )}
                    </label>
                    <div
                      className="w70 d-flex justify-content-between"
                      style={{
                        height: "100%",
                      }}>
                      <div
                        className="img_post w70 d-flex align-items-center justify-content-between text-center"
                        style={{
                          height:
                            "100%",
                        }}>
                        <section className="image-section">
                        {product.imageBg  &&
                            product.imageBg ?.map((i, j) => {
                              const fileName = i.url.split('/').pop().split('?')[0];
                              return (
                                <div className="position-relative" key={j}>
                                  <button
                                    type="button"
                                    onClick={() => dispatch(delImg(i.public_id))}
                                    className="btn-close position-absolute"
                                    style={{
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></button>
                                  <p className="file-name mb-0">{fileName}</p>
                                </div>
                              );
                            })}
                        </section>
                      </div>
                      <Dropzone
                        onDrop={(
                          acceptedFiles
                        ) => {
                          if (
                            auth !==
                            null
                          ) {
                            // fix
                            upbg(
                              acceptedFiles,
                              index
                            );
                          } else {
                            toast.warning(
                              t(
                                "ban_chua_dn"
                              )
                            );
                          }
                        }}>
                        {({
                          getRootProps,
                          getInputProps,
                        }) => (
                          <div>
                            <div
                              {...getRootProps()}
                              className="upload-button">
                              <input
                                {...getInputProps()}
                              />
                              <button
                                type="button"
                                className="btn_upload">
                                {t(
                                  "tai_len"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={t(
                        "so_to"
                      )}>
                      {t("so_to")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "so_to"
                        )}
                        className="input_custom w100"
                        value={
                          product.countNK
                        }
                        onChange={formik.handleChange(
                          `products[${index}].countNK`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].countNK`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.countNK &&
                      formik.errors
                        .products?.[
                        index
                      ]?.countNK ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].countNK
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={t(
                        "gia"
                      )}>
                      {t("gia")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "vl_nhapso"
                        )}
                        className="input_custom w100"
                        value={
                          product.hoahong
                        }
                        onChange={formik.handleChange(
                          `products[${index}].hoahong`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].hoahong`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.hoahong &&
                      formik.errors
                        .products?.[
                        index
                      ]?.hoahong ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].hoahong
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={t(
                        "gia_tien_hq"
                      )}>
                      {t("gia_tien_hq")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "vl_nhapso"
                        )}
                        className="input_custom w100"
                        value={
                          product.priceHQ
                        }
                        onChange={formik.handleChange(
                          `products[${index}].priceHQ`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].priceHQ`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.priceHQ &&
                      formik.errors
                        .products?.[
                        index
                      ]?.priceHQ ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].priceHQ
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={t(
                        "gia_tien_vn"
                      )}>
                      {t("gia_tien_vn")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "vl_nhapso"
                        )}
                        className="input_custom w100"
                        value={
                          product.priceVN
                        }
                        onChange={formik.handleChange(
                          `products[${index}].priceVN`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].priceVN`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.priceVN &&
                      formik.errors
                        .products?.[
                        index
                      ]?.priceVN ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].priceVN
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4"></div>
                </div>
              </div>
            )
          )}
          <hr
            className="text-white my-5"/>
          <div className="py-4 mt-5">
            <div>
              <h1 style={{fontSize:"50px"}}>{t("i4_muctieu")}</h1>
              <p>
                {t("vui_long_chon")}
              </p>
            </div>
            <div>
              <h3 className="pb-3" style={{fontSize:"40px"}}>
                {t("nhom_tuoi")} 
              </h3>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
              {nhomtuoi.map((e, index) => (
                  <InputRadioCustom
                    key={index}
                    type="checkbox"
                    title={e.title}
                    id={e.id}
                    value={e.value}
                    onChange={handleCheckboxChange}
                    checked={selectedAgeMT.includes(e.value)}
                  />
                ))}
                {/* {nhomtuoi.map(
                  (e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      title={e.title}
                      id={e.id}
                      value={e.value}
                      onChange={(e) => {
                        setSelectedAgeMT(
                          e.target.value
                        );
                      }}
                      checked={
                        selectedAgeMT ===
                        e.value
                      }
                    />
                  )
                )} */}
              </div>
            </div>
            <div className="mt-5">
              <h3 className="pb-3" style={{fontSize:"40px"}}>
                {t("gioitinh")}
              </h3>

              <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                {gioitinh.map(
                  (e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      title={e.title}
                      id={e.id}
                      value={e.value}
                      onChange={(e) => {
                        setSelectedGioitinh(
                          e.target.value
                        );
                      }}
                      checked={
                        selectedGioitinh ===
                        e.value
                      }
                    />
                  )
                )}
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <hr
              className="text-white my-5"
            />
            <div className="mt-5">
              <h3 className="mb-2" style={{fontSize:"50px"}}>
                {t("chon_nen_tang")}
              </h3>
              <h5 className="mb-5">
                {t("vui_long_chon")}
              </h5>
              <div className="d-flex justify-content-between ">
                {choiceNentang.map(
                  (e, index) => (
                    <InputChoiceNT
                      title={e.title}
                      key={index}
                      img={e.img}
                      type="checkbox"
                      value={e.value}
                      onChange={(
                        event
                      ) => {
                        const valueArray =
                          Array.isArray(
                            formik
                              .values
                              .nentang
                          )
                            ? [
                                ...formik
                                  .values
                                  .nentang,
                              ]
                            : [];
                        if (
                          event.target
                            .checked
                        ) {
                          if (
                            !valueArray.includes(
                              event
                                .target
                                .value
                            )
                          ) {
                            valueArray.push(
                              event
                                .target
                                .value
                            );
                          }
                        } else {
                          const index =
                            valueArray.indexOf(
                              event
                                .target
                                .value
                            );
                          if (
                            index > -1
                          ) {
                            valueArray.splice(
                              index,
                              1
                            );
                          }
                        }
                        formik.setFieldValue(
                          "nentang",
                          valueArray
                        );
                        setSelectedChoiceNT(
                          valueArray
                        );
                        console.log(
                          valueArray
                        );
                      }}
                      checked={
                        Array.isArray(
                          formik.values
                            .nentang
                        ) &&
                        formik.values.nentang.includes(
                          e.value
                        )
                      }
                    />
                  )
                )}
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            {/* lĩnh vực */}
            <div className="mb-4">
              <h3 className="pb-2 mt-5" style={{fontSize:"50px"}}>
                {t("linh_vuc")}
              </h3>

              <div className="d-flex flex-wrap justify-content-between gap-20">
                {linhvuc.map(
                  (e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      id={e.id}
                      title={e.title}
                      value={e.value}
                      onChange={(e) => {
                        setSelectedLinhvuc(
                          e.target.value
                        );
                      }}
                      checked={
                        selectedLinhvuc ===
                        e.value
                      }
                    />
                  )
                )}
                <div></div>
                <div></div>

                <div></div>
              </div>
            </div>
            {/* số lượng theo dõi */}
            <div className="mb-4">
              <h3 className="pb-2 mt-5" style={{fontSize:"50px"}}>
                {t("sl_follow")}
              </h3>
              <div className="d-flex flex-wrap justify-content-between gap-20">
                {countFollower.map(
                  (e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      title={e.title}
                      value={e.value}
                      id={e.id}
                      onChange={(e) => {
                        setSelectedFollower(
                          e.target.value
                        );
                      }}
                      checked={
                        selectedFollower ===
                        e.value
                      }
                    />
                  )
                )}
              </div>
            </div>
            {/* bản quyền */}
            <div className="mb-4">
              <h3 className="pb-2 mt-5" style={{fontSize:"50px"}}>
                {t("ban_quyen")}
              </h3>
              <div className="d-flex flex-wrap justify-content-between">
                {banquyen.map(
                  (e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      title={e.title}
                      value={e.value}
                      id={e.id}
                      onChange={(e) => {
                        setSelectedBanquyen(
                          e.target.value
                        );
                      }}
                      checked={
                        selectedBanquyen ===
                        e.value
                      }
                    />
                  )
                )}
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <hr
              className="text-white my-5"
            />
            <div className="mt-5 mb-5">
              <h1 style={{fontSize:"50px"}}>{t("lich_trinh")}</h1>
              <p>
                {t(
                  "vui_long_nhap_khoan_tg"
                )}
              </p>
              <RangePicker
                className=" custom-range-picker bg-dark text-white w25"
                format="YYYY-MM-DD"
                placeholder={[
                  t(
                    "thoi_gian_bat_dau"
                  ),
                  t(
                    "thoi_gian_ket_thuc"
                  ),
                ]}
                onChange={(
                  value,
                  dateString
                ) => {
                  console.log(
                    "Formatted Selected Time: ",
                    dateString
                  );
                  formik.setFieldValue(
                    "startDate",
                    dateString[0]
                  );
                  formik.setFieldValue(
                    "endDate",
                    dateString[1]
                  );
                }}
                onOk={onOk}
              />
              
            </div>
            {/* <div className="py-4">
              <h1 className="mb-4" style={{fontSize:"50px"}}>
                {t("lich_live")}
              </h1>
              <div className="w50">
                <InputLableCustom
                  title={t("tg_live")}
                  type="date"
                  value={
                    formik.values.live
                  }
                  onChange={formik.handleChange(
                    "live"
                  )}
                  onBlur={formik.handleBlur(
                    "live"
                  )}
                />
              </div>
            </div> */}
            <hr
              className="text-white"
            />
            <div className="mt-5">
              <h3 className="mb-2" style={{fontSize:"50px"}}>
                {t("pham_vi")}
              </h3>
              <h5 className="mb-5">
                {t("vui_long_chon_pv")}
              </h5>
              <div className="d-flex gap-30 align-items-center">
                <div className="d-flex py-4 gap-30 align-items-center flex-wrap">
                  {phamvivideo.map(
                    (e, index) => (
                      <InputRadioCustom
                        className="video"
                        key={index}
                        type="radio"
                        title={e.title}
                        value={e.value}
                        id={e.id}
                        onChange={(
                          e
                        ) => {
                          setSelectedPhamvitieuchuan(
                            e.target
                              .value
                          );
                        }}
                        checked={
                          selectedPhamvitieuchuan ===
                          e.value
                        }
                      />
                    )
                  )}
                </div>
                <p>{t("mieuta")}</p>
              </div>
              <div className="">
                {phamvimongmuong.map(
                  (e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      title={e.title}
                      value={e.value}
                      id={e.id}
                      onChange={(e) => {
                        setSelectedPhamvimongmuon(
                          e.target.value
                        );
                      }}
                      checked={
                        selectedPhamvimongmuon ===
                        e.value
                      }
                    />
                  )
                )}
              </div>
            </div>
            <div className="mt-5">
            {
                formik.values.titleProject ==="" || formik.values.endDate==="" ||
                formik.values.startDate ===""
                ? (
                <p className="warning-box mb-0">
                  <div className="d-flex align-items-center">
                    <CiWarning
                      style={{
                        color:
                          "#FF0099",
                      }}
                      className="me-3"
                    />
                    <span className="d-block">
                      {t("chon_mot_so")}
                    </span>
                  </div>
                </p>
                ):""
              }
              <div className="d-flex gap-3 mt-3">
                <button
                  style={{
                    width: "15%",
                  }}
                  className="btn_submit"
                  type="submit"
                  onClick={() => {
                    setType(
                      "Bản chính"
                    );
                  }}>
                  {t("gui_don")}
                </button>
                <button
                  style={{
                    width: "15%",
                  }}
                  className="btn_note"
                  type="submit"
                  onClick={() => {
                    setType("Bản nháp");
                  }}>
                  {t("luu_nhap")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
