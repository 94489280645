import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import publicProductService from "./publicProductService";

export const addPublicProduct = createAsyncThunk(
  "res/resprience",
  async (data, thunkApi) => {
    try {
      return await publicProductService.addPublicProduct(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getAllPublic = createAsyncThunk(
  "public/get-all",
  async (thunkApi) => {
    try {
      return await publicProductService.getAllPublic();
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getIdPublic = createAsyncThunk(
  "public/get-id",
  async (id,thunkApi) => {
    try {
      return await publicProductService.getIdPublic(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const updateProcessPublic = createAsyncThunk(
  "public/update",
  async (data, thunkApi) => {
    try {
      return await publicProductService.updateProcess(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
const initialState = {
  publicProduct: [],
  idPublic:{},
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const resExpriSlice = createSlice({
  name: "publicProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addPublicProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPublicProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(addPublicProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getAllPublic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPublic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.publicProduct = action.payload;
      })
      .addCase(getAllPublic.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.publicProduct = null;
      })


      .addCase(getIdPublic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIdPublic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.idPublic = action.payload;
      })
      .addCase(getIdPublic.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.idPublic = null;
      })

      .addCase(updateProcessPublic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProcessPublic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(updateProcessPublic.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });
  },
});
export const { resetImages } = resExpriSlice.actions;
export default resExpriSlice.reducer;
