import React from "react";
import a1 from "../images/a1.jpg";
import a2 from "../images/a2.jpg";
import a3 from "../images/a3.jpg";
import a4 from "../images/a4.jpg";
import a5 from "../images/a5.jpg";
import a6 from "../images/a6.jpg";
import { Container, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

export default function InfoService({ t }) {
  const data = [
    {
      image: a1,
      type: "left",
      title: t("cong_bo_san_pham"),
      description: t("tien_hanh_xuat_khau"),
    },
    {
      image: a2,
      type: "right",
      title: t("hoat_dong_trai_nghiem"),
      description: t("neu_khong_chac"),
    },
    {
      image: a3,
      type: "left",
      title: t("ban_hang_ky_gui"),
      description: t("chung_toi_uy_thac"),
    },
    {
      image: a4,
      type: "right",
      title: t("influencer_marketing"),
      description: t("tham_gia_tiep_thi"),
    },
    {
      image: a5,
      type: "left",
      title: t("live_commerce"),
      description: t("tien_hanh_live"),
    },
    {
      image: a6,
      title: t("bao_gia_tai_thoi_diem"),
      description: t("hay_nhan_bao_gia"),

      type: "right",
    },
  ];
  return (
    <section className="pad100 text-white py-4">
      <div className="py-4">
        <h1>{t("thong_tin_dich_vu")}</h1>
      </div>
      <div className="d-flex flex-column gap-5 ">
        {data?.map((item, index) => {
          return (
            <div
              key={index}
              className="p-5 flex-dic"
              style={{
                background: "#1A1A1A",
                borderRadius: "100px",
                display: "flex",
                gap: "20px",
              }}
            >
              <div className="" style={{ flex: 3 }}>
                <div className="py-4">
                  <h3
                    className=" abeezee-regular-italic  text-end"
                    style={{ fontSize: "50px" }}
                  >
                    {item.title}
                  </h3>
                </div>
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "40px",
                  }}
                  className="text-end abeezee-regular-italic "
                >
                  {item.description}
                </p>
              </div>
              <div style={{ flex: 1 }} className="">
                <img
                  src={item.image}
                  width="100%"
                  height="auto"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          );
        })}
        {/* <div className="row item_header p-4 py-5 mb-5">
          <div className="col-md-8 col-sm-12 mb-3 order-md-1 order-2">
            <div className="py-4">
              <h3 className="text-end">Hoạt động trải nghiệm</h3>
            </div>
            <p
              className="text-end"
              style={{
                fontSize: "24px",
              }}
            >
              Nếu không chắc sản phẩm của mình có cạnh tranh được ở thị trường
              Việt Nam hay không? Hãy tham gia hoạt động trải nghiệm sản phẩm.
              Tìm hiểu khả năng cạnh tranh sản phẩm của bạn bằng cách để người
              tại địa phương trực tiếp sử dụng, trải nghiệm sản phẩm của bạn.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 mb-3 order-md-2 order-1">
            <img src={a2} width="100%" alt="" />
          </div>
        </div>
        <div className="row item_header p-4 py-5 mb-5">
          <div className="col-md-4 col-sm-12 mb-3">
            <img src={a3} width="100%" alt="" />
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="py-4">
              <h3>Bán hàng/ Ký gửi</h3>
            </div>
            <p
              style={{
                fontSize: "24px",
              }}
            >
              Chúng tôi ủy thác bán hàng Online và Offline tại Việt Nam.
              <br />
              Bán sản phẩm của bạn thông qua các nền tảng được lựa chọn cẩn
trọng tại Việt Nam.
            </p>
          </div>
        </div>
        <div className="row item_header p-4 py-5 mb-5">
          <div className="col-md-8 col-sm-12 mb-3 order-md-1 order-2">
            <div className="py-4">
              <h3 className="text-end">Influencer Marketing</h3>
            </div>
            <p
              className="text-end"
              style={{
                fontSize: "24px",
              }}
            >
              Tham gia tiếp thị người có tầm ảnh hưởng (Influencer Marketing)
              bằng cách liên kết trực tiếp với những Influencer thông qua ứng
              dụng SNS số 1 Việt Nam về số lượng người dùng.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 mb-3 order-md-2 order-1">
            <img src={a4} width="100%" alt="" />
          </div>
        </div>
        <div className="row item_header p-4 py-5 mb-5">
          <div className="col-md-4 col-sm-12 mb-3">
            <img src={a5} width="100%" alt="" />
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="py-4">
              <h3>Live commerce</h3>
            </div>
            <p
              style={{
                fontSize: "24px",
              }}
            >
              Tiến hành Live commerce thông qua các phương tiện truyền thông có
              lượng người xem lớn nhất tại Việt Nam. Đây là hình thức bán hàng
              online theo thời gian thực thông qua những người live nổi tiếng ở
              địa phương.
            </p>
          </div>
        </div>
        <div className="row item_header p-4 py-5 mb-5">
          <div className="col-md-8 col-sm-12 mb-3 order-md-1 order-2">
            <div className="py-4">
              <h3 className="text-end">Báo giá tại thời điểm yêu cầu</h3>
            </div>
            <p
              className="text-end"
              style={{
                fontSize: "24px",
              }}
            >
              Hãy nhận báo giá và hỏi đáp! AI cung cấp báo giá sơ bộ nhanh mà
              không cần bất kỳ yêu cầu nào.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 mb-3 order-md-2 order-1">
            <img src={a6} width="100%" alt="" />
          </div>
        </div> */}
      </div>
    </section>
  );
}
