import React, { useEffect, useState } from "react";
import { Table, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getAllExprience,
  updateProcessExprience,
} from "../features/registerExprience/resExpriSlice";

const { Option } = Select;

export default function AdminRegisterExpirence({ t }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const columns = [
    { title: "No.", dataIndex: "key" },
    {
      title: t("lich_trinh"),
      dataIndex: "schedule",
    },
    {
      title: t("tien_trinh"),
      dataIndex: "process",
      filters: [
        { text: t("cho"), value: "Chờ" },
        {
          text: t("dang_tien_hanh"),
          value: "Đang tiến hành",
        },
        {
          text: t("hoan_thanh"),
          value: "Hoàn thành",
        },
      ],
      onFilter: (value, record) => record.process.props.value.includes(value),
    },
    {
      title: t("ten_du_an"),
      dataIndex: "titleProject",
    },
    {
      title: t("sdt"),
      dataIndex: "phone",
    },
    {
      title: t("mail"),
      dataIndex: "email",
    },
    {
      title: t("ten_sp"),
      dataIndex: "titleProduct",
    },
    {
      title: t("trong_luong"),
      dataIndex: "trongluong",
    },
    {
      title: t("the_loai"),
      dataIndex: "theloai",
    },
    {
      title: t("gioitinh"),
      dataIndex: "sex",
    },
    {
      title: t("nhom_tuoi"),
      dataIndex: "age",
    },
    {
      title: t("hinh_anh"),
      dataIndex: "images",
      render: (text, record) => (
        <div>
          {record.images?.map((img, index) => (
            <img
              key={index}
              src={img.url}
              width="80px"
              height="80px"
              alt={`Product Image ${index + 1}`}
              style={{
                marginRight: "5px",
              }} // Optional styling
            />
          ))}
        </div>
      ),
    },
  ];
  useEffect(() => {
    dispatch(getAllExprience());
    window.scroll(0, 0);
  }, [dispatch]);

  const res = useSelector((state) => state.registerPri.resExpri.getAll);

  const handleOnchangeOrder = (id, value) => {
    const data = {
      _id: id,
      status: value,
    };
    dispatch(updateProcessExprience(data));
    setTimeout(() => {
      dispatch(getAllExprience());
    }, 300);
  };

  useEffect(() => {
    if (res) {
      const formattedData = res.map((item, index) => ({
        key: index + 1,
        schedule: `${moment(item?.schedule?.startDate).format(
          "DD/MM/YYYY"
        )} ~ ${moment(item?.schedule?.endDate).format("DD/MM/YYYY")}`,
        process: (
          <Select
            style={{ width: "150px" }}
            value={item?.process}
            onChange={(value) => handleOnchangeOrder(item?._id, value)}
          >
            <Option value="Chờ">{t("cho")}</Option>
            <Option value="Đang tiến hành">{t("dang_tien_hanh")}</Option>
            <Option value="Hoàn thành">{t("hoan_thanh")}</Option>
          </Select>
        ),
        titleProject: item?.titleProject,
        phone: item?.registerBy?.mobile,
        email: item?.registerBy?.email,
        titleProduct: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p key={index}>{` ${index + 1}: ${e?.titleProduct}`}</p>
            ))}
          </>
        ),
        trongluong: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p key={index}>
                {` ${index + 1}: ${e?.trongluong}`}
              </p>
            ))}
          </>
        ),
        theloai: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p key={index}>{` ${index + 1}: ${e?.theloai}`}</p>
            ))}
          </>
        ),
        sex: item?.sex,
        age: item?.age,
        images: item?.infoProduct?.flatMap((e) => e?.imageProduct || []),
      }));
      setData(formattedData.reverse());
    }
  }, [res]);

  return (
    <div className="container py-5">
      <div className="py-5 text-white">
        <h1>{t("dang_ky_trai_nghiem")}</h1>
      </div>
      <div className="mt-5">
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={data}
        />
      </div>
    </div>
  );
}
