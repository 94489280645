import React from "react";
import b1 from "../images/icons/b1.png";
import b2 from "../images/icons/b2.png";
import b3 from "../images/icons/b3.png";
import b4 from "../images/icons/b4.png";
import b5 from "../images/b5.jpg";
import b6 from "../images/b6.jpg";
import { useNavigate } from "react-router-dom";

import live from "../images/live.jpg";
import tiktok from "../images/tiktok.png";
import ins from "../images/ins.png";
import InfoService from "../components/InfoService";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Col, Row, Container } from "react-bootstrap";
// import "./HomePage.css"; // Assuming you have a CSS file for custom styles

export default function HomePage({ t }) {
  const navigate = useNavigate();
  return (
    <>
      <section className="">
        <div className="">
          <div
            className="text-white position-relative"
            style={{ top: "-90px" }}
          >
            <div className="row">
              <div
                style={{ paddingLeft: "200px" }}
                className="col-lg-6 col-md-12 d-flex align-items-center"
              >
                <div className="">
                  <h3
                    style={{ fontSize: "40px" }}
                    dangerouslySetInnerHTML={{
                      __html: t("viet_nam_thi_truong"),
                    }}
                  ></h3>
                  <h1
                    style={{ fontSize: "70px" }}
                    dangerouslySetInnerHTML={{
                      __html: t("cung_viety"),
                    }}
                  ></h1>
                  <h2
                    className="py-4 text-highlight"
                    style={{
                      cursor: "pointer",
                      color: "#ff0099",
                      fontSize: "40px",
                    }}
                    onClick={() => navigate("/create-pricing")}
                  >
                    {t("nhan_bao_gia")}
                    <FaAngleDoubleRight />
                  </h2>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div
                  style={{
                    position: "relative",
                    top: "-50px",
                    right: 0,
                    width: "100%",
                  }}
                  className="flex"
                >
                  <div className="d-flex" style={{ width: "100%" }}>
                    <div style={{ flex: 1 }}>
                      <img
                        style={{
                          borderRadius: 110,
                        }}
                        src={b1}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <img
                        style={{
                          borderRadius: 110,
                        }}
                        src={b3}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <img
                        style={{
                          borderRadius: 110,
                        }}
                        src={b2}
                      />
                    </div>
                    <div>
                      <img
                        style={{
                          borderRadius: 110,
                        }}
                        src={b4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-6 col-md-12">
                <div className="">
                  <div className="d-flex justify-content-start">
                    <img src={b5} width="50%" alt="" />
                    <img width="50%" src={b6} alt="" />
                  </div>
                </div>
              </div>
              <div
                style={{ paddingRight: "200px" }}
                className="col-lg-6  col-md-12 d-flex align-items-center justify-content-end"
              >
                <div className="text-end pt-5">
                  <h1
                    style={{ fontSize: "50px" }}
                    dangerouslySetInnerHTML={{
                      __html: t("xuat_khau_k_beauty"),
                    }}
                  ></h1>
                  <h4
                    className="py-3"
                    style={{ fontSize: "30px" }}
                    dangerouslySetInnerHTML={{
                      __html: t("de_dang_trao_doi"),
                    }}
                  ></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <Row>
          <Col sm={8} className="d-flex flex-column">
            <div className="content-wrapper" style={{ paddingLeft: "200px" }}>
              <h3
                className="py-3 text-white"
                style={{ fontSize: "60px" }}
                dangerouslySetInnerHTML={{
                  __html: t("lien_ket_voi_115_nguoi"),
                }}
              ></h3>
              <p
                style={{ fontSize: "30px" }}
                className="text-white fs-4"
                dangerouslySetInnerHTML={{
                  __html: t("chi_hop_tac"),
                }}
              ></p>
            </div>
            <img src={live} width="50%" alt="" />
          </Col>
          <Col sm={4} className="position-relative">
            <div className="d-flex justify-content-end">
              <img src={ins} width="100%" alt="" />
            </div>
            <div
              className="tiktok-wrapper"
              style={{ position: "absolute", bottom: "-250px" }}
            >
              <img src={tiktok} width="100%" alt="" />
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <div className="" style={{ marginTop: "200px" }}>
          <InfoService t={t} />
        </div>
      </section>
    </>
  );
}
