import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import tiktok from "../images/tiktok.png";
import ins from "../images/ins.png";
import { useNavigate } from "react-router-dom";

export default function ForgotIdNext({ t }) {
  const userState = useSelector((state) => state.auth.forgotId.findId);

  // useEffect(() => {
  //   document.body.style.overflowY = "hidden";

  //   return () => {
  //     document.body.style.overflowY = "auto";
  //   };
  // }, []);
  const navigate = useNavigate()
  return (
    <div>
      <div>
        <div
          className="container py-5 mt-5"
          style={{
            position: "relative",
            zIndex: 10000,
          }}
        >
          <div className="text-white py-5 mx-auto" style={{ width: "500px" }}>
            <div className="text-start pb-4">
              <h1>{t("quenID")}</h1>
              <h3>{t("ban_quenID")}</h3>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                {t("ID_da_xem")}
              </label>
              <input
                type="text"
                className="form-control input_custom"
                id="exampleInputPassword1"
                placeholder={userState?.idName}
                disabled
              />
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn_submit" onClick={()=>navigate('/login')}>{t("login")}</button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: "30px",
          zIndex: 9999,
        }}
      >
        <div>
          <img src={ins} alt="" />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "-240px",
            right: "20px",
          }}
        >
          <img src={tiktok} alt="" />
        </div>
      </div>
    </div>
  );
}
