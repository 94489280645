import React, { useEffect, useState } from "react";
import { CiWarning } from "react-icons/ci";
import h1 from "../images/g1.png";
import h2 from "../images/g2.png";
import InputRadioCustom from "../components/InputRadioCustom";
import InputLableCustom from "../components/InputLableCustom";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { FaAngleDown } from "react-icons/fa";
import * as Yup from "yup";
import {
  delImg,
  delImgbg,
  resetImages,
  uploadImg,
  uploadImgBg,
} from "../features/upload/uploadSlice";
import { IoMdFemale } from "react-icons/io";
import { IoMdMale } from "react-icons/io";
import { IoMaleFemaleSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { addPrice } from "../features/createPrice/createPriceSlice";
import { config } from "../utils/axiosconfig";
import { base_url } from "../utils/base_url";
import axios from "axios";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

export default function CreatePricing({ t }) {
  const onOk = (value) => {
    console.log("onOk: ", value);
  };
  let userSchema = Yup.object().shape({
    titleProject: Yup.string().required(t("yc_nhap_ten_da")),
    products: Yup.array()
      .of(
        Yup.object().shape({
          brand: Yup.string().required(t("yc_nhap_thuong_hieu")),
          typeProduct: Yup.string().required(t("yc_nhap_loai_sp")),
          titleProduct: Yup.string().required(t("yc_nhap_ten_sp")),
          trongluong: Yup.string().required(t("yc_nhap_trong_luong")),
          theloai: Yup.string().required(t("yc_nhap_the_loai")),

          phutrach: Yup.string().required(t("yc_nhap_npt")),
          email: Yup.string()
            .email(t("chua_dung_email"))
            .required(t("yc_nhap_email")),
          mobile: Yup.string().required(t("yc_nhap_sdt")),
        })
      )
      .min(1, t("yc_them_sp")),

    priceOne: Yup.string().required(t("yc_gc")),
    HSD: Yup.string().required(t("yc_chon_hang_sd")),
    countRes: Yup.string().required(t("yc_trai_nghiem")),
    titleCompany: Yup.string().required(t("yc_nhap_ten_cty")),
    typeCompany: Yup.string().required(t("yc_nhap_loai_sp")),
    countProduct: Yup.string().required(t("yc_sl_sp")),
  });
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const dispatch = useDispatch();
  const input1 = [
    {
      title: t("ntn"),
      value: t("ntn"),
      id: t("ntn"),
    },
    {
      title: t("cong_bo_san_pham_header"),
      value: t("cong_bo_san_pham_header"),
      id: t("cong_bo_san_pham_header"),
    },
    {
      title: t("ban_hang_ky_gui_header"),
      value: t("ban_hang_ky_gui_header"),
      id: t("ban_hang_ky_gui_header"),
    },
    {
      title: t("influencer_marketing_header"),
      value: t("influencer_marketing_header"),
      id: t("influencer_marketing_header"),
    },
    {
      title: t("live_commerce_header"),
      value: t("live_commerce_header"),
      id: t("live_commerce_header"),
    },
  ];
  const phamvi = [
    {
      title: t("tn_rv_nb"),
      value: t("tn_rv_nb"),
      id: t("tn_rv_nb"),
    },
    {
      title: t("tn_SNS"),
      value: t("tn_SNS"),
      id: t("tn_SNS"),
    },
  ];
  const hinhthuc = [
    {
      title: t("onlineee"),
      value: t("onlineee"),
      id: t("onlineee"),
    },
    {
      title: t("oflineee"),
      value: t("oflineee"),
      id: t("oflineee"),
    },
  ];
  const gioitinh = [
    {
      title: (
        <>
          <IoMdFemale /> {t("nu")}
        </>
      ),
      value: t("nu"),
      id: t("nu"),
    },
    {
      title: (
        <>
          <IoMdMale /> {t("nam")}{" "}
        </>
      ),
      value: t("nam"),
      id: t("nam"),
    },
    {
      title: (
        <>
          <IoMaleFemaleSharp /> {t("khong_phan_biet")}
        </>
      ),
      value: t("khong_phan_biet"),
      id: t("khong_phan_biet"),
    },
  ];
  const nhomtuoi = [
    {
      title: t("treem"),
      value: "Trẻ em",
      id: t("treem"),
    },
    {
      title: "10" + " " + t("u"),
      value: "10 tuổi",
      id: "10" + " " + t("u"),
    },
    {
      title: "20" + " " + t("u"),
      value: "20 tuổi",
      id: "20" + " " + t("u"),
    },
    {
      title: "30" + " " + t("u"),
      value: "30 tuổi",
      id: "30" + " " + t("u"),
    },
    {
      title: "40" + " " + t("u"),
      value: "40 tuổi" ,
      id: "40" + " " + t("u"),
    },
    {
      title:
        "50" +
        " " +
        t("u") +
        t("trolen"),
      value:
        "50 tuổi",
      id:
        "50" +
        " " +
        t("u") +
        t("trolen"),
    },
  ];
  const banquyen = [
    {
      title: t("thang"),
      value: t("thang"),
      id: t("thang"),
    },
    {
      title: t("thang3"),
      value: t("thang3"),
      id: t("thang3"),
    },
    {
      title: t("thang6"),
      value: t("thang6"),
      id: t("thang6"),
    },
    {
      title: t("khsd"),
      value: t("khsd"),
      id: t("khsd"),
    },
  ];
  const countFollower = [
    {
      title: t("muoi_nam"),
      value: t("muoi_nam"),
      id: t("muoi_nam"),
    },
    {
      title: t("nam_muoi"),
      value: t("nam_muoi"),
      id: t("nam_muoi"),
    },
    {
      title: t("muoi_2muoi"),
      value: t("muoi_2muoi"),
      id: t("muoi_2muoi"),
    },
    {
      title: t("haitram_nam"),
      value: t("haitram_nam"),
      id: t("haitram_nam"),
    },
    {
      title: t("nammuoi_tram"),
      value: t("nammuoi_tram"),
      id: t("nammuoi_tram"),
    },
    {
      title: t("tramtrolen"),
      value: t("tramtrolen"),
      id: t("tramtrolen"),
    },
  ];
  const linhvuc = [
    {
      title: t("lamdep"),
      value: t("lamdep"),
      id: t("lamdep"),
    },
    {
      title: t("doisong"),
      value: t("doisong"),
      id: t("doisong"),
    },
    {
      title: t("thoitrang"),
      value: t("thoitrang"),
      id: t("thoitrang"),
    },
    {
      title: t("dadang"),
      value: t("dadang"),
      id: t("dadang"),
    },
  ];
  const up = async (acceptedFiles, productIndex) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => formData.append("images", file)); // Make sure the field name matches

    try {
      const res = await axios.post(
        `${base_url}upload/upload`,
        formData,
        config
      );
      const updatedImages = res.data;
      formik.setFieldValue(
        `products[${productIndex}].imageProduct`,
        updatedImages
      );
      console.log(res.data);
      // Handle response, e.g., update formik field
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(t("upload_failed"));
    }
  };
  const [selectedSevice, setSelectedSevice] = useState(t("ntn"));
  const [selectedLinhvuc, setSelectedLinhvuc] = useState(t("lamdep"));
  const [selectedFollower, setSelectedFollower] = useState(t("muoi_nam"));
  const [selectedBanquyen, setSelectedBanquyen] = useState(t("thang"));
  const [selectedAgeMT, setSelectedAgeMT] = useState([]);
  const [selectedAgeLive, setSelectedAgeLive] = useState([]);
  const [selectedAgeRes, setSelectedAgeRes] = useState([]);
  const [selectedHinhthuc, setSelectedHinhthuc] = useState(t("onlineee"));
  const [selectedGioitinh, setSelectedGioitinh] = useState(t("nu"));
  const [selectedGioitinhRes, setSelectedGioitinhRes] = useState(t("nu"));
  const [selectedPhamvi, setSelectedPhamvi] = useState(t("tn_rv_nb"));
  const [type, setType] = useState("Bản chính");
  const imgState = useSelector((state) => state.upload.images);
  const imgbgState = useSelector((state) => state.upload.imageBackground);
  const auth = useSelector((state) => state.auth.user);
  const img = [];
  const imgbg = [];

  imgState?.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });
  imgbgState?.forEach((i) => {
    imgbg.push({
      public_id: i.public_id,
      url: i.url,
    });
  });
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedAgeMT(prevSelectedAges => {
      const newSelectedAges = checked
        ? [...prevSelectedAges, value]
        : prevSelectedAges.filter(age => age !== value);

      formik.setFieldValue('age', newSelectedAges);

      return newSelectedAges;
    });
  };
  const handleCheckboxChanges = (e) => {
    const { value, checked } = e.target;
    setSelectedAgeLive(prevSelectedAges => {
      const newSelectedAges = checked
        ? [...prevSelectedAges, value]
        : prevSelectedAges.filter(age => age !== value);

      formik.setFieldValue('age', newSelectedAges);

      return newSelectedAges;
    });
  };
  const handleCheckboxChangeRes = (e) => {
    const { value, checked } = e.target;
    setSelectedAgeRes(prevSelectedAges => {
      const newSelectedAges = checked
        ? [...prevSelectedAges, value]
        : prevSelectedAges.filter(age => age !== value);

      formik.setFieldValue('age', newSelectedAges);

      return newSelectedAges;
    });
  };
  useEffect(() => {
    formik.values.imageProduct = img;
    formik.values.imageBg = imgbg;
  }, [img, imgbg]);

  const formik = useFormik({
    initialValues: {
      titleProject: "",
      products: [
        {
          brand: "",
          typeProduct: t("skincare"),
          titleProduct: "",
          trongluong: "",
          theloai: "",
          imageProduct: "",
          email: "",
          mobile: "",
          phutrach: "",
        },
      ],

      count: "",
      age: "",
      sex: "",
      phamvi: "",
      startDate: "",
      endDate: "",

      priceOne: "",
      HSD: "",
      imageBg: "",

      countRes: "",
      titleCompany: "",
      typeCompany: t("skincare"),
      countProduct: "",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (auth !== null) {
        const hasImagesInProducts = values.products.every(
          (product) => product.imageProduct.length > 0
        );

        if (!hasImagesInProducts) {
          toast.warning(t("ban_chua_chon_anh"));
          return; // Prevent form submission
        } else {
          const dataRes = {
            titleProject: values.titleProject,
            infoProduct: values.products,
            choiceDV: selectedSevice,
            schedule: {
              startDate: values.startDate,
              endDate: values.endDate,
            },
            publicProduct: {
              priceOne: values.countRes,
              HSD: values.HSD,
              imgBg: values.imageBg,
            },
            maketing: {
              linhvuc: selectedLinhvuc,
              follower: selectedFollower,
              banquyen: selectedBanquyen,
            },
            muctieu: {
              ageMt: selectedAgeMT,
              sexMt: selectedGioitinh,
            },
            resTn: {
              ageRes: selectedAgeLive,
              sexRes: selectedGioitinhRes,
            },
            countRes: values.countRes,
            titleCompany: values.titleCompany,
            typeCompany: values.typeCompany,
            hinhthai: selectedHinhthuc,
            countProduct: values.countProduct,
            type: type,
          };
          // console.log(dataRes);

          try {
            dispatch(addPrice(dataRes));
            setTimeout(() => {
              formik.resetForm();
              dispatch(resetImages());
              toast.success(t("save"));
              setSelectedSevice(t("ntn"));
              setSelectedLinhvuc(t("lamdep"));
              setSelectedFollower(t("muoi_nam"));
              setSelectedBanquyen(t("thang"));
              setSelectedAgeMT(t("treem"));
              setSelectedAgeLive(t("treem"));
              setSelectedHinhthuc(t("onlineee"));
              setSelectedGioitinh(t("nu"));
              setSelectedGioitinhRes(t("nu"));
              setSelectedPhamvi(t("tn_rv_nb"));
              setType("Bản chính");
            }, 300);
          } catch (error) {
            toast.error(t("dk_tb"));
          }
        }
      } else {
        toast.warning(t("ban_chua_dn"));
      }
    },
  });
  return (
    <div className="prcing-container">
      <section className="container py-5">
        <div className="row text-white">
          <div className="col-lg-6 col-md-12" style={{ zIndex: 1 }}>
            <h1 className="py-3 display-4" style={{ fontSize: "70px" }}>
              {t("xin_chao")}
            </h1>
            <p
              style={{ fontSize: "50px" }}
              className="py-4 fs-5"
              dangerouslySetInnerHTML={{
                __html: t("dv_bao_gia"),
              }}
            ></p>
          </div>
          <div className="col-lg-6 col-md-12" style={{ zIndex: "0" }}>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: "-60px",
              }}
            >
              <img src={h1} alt="" width="80%" />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <img src={h2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <hr className="text-white my-5" />
      <section className="container py-4 text-white">
        <form action="" onSubmit={formik.handleSubmit}>
          <div className="row py-3">
            <h1 className="pb-4" style={{ fontSize: "50px" }}>
              {t("thong_tin_du_an")}
            </h1>

            <div className="col-lg-8 col-md-8 position-relative">
              <div className="d-flex flex-column align-items-center mb-3 w100">
                <div
                  className="gap-3 align-items-center justify-content-between w100"
                  style={{
                    height: "50px",
                  }}
                >
                  <div className="d-flex gap-3 align-items-center justify-content-between">
                    <label
                      htmlFor={t("ten_du_an")}
                      className="me-3 mb-2"
                      style={{
                        flex: 1,
                      }}
                    >
                      {t("ten_du_an")}
                    </label>
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "40%",
                      }}
                    >
                      <select
                        className="form-select input_custom"
                        aria-label="Default select example"
                        style={{
                          flex: 3,
                          width: "100%",
                          borderRadius: "24px",
                          background: "#1a1a1a",
                          color: "white",
                          outline: "none",
                          border: "2px solid #fff",
                          appearance: "none", // Hide the default dropdown arrow
                          paddingRight: "2.5rem", // Add space for the icon
                        }}
                      >
                        <option selected>{t("tao_moi")}</option>
                        <option value={t("tao_cu")}>{t("tao_cu")}</option>
                        <option value={t("ban_nhap")}>{t("ban_nhap")}</option>
                      </select>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "1rem", // Adjust the position from the right
                          transform: "translateY(-50%)",
                          pointerEvents: "none", // Allow clicks to pass through to the select element
                        }}
                      >
                        <FaAngleDown />
                      </div>
                    </div>
                    <input
                      style={{
                        flex: 3,
                      }}
                      type="text"
                      placeholder={t("ten_du_an")}
                      className="input_custom"
                      value={formik.values.titleProject}
                      onChange={formik.handleChange("titleProject")}
                      onBlur={formik.handleBlur("titleProject")}
                    />
                  </div>
                </div>
                <div
                  className="error mb-3 d-flex justify-content-end"
                  style={{
                    top: "100%",
                  }}
                >
                  {formik.touched.titleProject && formik.errors.titleProject ? (
                    <div>{formik.errors.titleProject}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4"></div>
          </div>
          <div className="py-5">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h1 className="mb-3" style={{ fontSize: "50px" }}>
                {t("nhap_thong_tin_sp")}
              </h1>
              <div className="boxed">
                <button
                  className="btn_add"
                  style={{
                    border:
                      "linear-gradient(90deg, #ff0099, #758a65 50%, #00ff38)",
                  }}
                  onClick={() => {
                    const newProduct = {
                      brand: "",
                      typeProduct: t("skincare"),
                      titleProduct: "",
                      trongluong: "",
                      theloai: "",
                      imageProduct: "",
                      email: "",
                      mobile: "",
                      phutrach: "",
                    };
                    formik.setFieldValue("products", [
                      ...formik.values.products,
                      newProduct,
                    ]);
                  }}
                >
                  {t("them_san_pham")}
                </button>
              </div>
            </div>
          </div>
          {formik.values.products?.map((product, index) => (
            <div key={index}>
              <h3 className="pb-4">
                {t("thong_tin_sp")} {index + 1}
              </h3>
              <div className="row pb-4">
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={`products[${index}].brand`}
                  >
                    {t("ten_thuong_hieu")}
                  </label>
                  <div className="w70">
                    <input
                      type="text"
                      placeholder={t("ten_thuong_hieu")}
                      className="input_custom w100"
                      value={product.brand}
                      onChange={formik.handleChange(`products[${index}].brand`)}
                      onBlur={formik.handleBlur(`products[${index}].brand`)}
                      name={`products[${index}].brand`}
                    />
                    {formik.touched.products?.[index]?.brand &&
                    formik.errors.products?.[index]?.brand ? (
                      <div className="error mb-3">
                        {formik.errors.products[index].brand}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={`products[${index}].typeProduct`}
                  >
                    {t("loai_sp")}
                  </label>
                  <div
                    className="w70"
                    style={{
                      position: "relative",
                    }}
                  >
                    <select
                      className="form-select input_custom w100"
                      aria-label="Default select example"
                      defaultValue={"Skincare"}
                      style={{
                        borderRadius: "24px",
                        background: "#1a1a1a",
                        color: "white",
                        outline: "none",
                        border: "2px solid #fff",
                      }}
                      value={product.typeProduct}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name={`products[${index}].typeProduct`}
                    >
                      <option value={t("skincare")}>{t("skincare")}</option>
                      <option value={t("doan")}>{t("doan")}</option>
                      <option value={t("dogiadung")}>{t("dogiadung")}</option>
                      <option value={t("sach")}>{t("sach")}</option>
                    </select>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "1rem", // Adjust as needed
                        transform: "translateY(-50%)",
                        pointerEvents: "none", // Allow clicks to pass through to the select
                        color: "#fff", // Match the color with the select
                      }}
                    >
                      <FaAngleDown />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={`products[${index}].titleProduct`}
                  >
                    {t("ten_sp")}
                  </label>
                  <div className="w70">
                    <input
                      type="text"
                      placeholder={t("ten_sp")}
                      className="input_custom w100"
                      value={product.titleProduct}
                      onChange={formik.handleChange(
                        `products[${index}].titleProduct`
                      )}
                      onBlur={formik.handleBlur(
                        `products[${index}].titleProduct`
                      )}
                      name={`products[${index}].titleProduct`}
                    />
                    {formik.touched.products?.[index]?.titleProduct &&
                    formik.errors.products?.[index]?.titleProduct ? (
                      <div className="error mb-3">
                        {formik.errors.products[index].titleProduct}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={`products[${index}].trongluong`}
                  >
                    {t("trong_luong")}
                  </label>
                  <div className="w70">
                    <input
                      type="text"
                      placeholder={t("trong_luong")}
                      className="input_custom w100"
                      value={product.trongluong}
                      onChange={formik.handleChange(
                        `products[${index}].trongluong`
                      )}
                      onBlur={formik.handleBlur(
                        `products[${index}].trongluong`
                      )}
                      name={`products[${index}].trongluong`}
                    />
                    {formik.touched.products?.[index]?.trongluong &&
                    formik.errors.products?.[index]?.trongluong ? (
                      <div className="error mb-3">
                        {formik.errors.products[index].trongluong}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={`products[${index}].theloai`}
                  >
                    {t("the_loai")}
                  </label>
                  <div className="w70">
                    <input
                      type="text"
                      placeholder={t("theloai_")}
                      className="input_custom w100"
                      value={product.theloai}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name={`products[${index}].theloai`}
                    />
                    {formik.touched.products?.[index]?.theloai &&
                    formik.errors.products?.[index]?.theloai ? (
                      <div className="error mb-3">
                        {formik.errors.products[index].theloai}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={`products[${index}].imageProduct`}
                  >
                    {t("them_ha")}
                  </label>
                  <div
                    className="w70 d-flex justify-content-between"
                    style={{
                      height: "100%",
                    }}
                  >
                    <div
                      className="img_post w70 d-flex align-items-center justify-content-between text-center"
                      style={{
                        height: "100%",
                      }}
                    >
                      <section className="image-section">
                      {product.imageProduct &&
                            product.imageProduct?.map((i, j) => {
                              const fileName = i.url.split('/').pop().split('?')[0];
                              return (
                                <div className="position-relative" key={j}>
                                  <button
                                    type="button"
                                    onClick={() => dispatch(delImg(i.public_id))}
                                    className="btn-close position-absolute"
                                    style={{
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></button>
                                  <p className="file-name mb-0">{fileName}</p>
                                </div>
                              );
                            })}
                      </section>
                    </div>
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        if (auth !== null) {
                          up(acceptedFiles, index);
                        } else {
                          toast.warning(t("ban_chua_dn"));
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="">
                          <div {...getRootProps()} className="upload-button">
                            <input {...getInputProps()} />
                            <button type="button" className="btn_upload">
                              {t("tai_len")}
                            </button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={`products[${index}].phutrach`}
                  >
                    {t("nguoi_phu_trach")}
                  </label>
                  <div className="w70">
                    <input
                      type="text"
                      placeholder={t("nguoi_phu_trach")}
                      className="input_custom w100"
                      value={product.phutrach}
                      onChange={formik.handleChange(
                        `products[${index}].phutrach`
                      )}
                      onBlur={formik.handleBlur(`products[${index}].phutrach`)}
                    />
                    {formik.touched.products?.[index]?.phutrach &&
                    formik.errors.products?.[index]?.phutrach ? (
                      <div className="error mb-3">
                        {formik.errors.products[index].phutrach}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={t(`products[${index}].email`)}
                  >
                    Email
                  </label>
                  <div className="w70">
                    <input
                      type="email"
                      placeholder="example@example.com"
                      className="input_custom w100"
                      value={product.email}
                      onChange={formik.handleChange(`products[${index}].email`)}
                      onBlur={formik.handleBlur(`products[${index}].email`)}
                    />
                    {formik.touched.products?.[index]?.email &&
                    formik.errors.products?.[index]?.email ? (
                      <div className="error mb-3">
                        {formik.errors.products[index].email}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={t(`products[${index}].mobile`)}
                  >
                    {t("sdt")}
                  </label>
                  <div className="w70">
                    <input
                      type="number"
                      placeholder="+84"
                      className="input_custom w100"
                      value={product.mobile}
                      onChange={formik.handleChange(
                        `products[${index}].mobile`
                      )}
                      onBlur={formik.handleBlur(`products[${index}].mobile`)}
                    />
                    {formik.touched.products?.[index]?.mobile &&
                    formik.errors.products?.[index]?.mobile ? (
                      <div className="error mb-3">
                        {formik.errors.products[index].mobile}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <hr className="text-white my-5" />

          <div className="py-4 mt-5">
            <div>
              <h1>{t("dv_bao_hanh")}</h1>
              <p>{t("co_the_chon")}</p>
            </div>
            <div className="d-flex flex-wrap justify-content-between mb-5 pt-2">
              {input1.map((e, index) => (
                <InputRadioCustom
                  key={index}
                  title={e.title}
                  id={e.id}
                  type="radio"
                  checked={selectedSevice === e.value}
                  value={e.value}
                  onChange={(e) => {
                    setSelectedSevice(e.target.value);
                  }}
                />
              ))}
            </div>
            <div className="mt-5">
              <h1>{t("lich_trinh")}</h1>
              <p>{t("vui_long_nhap_khoan_tg")}</p>
              <RangePicker
                className=" custom-range-picker bg-dark text-white w25"
                format="YYYY-MM-DD"
                placeholder={[t("thoi_gian_bat_dau"), t("thoi_gian_ket_thuc")]}
                onChange={(value, dateString) => {
                  console.log("Formatted Selected Time: ", dateString);
                  formik.setFieldValue("startDate", dateString[0]);
                  formik.setFieldValue("endDate", dateString[1]);
                }}
                onOk={onOk}
              />
            </div>
            <div className="mt-5">
              <h1 className="mb-5">{t("live_commerce")}</h1>
              <div className="pb-4">
                <h4 className="mb-2">{t("i4_muctieu")}</h4>
                <h6 className="mb-2">{t("vui_long_chon")}</h6>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h4 className="pb-2 mt-4" style={{ fontSize: "40px" }}>
                    {t("nhom_tuoi")}
                  </h4>

                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                  {nhomtuoi.map((e, index) => (
                  <InputRadioCustom
                    key={index}
                    type="checkbox"
                    title={e.title}
                    id={e.id}
                    value={e.value}
                    onChange={handleCheckboxChange}
                    checked={selectedAgeMT.includes(e.value)}
                  />
                ))}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <h3 className="pb-2 mt-5" style={{ fontSize: "40px" }}>
                    {t("gioitinh")}
                  </h3>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    {gioitinh.map((e, index) => (
                      <InputRadioCustom
                        key={index}
                        type="radio"
                        title={e.title}
                        value={e.value}
                        onChange={(e) => {
                          setSelectedGioitinhRes(e.target.value);
                        }}
                        checked={selectedGioitinhRes === e.value}
                      />
                    ))}
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <h3 className="py-3 mt-5" style={{ fontSize: "40px" }}>
                      {t("so_luong")}
                    </h3>

                    <div className="">
                      <input
                        type="number"
                        placeholder={t("so_nguoi_tn")}
                        className="input_custom"
                        value={formik.values.countRes}
                        onChange={formik.handleChange("countRes")}
                        onBlur={formik.handleBlur("countRes")}
                      />
                      {formik.touched.countRes && formik.errors.countRes ? (
                        <div className="error mb-3">
                          {formik.errors.countRes}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-6">
                    <h3 className="py-3 mt-5" style={{ fontSize: "40px" }}>
                      {t("pham_vi")}
                    </h3>
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      {phamvi.map((e, index) => (
                        <InputRadioCustom
                          key={index}
                          type="radio"
                          title={e.title}
                          value={e.value}
                          id={e.id}
                          onChange={(e) => {
                            setSelectedPhamvi(e.target.value);
                          }}
                          checked={selectedPhamvi === e.value}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* công bố sản phẩm */}
            <hr className="text-white my-5" />
            <div className="mt-5">
              <h1>{t("cong_bo_san_pham")}</h1>
              <div
                className="row pb-4 mt-4 align-items-center justify-content-between"
                style={{
                  height: "100px",
                }}
              >
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label className="me-3 w30" htmlFor={t("gia_cai")}>
                    {t("gia_cai")}
                  </label>
                  <div className="w70">
                    <input
                      type="text"
                      placeholder={t("vl_nhapso")}
                      className="input_custom w100"
                      value={formik.values.priceOne}
                      onChange={formik.handleChange("priceOne")}
                      onBlur={formik.handleBlur("priceOne")}
                    />
                    {formik.touched.priceOne && formik.errors.priceOne ? (
                      <div className="error mb-3">{formik.errors.priceOne}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <InputLableCustom
                    title="HSD"
                    type="date"
                    value={formik.values.HSD}
                    onChange={formik.handleChange("HSD")}
                    onBlur={formik.handleBlur("HSD")}
                  />
                </div>
                <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                  <label className="me-3 w30" htmlFor={t("hinh_nen_trang")}>
                    {t("hinh_nen_trang")}
                  </label>
                  <div
                    className="w70 d-flex justify-content-between"
                    style={{
                      height: "100%",
                    }}
                  >
                    <div className="img_post d-flex align-items-center justify-content-between text-center w70">
                      <section className="image-section">
                      {imgbgState &&
                            imgbgState?.map((i, j) => {
                              const fileName = i.url.split('/').pop().split('?')[0];
                              return (
                                <div className="position-relative" key={j}>
                                  <button
                                    type="button"
                                    onClick={() => dispatch(delImgbg(i.public_id))}
                                    className="btn-close position-absolute"
                                    style={{
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></button>
                                  <p className="file-name mb-0">{fileName}</p>
                                </div>
                              );
                            })}
                      </section>
                    </div>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        dispatch(uploadImgBg(acceptedFiles))
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="btn-upload" {...getRootProps()}>
                          {" "}
                          <input {...getInputProps()} />{" "}
                          <button type="button" className="btn_upload">
                            {t("tai_len")}
                          </button>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
            </div>
            {/* maketing */}
            <div className="mt-5">
              <h1 style={{ fontSize: "50px" }}>{t("influencer_marketing")}</h1>
              <div className="mb-4">
                <h3 className="pb-2 mt-5" style={{ fontSize: "40px" }}>
                  {t("linh_vuc")}
                </h3>
                <div className="d-flex flex-wrap justify-content-between gap-20">
                  {linhvuc.map((e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      id={e.id}
                      title={e.title}
                      value={e.value}
                      onChange={(e) => {
                        setSelectedLinhvuc(e.target.value);
                      }}
                      checked={selectedLinhvuc === e.value}
                    />
                  ))}
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className="mb-4">
                <h3 className="pb-2 mt-5" style={{ fontSize: "40px" }}>
                  {t("sl_follow")}
                </h3>

                <div className="d-flex flex-wrap justify-content-between">
                  {countFollower.map((e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      title={e.title}
                      value={e.value}
                      id={e.id}
                      onChange={(e) => {
                        setSelectedFollower(e.target.value);
                      }}
                      checked={selectedFollower === e.value}
                    />
                  ))}
                </div>
              </div>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="" style={{ fontSize: "40px", width: "30%" }}>
                    {t("ban_quyen")}
                  </span>
                  <span className="be-vietnam-pro-regular">
                    *Khi có được bản quyền hình ảnh, tất cả nội dung được sáng
                    tạo đều có thể được chỉnh sửa/xử lý và sử dụng tự do. Tuy
                    nhiên, nó không thể được sử dụng để quảng cáo phóng đại hoặc
                    sai sự thật.
                  </span>
                </div>

                <div className="d-flex justify-content-between flex-wrap gap-20">
                  {banquyen.map((e, index) => (
                    <InputRadioCustom
                      key={index}
                      type="radio"
                      title={e.title}
                      value={e.value}
                      id={e.id}
                      onChange={(e) => {
                        setSelectedBanquyen(e.target.value);
                      }}
                      checked={selectedBanquyen === e.value}
                    />
                  ))}
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
            <hr className="text-white my-5" />

            <div className="mt-5">
              <h1 className="mb-2" style={{ fontSize: "50px" }}>
                {t("i4_muctieu")}
              </h1>
              <h4 className="mb-2" style={{ fontSize: "18px" }}>
                {t("vui_long_chon")}
              </h4>
              <h4 className="pb-3 mt-4">{t("nhom_tuoi")}</h4>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
              {nhomtuoi.map((e, index) => (
                  <InputRadioCustom
                    key={index}
                    type="checkbox"
                    title={e.title}
                    id={e.id}
                    value={e.value}
                    onChange={handleCheckboxChanges}
                    checked={selectedAgeLive.includes(e.value)}
                  />
                ))}
              </div>
            </div>
            <div className="mt-5">
              <h3 className="pb-3" style={{ fontSize: "40px" }}>
                {t("gioitinh")}
              </h3>
              <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
                {gioitinh.map((e, index) => (
                  <InputRadioCustom
                    key={index}
                    type="radio"
                    title={e.title}
                    id={e.id}
                    value={e.value}
                    onChange={(e) => {
                      setSelectedGioitinh(e.target.value);
                    }}
                    checked={selectedGioitinh === e.value}
                  />
                ))}
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <hr className="text-white my-5" />

            <div className="mt-5">
              <h1 style={{ fontSize: "50px" }}>{t("dang_ky_trai_nghiem")}</h1>
              <p>{t("vui_long_chon")}</p>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h4 className="pb-2 mt-4" style={{ fontSize: "40px" }}>
                    {t("nhom_tuoi")}
                  </h4>

                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                  {nhomtuoi.map((e, index) => (
                  <InputRadioCustom
                    key={index}
                    type="checkbox"
                    title={e.title}
                    id={e.id}
                    value={e.value}
                    onChange={handleCheckboxChangeRes}
                    checked={selectedAgeRes.includes(e.value)}
                  />
                ))}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <h3 className="pb-2 mt-5" style={{ fontSize: "40px" }}>
                    {t("gioitinh")}
                  </h3>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    {gioitinh.map((e, index) => (
                      <InputRadioCustom
                        key={index}
                        type="radio"
                        title={e.title}
                        value={e.value}
                        onChange={(e) => {
                          setSelectedGioitinhRes(e.target.value);
                        }}
                        checked={selectedGioitinhRes === e.value}
                      />
                    ))}
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <h3 className="py-3 mt-5" style={{ fontSize: "40px" }}>
                      {t("so_luong")}
                    </h3>

                    <div className="">
                      <input
                        type="number"
                        placeholder={t("so_nguoi_tn")}
                        className="input_custom"
                        value={formik.values.countRes}
                        onChange={formik.handleChange("countRes")}
                        onBlur={formik.handleBlur("countRes")}
                      />
                      {formik.touched.countRes && formik.errors.countRes ? (
                        <div className="error mb-3">
                          {formik.errors.countRes}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-6">
                    <h3 className="py-3 mt-5" style={{ fontSize: "40px" }}>
                      {t("pham_vi")}
                    </h3>
                    <div className="d-flex justify-content-between align-items-center gap-3">
                      {phamvi.map((e, index) => (
                        <InputRadioCustom
                          key={index}
                          type="radio"
                          title={e.title}
                          value={e.value}
                          id={e.id}
                          onChange={(e) => {
                            setSelectedPhamvi(e.target.value);
                          }}
                          checked={selectedPhamvi === e.value}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg -6 col-md-12">
            <h1 className="mb-4 mt-5" style={{ fontSize: "50px" }}>
              {t("cua_hang_dai_ly")}
            </h1>
            <h3 className="mb-3" style={{ fontSize: "40px" }}>
              {t("ten_thuong_hieu")}
            </h3>
            <div className="d-flex mb-3">
              <div className="row">
                <div className="col-lg-12 col-md-12 mb-3">
                  <label className="me-3 mb-3" htmlFor={t("ten_cty")}>
                    {t("ten_cty")}
                  </label>
                  <div className="">
                    <input
                      type="text"
                      placeholder={t("ten_cty")}
                      className="input_custom"
                      value={formik.values.titleCompany}
                      onChange={formik.handleChange("titleCompany")}
                      onBlur={formik.handleBlur("titleCompany")}
                    />
                    {formik.touched.titleCompany &&
                    formik.errors.titleCompany ? (
                      <div className="error mb-3">
                        {formik.errors.titleCompany}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12 mt-4 col-md-12">
                  <label htmlFor={t("loai_sp")} className="me-3 mb-3">
                    {t("loai_sp")}
                  </label>
                  <div
                    className=""
                    style={{
                      position: "relative",
                    }}
                  >
                    <select
                      className="form-select input_custom"
                      aria-label="Default select example"
                      defaultValue={formik.values.typeProduct}
                      style={{
                        width: "200px",
                        borderRadius: "24px",
                        background: "#1a1a1a",
                        color: "white",
                        outline: "none",
                        border: "2px solid #fff",
                      }}
                      value={formik.values.typeCompany}
                      onChange={formik.handleChange("typeCompany")}
                      onBlur={formik.handleBlur("typeCompany")}
                      name="typeCompany"
                    >
                      <option value={t("skincare")}>{t("skincare")}</option>
                      <option value={t("doan")}>{t("doan")}</option>
                      <option value={t("dogiadung")}>{t("dogiadung")}</option>
                      <option value={t("sach")}>{t("sach")}</option>
                    </select>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "170px", // Adjust the position from the right
                        transform: "translateY(-50%)",
                        pointerEvents: "none", // Allow clicks to pass through to the select element
                      }}
                    >
                      <FaAngleDown />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="mb-3 mt-5" style={{ fontSize: "40px" }}>
              {t("hinh_thai")}
            </h3>

            <div
              className="d-flex align-items-center mb-5"
              style={{ gap: "50px" }}
            >
              {hinhthuc.map((e, index) => (
                <InputRadioCustom
                  key={index}
                  type="radio"
                  title={e.title}
                  value={e.value}
                  id={e.id}
                  onChange={(e) => {
                    setSelectedHinhthuc(e.target.value);
                  }}
                  checked={selectedHinhthuc === e.value}
                />
              ))}
            </div>
            <h3 className="py-3" style={{ fontSize: "40px" }}>
              {t("sl")}
            </h3>

            <div className="">
              <input
                type="number"
                placeholder={t("sl")}
                className="input_custom"
                value={formik.values.countProduct}
                onChange={formik.handleChange("countProduct")}
                onBlur={formik.handleBlur("countProduct")}
              />
              {formik.touched.countProduct && formik.errors.countProduct ? (
                <div className="error mb-3">{formik.errors.countProduct}</div>
              ) : null}
            </div>
          </div>
          <div className="mt-5">
          {
                formik.values.titleProject ==="" || formik.values.endDate==="" ||
                formik.values.startDate ==="" || formik.values.age ===""||formik.values.phamvi===""
                || formik.values.sex ==="" || formik.values.priceOne ==="" || formik.values.phamvi===""
                ? (
                <p className="warning-box mb-0">
                  <div className="d-flex align-items-center">
                    <CiWarning
                      style={{
                        color:
                          "#FF0099",
                      }}
                      className="me-3"
                    />
                    <span className="d-block">
                      {t("chon_mot_so")}
                    </span>
                  </div>
                </p>
                ):""
              }
            <div className="d-flex gap-3 mt-3">
              <button
                style={{ width: "15%" }}
                className="btn_submit"
                onClick={() => {
                  setType("Bản chính");
                }}
                type="submit"
              >
                {t("gui_don")}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
