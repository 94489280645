import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

// tham so la img
const addPrice = async (data) => {
  const res = await axios.post(
    `${base_url}price/add-price`,
    data,
    config
  );
  return res.data;
};
const getAllPrice = async () => {
  const res = await axios.get(
    `${base_url}price/get-all-price`,
    config
  );
  return res.data;
};
const updateProcess = async (data) => {
  const res = await axios.put(
    `${base_url}price/update-process/${data._id}/${data.status}`,
    "",
    config
  );
  return res.data;
};
const getIdPrice = async (id) => {
  const res = await axios.get(
    `${base_url}price/get-id-price/${id}`,
    config
  );
  return res.data;
};
const uploadService = {
  addPrice,
  getAllPrice,
  updateProcess,getIdPrice
};

export default uploadService;
