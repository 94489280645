import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import addProductService from "./addProductService";

export const addProducts = createAsyncThunk(
  "product/product",
  async (data, thunkApi) => {
    try {
      return await addProductService.addProducts(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getAllProduct = createAsyncThunk("product/get-product", async (thunkApi) => {
  try {
    return await addProductService.getAllProduct();
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
export const updateProduct = createAsyncThunk(
  "product/update-product",
  async (data, thunkApi) => {
    try {
      return await addProductService.updateProduct(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
const initialState = {
  addProduct: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const resExpriSlice = createSlice({
  name: "addProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(addProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getAllProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.addProduct = action.payload;
      })
      .addCase(getAllProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.addProduct = [];
      })

      .addCase(updateProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });
  },
});
export const { resetImages } = resExpriSlice.actions;
export default resExpriSlice.reducer;
