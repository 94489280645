import React, { useEffect, useState } from "react";
import { Table, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAllExprience } from "../features/registerExprience/resExpriSlice";

const { Option } = Select;

const columns = [
  { title: "No.", dataIndex: "key" },
  {
    title: "Lịch trình",
    dataIndex: "schedule",
  },
  {
    title: "Tiến trình",
    dataIndex: "process",
  },
  {
    title: "Tên dự án",
    dataIndex: "titleProject",
  },
  {
    title: "Số điện thoại",
    dataIndex: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Tên sản phẩm",
    dataIndex: "titleProduct",
  },
  {
    title: "Trọng lượng",
    dataIndex: "trongluong",
  },
  {
    title: "Thể loại",
    dataIndex: "theloai",
  },
  {
    title: "Giới tính",
    dataIndex: "sex",
  },
  {
    title: "Nhóm tuổi",
    dataIndex: "age",
  },
  {
    title: "Hình ảnh",
    dataIndex: "images",
    render: (text, record) => (
      <img src={record.images} width="80px" height="80px" alt="" />
    ),
  },
];

export default function AdminRegisterLive({ t }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAllExprience());
    window.scroll(0, 0);
  }, [dispatch]);

  const res = useSelector((state) => state.registerPri.resExpri.getAll);

  const handleOnchangeOrder = (id, value) => {
    const data = {
      _id: id,
      status: value,
    };
    console.log(data);
  };

  useEffect(() => {
    if (res) {
      const formattedData = res.map((item, index) => ({
        key: index + 1,
        schedule: `${moment(item?.schedule?.startDate).format(
          "DD/MM/YYYY"
        )} ~ ${moment(item?.schedule?.endDate).format("DD/MM/YYYY")}`,
        process: (
          <Select
            style={{ width: "150px" }}
            defaultValue={item?.process}
            onChange={(value) => handleOnchangeOrder(item?._id, value)}
          >
            <Option value="Chờ">Chờ</Option>
            <Option value="Đang tiến hành">Đang tiến hành</Option>
            <Option value="Hoàn thành">Hoàn thành</Option>
          </Select>
        ),
        titleProject: item?.titleProject,
        phone: item?.registerBy?.mobile,
        email: item?.registerBy?.email,
        titleProduct: item?.infoProduct[0]?.titleProduct,
        trongluong: item?.infoProduct[0]?.trongluong,
        theloai: item?.infoProduct[0]?.theloai,
        sex: item?.sex,
        age: item?.age,
        images: item?.infoProduct[0]?.imageProduct[0]?.url,
      }));
      setData(formattedData);
    }
  }, [res]);

  return (
    <div className="container py-5">
      <div className="py-5 text-white">
        <h1>{t("live_commerce_header")}</h1>
      </div>
      <div className="mt-5">
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={data}
        />
      </div>
    </div>
  );
}
