import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getIdPublic } from "../features/publicProduct/publicProductSlice";
import moment from "moment";

export default function ManagerPublicProduct({ t }) {
  const {id} = useParams()
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getIdPublic(id))
    window.scroll(0,0)
  },[])
  const idEx = useSelector(state=>state.publicProduct.idPublic)
  const [info, setInfo] = useState([]);

  useEffect(() => {
    if (idEx?.getId?.infoProduct) {
      try {
        const parsedInfo = JSON.parse(idEx.getId.infoProduct);
        setInfo(parsedInfo);
      } catch (error) {
        console.error("Failed to parse infoProduct:", error);
        setInfo([]);
      }
    } else {
      setInfo([]); 
    }
  }, [idEx]);

  const dangkyPhapNhan = idEx?.[0]?.dangKyPhapNhan;
  console.log(idEx)
  return (
    <div className="" style={{background:"#333333"}}>
      <section className="container py-5 text-white">
        {/* tên dự án */}
        <div className="py-4">
          <h6 className="mb-3">{t("cong_bo_san_pham")}</h6>
          <h1 className="mb-3" style={{fontSize:"50px"}}>{idEx?.getId?.titleProject}</h1>
          <h6 className="mb-5">
            {t("thoigian")} : {idEx?.getId?.createdAt
                ? `${moment(idEx?.getId?.createdAt).format('DD/MM/YYYY')}`
              : 'N/A'}
          </h6>
        </div>
        <hr className="bg-white mb-4 mt-0 p-0" />

        {/* thông tin */}
        <div>
          {
            info?.map((e,index)=>(
              <div key={index}>
                <h3 className="pb-4">{t("thong_tin_sp1")} {index+1}</h3>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                    <label className="me-3" htmlFor={t("ten_thuong_hieu")}>
                      {t("ten_thuong_hieu")}
                    </label>
                    <input
                      type="text"
                      value={e?.brand}
                      disabled
                      placeholder={t("ten_thuong_hieu")}
                      className="input_custom disable"
                    />
                  </div>
                  <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                    <label className="me-3" htmlFor={t("loai_sp")}>
                      {t("loai_sp")}
                    </label>
                    <select
                      className="form-select input_custom disable"
                      aria-label="Default select example"
                      disabled
                      defaultValue={e?.typeProduct}
                      style={{
                        width: "200px",
                        borderRadius: "24px",
                        background: "#1a1a1a",
                        color: "white",
                        outline: "none",
                        border: "2px solid #fff",
                      }}
                    >
                      <option value={t("skincare")}>{t("skincare")}</option>
                      <option value={t("doan")}>{t("doan")}</option>
                      <option value={t("dogiadung")}>{t("dogiadung")}</option>
                      <option value={t("sach")}>{t("sach")}</option>
                    </select>
                  </div>
                  <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                    <label className="me-3" htmlFor={t("ten_sp")}>
                      {t("ten_sp")}
                    </label>
                    <input
                      value={e?.titleProduct}
                      type="text"
                      placeholder={t("ten_sp")}
                      className="input_custom disable"
                      disabled
                    />
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                    <label className="me-3" htmlFor={t("trong_luong")}>
                      {t("trong_luong")}
                    </label>
                    <input
                      type="number"
                      value={e?.trongluong}
                      placeholder={t("trong_luong")}
                      className="input_custom disable"
                      disabled
                    />
                  </div>
                  <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                    <label className="me-3" htmlFor={t("the_loai")}>
                      {t("the_loai")}
                    </label>
                    <input
                      type="text"
                      value={e?.theloai}
                      placeholder={t("the_loai")}
                      disabled
                      className="input_custom disable"
                    />
                  </div>
                  <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                    <label className="me-3" htmlFor={t("them_ha")}>
                      {t("them_ha")}
                    </label>
                    <div className="text-end d-flex justify-content-end">
                        <img
                          src={
                            e?.imageProduct[0]?.url
                          }
                          width="201px"
                          height="47px"
                          alt=""
                          className="disable"
                        />
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <hr className="bg-white mb-4 mt-0 p-0" />
        <div className="py-4">
          <div className="py-3">
            <h3>{t("kq_du_an")}</h3>
          </div>
          <div className="document-upload-container text-white">
            <h6>{t("loai_da")}</h6>
            <span>{t("cong_bo_san_pham")}</span>
            <div className="row mt-5 document-row">
              <div className="col-3 document-col">
                <p className="document-title">{t("ten_tai_lieu")}</p>
              </div>
              <div className="col-3 document-col">
                <p className="document-title">{t("trang_thai")}</p>
              </div>
              <div className="col-6 document-col">
                <p className="document-title">{t("dang_tai")}</p>
              </div>
            </div>
            <hr className="bg-white mb-4 mt-0 p-0" />
            {/* thông tin */}
            <div className="row">
              <div className="col-3">
                <p className="mb-4">{t("phap_nhan")}</p>
              </div>
              <div className="col-3">
                <p className="mb-4">{t("da_nhan")}</p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    type="file"
                    placeholder={dangkyPhapNhan}
                    value={dangkyPhapNhan}
                    className="file-input"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p className="mb-4">{t("giay_uy_quyen")}</p>
              </div>
              <div className="col-3">
                <p className="mb-4">{t("da_nhan")}</p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    type="file"
                    placeholder="upload"
                    className="file-input"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p className="mb-4">{t("giay_kinh_doanh")}</p>
              </div>
              <div className="col-3">
                <p className="mb-4">{t("da_nhan")}</p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    type="file"
                    placeholder="upload"
                    className="file-input"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p className="mb-4">{t("bang_thanh_phan")}</p>
              </div>
              <div className="col-3">
                <p className="mb-4">{t("da_nhan")}</p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    type="file"
                    placeholder="upload"
                    className="file-input"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="mb-4">{t("xac_nhan_lsq")}</p>
              </div>
              <div className="col-3">
                <p className="mb-4">{t("da_nhan")}</p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    type="file"
                    placeholder="upload"
                    className="file-input"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p className="mb-4">{t("giay_chung_nhan")}</p>
              </div>
              <div className="col-3">
                <p className="mb-4">{t("da_nhan")}</p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    type="file"
                    placeholder="upload"
                    className="file-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
