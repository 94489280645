import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import uploadService from "./uploadService";

export const uploadImg =
  createAsyncThunk(
    "upload/images",
    async (data, thunkAPI) => {
      try {
        const formData = new FormData();
        for (
          let i = 0;
          i < data.length;
          i++
        ) {
          formData.append(
            "images",
            data[i]
          );
        }
        return await uploadService.uploadImg(
          formData
        );
      } catch (error) {
        return thunkAPI.rejectWithValue(
          error
        );
      }
    }
  );
export const uploadImgBg =
  createAsyncThunk(
    "upload/imagesBg",
    async (data, thunkAPI) => {
      try {
        const formData = new FormData();
        for (
          let i = 0;
          i < data.length;
          i++
        ) {
          formData.append(
            "images",
            data[i]
          );
        }
        return await uploadService.uploadImg(
          formData
        );
      } catch (error) {
        return thunkAPI.rejectWithValue(
          error
        );
      }
    }
  );
export const uploadImgGpkd =
  createAsyncThunk(
    "upload/imagesgpkd",
    async (data, thunkAPI) => {
      try {
        const formData = new FormData();
        for (
          let i = 0;
          i < data.length;
          i++
        ) {
          formData.append(
            "images",
            data[i]
          );
        }
        return await uploadService.uploadImg(
          formData
        );
      } catch (error) {
        return thunkAPI.rejectWithValue(
          error
        );
      }
    }
  );
export const delImg = createAsyncThunk(
  "delete/images",
  async (id, thunkAPI) => {
    try {
      return await uploadService.deleteImg(
        id
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error
      );
    }
  }
);
export const delImgGpkd =
  createAsyncThunk(
    "delete/imagegpkd",
    async (id, thunkAPI) => {
      try {
        return await uploadService.deleteImg(
          id
        );
      } catch (error) {
        return thunkAPI.rejectWithValue(
          error
        );
      }
    }
  );
export const delImgbg =
  createAsyncThunk(
    "delete/imagebg",
    async (data, thunkAPI) => {
      try {
        return await uploadService.deleteImg(
          data
        );
      } catch (error) {
        return thunkAPI.rejectWithValue(
          error
        );
      }
    }
  );
const initialState = {
  images: [],
  imageGpkd: [],
  imageBackground: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const uploadSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    resetImages: (state) => {
      state.images = [];
      state.imageGpkd = [];
      state.imageBackground = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        uploadImg.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        uploadImg.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.images = action.payload;
        }
      )
      .addCase(
        uploadImg.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.isSuccess = false;
          state.message = action.error;
        }
      )
      .addCase(
        uploadImgGpkd.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        uploadImgGpkd.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.imageGpkd =
            action.payload;
        }
      )
      .addCase(
        uploadImgGpkd.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.isSuccess = false;
          state.message = action.error;
        }
      )

      .addCase(
        uploadImgBg.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        uploadImgBg.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.imageBackground =
            action.payload;
        }
      )
      .addCase(
        uploadImgBg.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.isSuccess = false;
          state.message = action.error;
        }
      )

      .addCase(
        delImg.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        delImg.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.images = [];
        }
      )
      .addCase(
        delImg.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.isSuccess = false;
          state.message =
            action.payload;
        }
      )
      .addCase(
        delImgGpkd.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        delImgGpkd.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.imageGpkd = [];
        }
      )
      .addCase(
        delImgGpkd.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.isSuccess = false;
          state.message =
            action.payload;
        }
      )

      .addCase(
        delImgbg.pending,
        (state) => {
          state.isLoading = true;
        }
      )
      .addCase(
        delImgbg.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isError = false;
          state.isSuccess = true;
          state.imageBackground = [];
        }
      )
      .addCase(
        delImgbg.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.isSuccess = false;
          state.message =
            action.payload;
        }
      );
  },
});
export const { resetImages } =
  uploadSlice.actions;
export default uploadSlice.reducer;
