import React, { useEffect, useState } from "react";
import { Table, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getAllSale,
  updateProcessSale,
} from "../features/registerSale/resSaleSlice";

const { Option } = Select;

export default function AdminRegisterSale({ t }) {
  const columns = [
    { title: "No.", dataIndex: "key" },
    {
      title: t("lich_trinh"),
      dataIndex: "schedule",
    },
    {
      title: t("tien_trinh"),
      dataIndex: "process",
      filters: [
        { text: t("cho"), value: "Chờ" },
        {
          text: t("dang_tien_hanh"),
          value: "Đang tiến hành",
        },
        {
          text: t("hoan_thanh"),
          value: "Hoàn thành",
        },
      ],
      onFilter: (value, record) => {
        const processValue = record.process.props.defaultValue;
        return processValue ? processValue.includes(value) : false;
      },
    },
    {
      title: t("ten_du_an"),
      dataIndex: "titleProject",
    },
    {
      title: t("sdt"),
      dataIndex: "phone",
    },
    {
      title: t("mail"),
      dataIndex: "email",
    },
    {
      title: t("ten_sp"),
      dataIndex: "titleProduct",
    },
    {
      title: t("loai_sp"),
      dataIndex: "typeProduct",
    },
    {
      title: t("trong_luong"),
      dataIndex: "trongluong",
    },
    { title: t("hsd"), dataIndex: "HSD" },
    {
      title: t("gia"),
      dataIndex: "FOB",
    },
    {
      title: t("gia_tien_hq"),
      dataIndex: "priceHQ",
    },
    {
      title: t("gia_tien_vn"),
      dataIndex: "priceVN",
    },
    {
      title: t("store_online"),
      dataIndex: "storeOnline",
    },
    {
      title: t("store_offline"),
      dataIndex: "storeOffline",
    },
  ];
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAllSale());
    window.scroll(0, 0);
  }, [dispatch]);

  const res = useSelector((state) => state.registerSale.resSale.getAll);

  const handleOnchangeOrder = (id, value) => {
    const data = {
      _id: id,
      status: value,
    };
    dispatch(updateProcessSale(data));
    setTimeout(() => {
      dispatch(getAllSale());
    }, 300);
  };

  useEffect(() => {
    if (res) {
      const formattedData = res.map((item, index) => ({
        key: index + 1,
        schedule: `${moment(item?.schedule?.startDate).format(
          "DD/MM/YYYY"
        )} ~ ${moment(item?.schedule?.endDate).format("DD/MM/YYYY")}`,
        process: (
          <Select
            style={{ width: "150px" }}
            defaultValue={item?.process}
            onChange={(value) => handleOnchangeOrder(item?._id, value)}
          >
            <Option value="Chờ">{t("cho")}</Option>
            <Option value="Đang tiến hành">{t("dang_tien_hanh")}</Option>
            <Option value="Hoàn thành">{t("hoan_thanh")}</Option>
          </Select>
        ),
        titleProject: item?.titleProject,
        phone: item?.registerBy?.mobile,
        email: item?.registerBy?.email,
        titleProduct: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{`${index + 1}: ${e?.titleProduct}`}</p>
            ))}
          </>
        ),
        typeProduct: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{` ${index + 1}: ${e?.typeProduct}`}</p>
            ))}
          </>
        ),
        trongluong: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{`${index + 1}: ${e?.trongluong}`}</p>
            ))}
          </>
        ),

        HSD: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{` ${index + 1}: ${e?.HSD}`}</p>
            ))}
          </>
        ),
        FOB: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{` ${index + 1}: ${e?.FOB}`}</p>
            ))}
          </>
        ),
        priceHQ: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{` ${index + 1}: ${e?.priceHQ}`}</p>
            ))}
          </>
        ),
        priceVN: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{` ${index + 1}: ${e?.priceVN}`}</p>
            ))}
          </>
        ),
        storeOnline: (
          <>
            {item?.storeOnline?.map((item, idex) => (
              <span key={idex}>{item + " "} </span>
            ))}{" "}
          </>
        ),

        storeOffline: (
          <>
            {item?.storeOffline?.map((item, idex) => (
              <span key={idex}>{item + " "} </span>
            ))}{" "}
          </>
        ),
      }));
      setData(formattedData.reverse());
    }
  }, [res]);

  return (
    <div className="container py-5">
      <div className="py-5 text-white">
        <h1>{t("ban_hang_ky_gui_header")}</h1>
      </div>
      <div className="mt-5">
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={data}
        />
      </div>
    </div>
  );
}
