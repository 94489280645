import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

// tham so la img
const addPublicProduct = async (
  data
) => {
  const res = await axios.post(
    `${base_url}public/addpublic-product`,
    data,
    config
  );
  return res.data;
};
const getAllPublic = async () => {
  const res = await axios.get(
    `${base_url}public/get-all-product`,
    config
  );
  return res.data;
};
const updateProcess = async (data) => {
  const res = await axios.put(
    `${base_url}public/update-process/${data._id}/${data.status}`,
    "",
    config
  );
  return res.data;
};
const getIdPublic = async (id) => {
  const res = await axios.get(
    `${base_url}public/get-id-product/${id}`,
    config
  );
  return res.data;
};
const publicProductService = {
  addPublicProduct,
  getAllPublic,
  updateProcess,getIdPublic
};

export default publicProductService;
