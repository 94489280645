import React from "react";

export default function Pricing({ t }) {
  return (
    <section className="container py-4 text-white">
      <div className="row py-3">
        <h1 className="pb-3">{t("kq_bao_gia")}</h1>
        <h3 className="pb-4">{t("thong_tin_du_an")}</h3>
        <div className="col-lg-6 col-md-12">
          <div className="d-flex  mb-3">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <label htmlFor={t("thong_tin_du_an")} className="me-3 mb-2">
                  {t("ten_du_an")}
                </label>
                <select
                  className="form-select input_custom me-2"
                  aria-label="Default select example"
                  style={{
                    width: "200px",
                    borderRadius: "24px",
                    background: "#1a1a1a",
                    color: "white",
                    outline: "none",
                    border: "2px solid #fff",
                  }}
                >
                  <option value={t("skincare")}>{t("skincare")}</option>
                  <option value={t("doan")}>{t("doan")}</option>
                  <option value={t("dogiadung")}>{t("dogiadung")}</option>
                  <option value={t("sach")}>{t("sach")}</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-12 mt-3 d-flex align-items-end">
                <input
                  type="text"
                  placeholder={t("ten_du_an")}
                  className="input_custom"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12"></div>
      </div>
      <div className="py-5">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <h1 className="mb-3">{t("nhap_thong_tin_sp")}</h1>
          {/* <button className="btn_add">
            Thêm sản phẩm +
          </button> */}
        </div>
      </div>
      <div>
        <h3 className="pb-4">{t("thong_tin_sp1")}</h3>
        <div className="row pb-4">
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("ten_thuong_hieu")}>
              {t("ten_thuong_hieu")}
            </label>
            <input
              type="text"
              placeholder={t("ten_thuong_hieu")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("loai_sp")}>
              {t("loai_sp")}
            </label>
            <select
              className="form-select input_custom"
              aria-label="Default select example"
              style={{
                width: "200px",
                borderRadius: "24px",
                background: "#1a1a1a",
                color: "white",
                outline: "none",
                border: "2px solid #fff",
              }}
            >
              <option selected>{t("skincare")}</option>
              <option value="1">{t("doan")}</option>
              <option value="2">{t("dogiadung")}</option>
              <option value="3">{t("sach")}</option>
            </select>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("ten_sp")}>
              {t("ten_sp")}
            </label>
            <input
              type="text"
              placeholder={t("ten_sp")}
              className="input_custom"
            />
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("trong_luong")}>
              {t("trong_luong")}
            </label>
            <input
              type="number"
              placeholder={t("trong_luong")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("the_loai")}>
              {t("the_loai")}
            </label>
            <input
              type="text"
              placeholder={t("theloai_")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("them_ha")}>
              {t("them_ha")}
            </label>
            <div className="text-end d-flex justify-content-end">
              <input
                type="text"
                placeholder={t("them_ha")}
                className="input_custom me-2"
              />
            </div>
            <button className=" btn_upload">{t("tai_len")}</button>
          </div>
        </div>
      </div>
      <div className="py-4 mt-5">
        <div className="">
          <div className="py-5">
            <h1>{t("nd_bao_gia")}</h1>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="d-flex align-items-center gap-20">
                <p className="mb-0">{t("loai_da")}</p>
                <p className="mb-0">{t("live_commerce")}</p>
              </div>
            </div>
            <div className="col-8">
              <div className="d-flex align-items-center gap-20">
                <p className="mb-0">{t("lich_trinh")}</p>
                <p className="mb-0">0000-00-00 ~ 0000-00-00</p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-4">
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{t("nhom_tuoi")}</p>
                <p className="mb-0">{t("u")}20</p>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{"sl_follow"}</p>
                <p className="mb-0">10.000</p>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{t("quy_mo")}</p>
                <p className="mb-0">50</p>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{t("ten_thuong_hieu")}</p>
                <p className="mb-0">Miskin</p>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{t("nguoi_phu_trach")}</p>
                <p className="mb-0">Anh tuấn</p>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{t("hinh_thai")}</p>
                <p className="mb-0">{t("online")}</p>
              </div>
            </div>
            <div className="col-8">
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{t("gioitinh")}</p>
                <p className="mb-0">Nữ</p>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <div className="d-flex align-items-center gap-20">
                  <p className="mb-0">{t("linh_vuc")}</p>
                  <p className="mb-0">Làm đẹp</p>
                </div>
                <div className="d-flex align-items-center gap-20">
                  <p className="mb-0">{t("ban_quyen")}</p>
                  <p className="mb-0">6 tháng</p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{t("pham_vi")}</p>
                <p className="mb-0">Trải nghiệm + SNS review</p>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{t("loai_sp")}</p>
                <p className="mb-0">Mỹ phẩm</p>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <div className="d-flex align-items-center gap-20">
                  <p className="mb-0">{t("sdt")}</p>
                  <p className="mb-0">0379661878</p>
                </div>
                <div className="d-flex align-items-center gap-20">
                  <p className="mb-0">{t("mail")}</p>
                  <p className="mb-0">example@example.com</p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-20 mb-2">
                <p className="mb-0">{t("so_luong")}</p>
                <p className="mb-0">7 </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="d-flex gap-3 mt-3">
            <button className="btn_submit">Tiếp tục</button>
            <button className="btn_note">In báo giá</button>
          </div>
        </div>
      </div>
    </section>
  );
}
