import tt from "../images/tt.png";
import ig from "../images/ig.png";
import yt from "../images/yt.png";
import fb from "../images/fb.png";
import shopee from "../images/shopee.png";
import off1 from "../images/off1.png";
import off2 from "../images/off2.png";
import off3 from "../images/off3.png";
import off4 from "../images/off4.png";
import off5 from "../images/off5.png";
import off6 from "../images/off6.png";
import off7 from "../images/off7.png";
import off8 from "../images/off8.png";
import off9 from "../images/off9.png";
import off10 from "../images/off10.png";
import off11 from "../images/off11.png";
export const storeOnline = [
  {
    title: "Shopee",
    value: "Shopee",
    id: "Shopee",
    img: shopee,
  },
  {
    title: "Tiktok Shop",
    value: "Tiktok Shop",
    id: "Tiktok Shop",
    img: tt,
  },
];
export const storeOffline = [
  {
    title: "AEON",
    value: "AEON",
    id: "AEON",
    img: off1,
  },
  {
    title: "Trang tiên",
    value: "Trang tiên",
    id: "Trang tiên",
    img: off2,
  },
  {
    title: "W",
    value: "W",
    id: "W",
    img: off3,
  },
  {
    title: "G",
    value: "G",
    id: "G",
    img: off4,
  },
  {
    title: "VINCOM",
    value: "VINCOM",
    id: "VINCOM",
    img: off5,
  },
  {
    title: "PARKSON",
    value: "PARKSON",
    id: "PARKSON",
    img: off6,
  },
  {
    title: "SAMMISHOP",
    value: "SAMMISHOP",
    id: "SAMMISHOP",
    img: off7,
  },
  {
    title: "H",
    value: "H",
    id: "H",
    img: off8,
  },
  {
    title: "LOTTE MART",
    value: "LOTTE MART",
    id: "LOTTE MART",
    img: off9,
  },
  {
    title: "Nhà thuốc",
    value: "Nhà thuốc",
    id: "Nhà thuốc",
    img: off10,
  },
  {
    title: "Hoa",
    value: "Hoa",
    id: "Hoa",
    img: off11,
  },
];
export const input1 = [
  {
    title: "Nhóm trải nghiệm",
    value: "Nhóm trải nghiệm",
    id: "Nhóm trải nghiệm",
  },
  {
    title: "Công bố sản phẩm",
    value: "Công bố sản phẩm",
    id: "Công bố sản phẩm",
  },
  {
    title: "Bán hàng/Ký gửi",
    value: "Bán hàng/Ký gửi",
    id: "Bán hàng/Ký gửi",
  },
  {
    title: "Influencer Marketing",
    value: "Influencer Marketing",
    id: "Influencer Marketing",
  },
  {
    title: "Live Commerce",
    value: "Live Commerce",
    id: "Live Commerce",
  },
];
export const linhvuc = [
  {
    title: "Làm đẹp",
    value: "Làm đẹp",
    id: "Làm đẹp",
  },
  {
    title: "Đời sống",
    value: "Đời sống",
    id: "Đời sống",
  },
  {
    title: "Thời trang",
    value: "Thời trang",
    id: "Thời trang",
  },
  {
    title: "Đa dạng",
    value: "Đa dạng",
    id: "Đa dạng",
  },
];
export const countFollower = [
  {
    title: "10k-50k người",
    value: "10k-50k người",
    id: "10k-50k người",
  },
  {
    title: "50k-100k người",
    value: "50k-100k người",
    id: "50k-100k người",
  },
  {
    title: "100k-200k người",
    value: "100k-200k người",
    id: "100k-200k người",
  },
  {
    title: "200k-500k người",
    value: "200k-500k người",
    id: "200k-500k người",
  },
  {
    title: "500k-1M người",
    value: "500k-1M người",
    id: "500k-1M người",
  },
  {
    title: "Trên 1M người",
    value: "Trên 1M người",
    id: "Trên 1M người",
  },
];
export const nhomtuoi = [
  {
    title: "Trẻ em",
    value: "Trẻ em",
    id: "Trẻ em",
  },
  {
    title: "U10",
    value: "U10",
    id: "U10",
  },
  {
    title: "U20",
    value: "U20",
    id: "U20",
  },
  {
    title: "U30",
    value: "U30",
    id: "U30",
  },
  {
    title: "U40",
    value: "U40",
    id: "U40",
  },
  {
    title: "U50 trở lên",
    value: "U50 trở lên",
    id: "U50 trở lên",
  },
];
export const banquyen = [
  {
    title: "1 tháng",
    value: "1 tháng",
    id: "1 tháng",
  },
  {
    title: "3 tháng",
    value: "3 tháng",
    id: "3 tháng",
  },
  {
    title: "6 tháng",
    value: "6 tháng",
    id: "6 tháng",
  },
  {
    title: "Không sử dụng",
    value: "Không sử dụng",
    id: "Không sử dụng",
  },
];
export const hinhthuc = [
  {
    title: "Online",
    value: "Online",
    id: "Online",
  },
  {
    title: "Offline",
    value: "Offline",
    id: "Offline",
  },
];
export const phamvi = [
  {
    title:
      "Trải nghiệm + review nội bộ",
    value:
      "Trải nghiệm + review nội bộ",
    id: "Trải nghiệm + review nội bộ",
  },
  {
    title: "Trải nghiệm + SNS review",
    value: "Trải nghiệm + SNS review",
    id: "Trải nghiệm + SNS review",
  },
];
export const gioitinh = [
  {
    title: "Nữ",
    value: "Nữ",
    id: "Nữ",
  },
  {
    title: "Nam",
    value: "Nam",
    id: "Nam",
  },
  {
    title: "Không phân biệt",
    value: "Không phân biệt",
    id: "Không phân biệt",
  },
];

export const choiceNentang = [
  {
    title: "TIKTOK",
    value: "TIKTOK",
    id: "TIKTOK",
    img: tt,
  },
  {
    title: "INSTAGRAM",
    value: "INSTAGRAM",
    id: "INSTAGRAM",
    img: ig,
  },
  {
    title: "YOUTUBE",
    value: "YOUTUBE",
    id: "YOUTUBE",
    img: yt,
  },
  {
    title: "FACEBOOK",
    value: "FACEBOOK",
    id: "FACEBOOK",
    img: fb,
  },
];

export const phamvimongmuong = [
  {
    title:
      "Xuất hiện đơn giản (Xuất hiện khoảng 1 phút, không đề cập đến)",
    value:
      "Xuất hiện đơn giản (Xuất hiện khoảng 1 phút, không đề cập đến)",
    id: "Xuất hiện đơn giản (Xuất hiện khoảng 1 phút, không đề cập đến)",
  },
  {
    title:
      "Giới thiệu sản phẩm (giới thiệu sản phẩm khoảng 5 phút)",
    value:
      "Giới thiệu sản phẩm (giới thiệu sản phẩm khoảng 5 phút)",
    id: "Giới thiệu sản phẩm (giới thiệu sản phẩm khoảng 5 phút)",
  },
  {
    title:
      "Nội dung sử dụng tổng hợp nhiều sản phẩm (Xuất hiện với các sản phẩm khác)",
    value:
      "Nội dung sử dụng tổng hợp nhiều sản phẩm (Xuất hiện với các sản phẩm khác)",
    id: "Nội dung sử dụng tổng hợp nhiều sản phẩm (Xuất hiện với các sản phẩm khác)",
  },
  {
    title:
      "Nội dung sử dụng duy nhất độc quyền 1 sản phẩm (sản xuất nội dung dành riêng cho sản phẩm)",
    value:
      "Nội dung sử dụng duy nhất độc quyền 1 sản phẩm (sản xuất nội dung dành riêng cho sản phẩm)",
    id: "Nội dung sử dụng duy nhất độc quyền 1 sản phẩm (sản xuất nội dung dành riêng cho sản phẩm)",
  },
];
