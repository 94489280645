import React, {
  useEffect,
  useState,
  useRef,
} from "react";
import { IoReload } from "react-icons/io5";
import { CiWarning } from "react-icons/ci";
import h1 from "../images/p1.png";
import h2 from "../images/p2.png";
import Dropzone from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  delImg,
  resetImages,
  uploadImg,
} from "../features/upload/uploadSlice";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { addPublicProduct } from "../features/publicProduct/publicProductSlice";
import { pdfjs } from "react-pdf";
import { toast } from "react-toastify";
import { base_url } from "../utils/base_url";
import { config } from "../utils/axiosconfig";
import { FaAngleDown } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function PublicProduct({
  t,
}) {
  const [type, setType] = useState(
    t("ban_chinh")
  );
  const handleRemoveLastProduct = () => {
    // Kiểm tra nếu có ít nhất một sản phẩm
    if (formik.values.products.length > 0) {
      // Loại bỏ phần tử cuối cùng
      const updatedProducts = formik.values.products.slice(0, -1);
      formik.setFieldValue("products", updatedProducts);
    }
  };
  let userSchema = Yup.object().shape({
    titleProject: Yup.string().required(
      t("yc_nhap_ten_da")
    ),
    products: Yup.array()
      .of(
        Yup.object().shape({
          brand: Yup.string().required(
            t("yc_nhap_thuong_hieu")
          ),
          typeProduct:
            Yup.string().required(
              t("yc_nhap_loai_sp")
            ),
          titleProduct:
            Yup.string().required(
              t("yc_nhap_ten_sp")
            ),
          trongluong:
            Yup.string().required(
              t("yc_nhap_trong_luong")
            ),
          theloai:
            Yup.string().required(
              t("yc_nhap_the_loai")
            ),
        })
      )
      .min(1, t("yc_them_sp")),
  });
  const [fileStatus, setFileStatus] =
    useState({
      dangKyPhapNhan: false,
      giayUyQuyen: false,
      giayDangKyKinhDoanh: false,
      bangThanhPhan: false,
      xacNhanCuaLanhSuQuan: false,
      giayChungNhanBanHangSanXuat: false,
    });
  const fileInputRefs = useRef({
    dangKyPhapNhan: null,
    giayUyQuyen: null,
    giayDangKyKinhDoanh: null,
    bangThanhPhan: null,
    xacNhanCuaLanhSuQuan: null,
    giayChungNhanBanHangSanXuat: null,
  });
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // upload ảnh
  const up = async (
    acceptedFiles,
    productIndex
  ) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) =>
      formData.append("images", file)
    );

    try {
      const res = await axios.post(
        `${base_url}upload/upload`,
        formData,
        config
      );
      const updatedImages = res.data;
      formik.setFieldValue(
        `products[${productIndex}].imageProduct`,
        updatedImages
      );
      // console.log(res.data);
    } catch (error) {
      console.error(
        "Error uploading file:",
        error
      );
      toast.error(t("upload_failed"));
    }
  };

  const dispatch = useDispatch();
  const imgState = useSelector(
    (state) => state.upload.images
  );
  const auth = useSelector(
    (state) => state.auth.user
  );
  const img = [];
  imgState?.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });

  useEffect(() => {
    formik.values.imageProduct = img;
  }, [img]);
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      titleProject: "",
      products: [
        {
          brand: "",
          typeProduct: t("skincare"),
          titleProduct: "",
          trongluong: "",
          theloai: "",
          imageProduct: "",
        },
      ],
      dangKyPhapNhan: null,
      giayUyQuyen: null,
      giayDangKyKinhDoanh: null,
      bangThanhPhan: null,
      xacNhanCuaLanhSuQuan: null,
      giayChungNhanBanHangSanXuat: null,
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (auth !== null) {
        const formData = new FormData();
        for (const key in values) {
          if (
            values[key] &&
            key !== "brand" &&
            key !== "typeProduct" &&
            key !== "titleProduct" &&
            key !== "trongluong" &&
            key !== "theloai" &&
            key !== "imageProduct"
          ) {
            formData.append(
              key,
              values[key]
            );
          }
        }
        const infoProduct =
          values.products;
        formData.append(
          "infoProduct",
          JSON.stringify(infoProduct)
        );

        try {
          dispatch(
            addPublicProduct(formData)
          );
          setTimeout(() => {
            formik.resetForm();
            dispatch(resetImages());
            toast.success(t("save"));
            Object.keys(
              fileInputRefs.current
            ).forEach((key) => {
              if (
                fileInputRefs.current[
                  key
                ]
              ) {
                fileInputRefs.current[
                  key
                ].value = null;
              }
            });
            setFileStatus({
              dangKyPhapNhan: false,
              giayUyQuyen: false,
              giayDangKyKinhDoanh: false,
              bangThanhPhan: false,
              xacNhanCuaLanhSuQuan: false,
              giayChungNhanBanHangSanXuat: false,
            });
          }, 300);
        } catch (error) {
          toast.error(t("dk_tb"));
        }
      } else {
        toast.warning(t("ban_chua_dn"));
      }
    },
  });

  const handleFileChange = (
    doc,
    file
  ) => {
    formik.setFieldValue(doc, file);
    setFileStatus((prevStatus) => ({
      ...prevStatus,
      [doc]: !!file,
    }));
  };

  return (
    <>
      <section className="container py-5">
        <div className="row text-white">
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: 1 }}>
            <h1 className="py-3 display-4" style={{fontSize:"70px"}}>
              {t("xin_chao")}
            </h1>
            <p
              className="py-4 fs-5"
              style={{fontSize:"40px"}}
              dangerouslySetInnerHTML={{
                __html: t(
                  "ban_muon_dang_ky"
                ),
              }}></p>
            <div className="d-flex align-items-end" onClick={()=>navigate(`/truyxuat-public`)}>
              <button className="btn_cus d-flex align-items-center">
                <IoReload className="me-3" />
                <span className="d-block">
                  {t("truy_xuat")}
                </span>
              </button>
              <span className="ps-3">
              {t(
                  "truy_xuat_thong_tin"
                )}
              </span>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: "0" }}>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: "-60px",
              }}>
              <img
                src={h1}
                alt=""
                width="80%"
              />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}>
              <img src={h2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <hr className="text-white" />
      <section className="container py-4 text-white">
        <form
          action=""
          encType="multipart/form-data"
          onSubmit={
            formik.handleSubmit
          }>
          <div className="row py-3">
            <h1 className="pb-4" style={{fontSize:"60px"}}>
              {t("thong_tin_du_an")}
            </h1>
            <div className="col-lg-8 col-md-8 position-relative">
              <div className="d-flex flex-column align-items-center mb-3 w100">
                <div
                  className="gap-3 align-items-center justify-content-between w100"
                  style={{
                    height: "50px",
                  }}>
                  <div className="d-flex gap-3 align-items-center justify-content-between">
                    <label
                      htmlFor={t(
                        "ten_du_an"
                      )}
                      className="me-3 mb-2"
                      style={{
                        flex: 1,
                      }}>
                      {t("ten_du_an")}
                    </label>
                    <div
                      style={{
                        position:
                          "relative",
                        display:
                          "inline-block",
                        width: "40%",
                      }}>
                      <select
                        className="form-select input_custom"
                        aria-label="Default select example"
                        style={{
                          flex: 3,
                          width: "100%",
                          borderRadius:
                            "24px",
                          background:
                            "#1a1a1a",
                          color:
                            "white",
                          outline:
                            "none",
                          border:
                            "2px solid #fff",
                          appearance:
                            "none", // Hide the default dropdown arrow
                          paddingRight:
                            "2.5rem", // Add space for the icon
                        }}>
                        <option
                          selected>
                          {t("tao_moi")}
                        </option>
                        <option
                          value={t(
                            "tao_cu"
                          )}>
                          {t("tao_cu")}
                        </option>
                        <option
                          value={t(
                            "ban_nhap"
                          )}>
                          {t(
                            "ban_nhap"
                          )}
                        </option>
                      </select>
                      <div
                        style={{
                          position:
                            "absolute",
                          top: "50%",
                          right: "1rem", // Adjust the position from the right
                          transform:
                            "translateY(-50%)",
                          pointerEvents:
                            "none", // Allow clicks to pass through to the select element
                        }}>
                        <FaAngleDown />
                      </div>
                    </div>
                    <input
                      style={{
                        flex: 3,
                      }}
                      type="text"
                      placeholder={t(
                        "ten_du_an"
                      )}
                      className="input_custom"
                      value={
                        formik.values
                          .titleProject
                      }
                      onChange={formik.handleChange(
                        "titleProject"
                      )}
                      onBlur={formik.handleBlur(
                        "titleProject"
                      )}
                    />
                  </div>
                </div>
                <div
                  className="error mb-3 d-flex justify-content-end"
                  style={{
                    top: "100%",
                  }}>
                  {formik.touched
                    .titleProject &&
                  formik.errors
                    .titleProject ? (
                    <div>
                      {
                        formik.errors
                          .titleProject
                      }
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4"></div>
          </div>
          <div className="py-5">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h1 className="mb-3" style={{fontSize:"60px"}}>
                {t("nhap_thong_tin_sp")}
              </h1>
              <div className="">
                <div className="boxed">
                  <button
                    className="btn_add"
                    onClick={() => {
                      const newProduct = {
                        brand: "",
                        typeProduct:
                          t("skincare"),
                        titleProduct: "",
                        trongluong: "",
                        theloai: "",
                        imageProduct: "",
                      };
                      formik.setFieldValue(
                        "products",
                        [
                          ...formik.values
                            .products,
                          newProduct,
                        ]
                      );
                    }}>
                    {t("them_san_pham")}
                  </button>
                </div>
                <button
                    className="btn btn-primary mt-3"
                    style={{width:"178px",borderRadius:"24px"}}
                    onClick={handleRemoveLastProduct}
                  >
                    {t("xoa_san_pham")}
                  </button>
              </div>
            </div>
          </div>
          {formik.values.products?.map(
            (product, index) => (
              <div key={index}>
                <h3 className="pb-4 mb-5" style={{fontSize:"40px"}}>
                  {t("thong_tin_sp")}{" "}
                  {index + 1}
                </h3>
                <div className="row pb-4 ">
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].brand`}>
                      {t(
                        "ten_thuong_hieu"
                      )}
                    </label>
                    <div className="w70">
                      <input
                        style={{
                          width: "100%",
                        }}
                        type="text"
                        placeholder={t(
                          "ten_thuong_hieu"
                        )}
                        className="input_custom"
                        value={
                          product.brand
                        }
                        onChange={formik.handleChange(
                          `products[${index}].brand`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].brand`
                        )}
                        name={`products[${index}].brand`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.brand &&
                      formik.errors
                        .products?.[
                        index
                      ]?.brand ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].brand
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].typeProduct`}>
                      {t("loai_sp")}
                    </label>
                    <div
                      className="w70"
                      style={{
                        position:
                          "relative",
                      }}>
                      <select
                        className="form-select input_custom"
                        aria-label="Default select example"
                        defaultValue={
                          "Skincare"
                        }
                        style={{
                          width: "100%",
                          borderRadius:
                            "24px",
                          background:
                            "#1a1a1a",
                          color:
                            "white",
                          outline:
                            "none",
                          border:
                            "2px solid #fff",
                        }}
                        value={
                          product.typeProduct
                        }
                        onChange={
                          formik.handleChange
                        }
                        onBlur={
                          formik.handleBlur
                        }
                        name={`products[${index}].typeProduct`}>
                        <option
                          value={t(
                            "skincare"
                          )}>
                          {t(
                            "skincare"
                          )}
                        </option>
                        <option
                          value={t(
                            "doan"
                          )}>
                          {t("doan")}
                        </option>
                        <option
                          value={t(
                            "dogiadung"
                          )}>
                          {t(
                            "dogiadung"
                          )}
                        </option>
                        <option
                          value={t(
                            "sach"
                          )}>
                          {t("sach")}
                        </option>
                      </select>
                      <div
                        style={{
                          position:
                            "absolute",
                          top: "50%",
                          right: "1rem", // Adjust the position from the right
                          transform:
                            "translateY(-50%)",
                          pointerEvents:
                            "none", // Allow clicks to pass through to the select element
                        }}>
                        <FaAngleDown />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].titleProduct`}>
                      {t("ten_sp")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        style={{
                          width: "100%",
                        }}
                        placeholder={t(
                          "ten_sp"
                        )}
                        className="input_custom"
                        value={
                          product.titleProduct
                        }
                        onChange={formik.handleChange(
                          `products[${index}].titleProduct`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].titleProduct`
                        )}
                        name={`products[${index}].titleProduct`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.titleProduct &&
                      formik.errors
                        .products?.[
                        index
                      ]
                        ?.titleProduct ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ]
                              .titleProduct
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].trongluong`}>
                      {t("trong_luong")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        style={{
                          width: "100%",
                        }}
                        placeholder={t(
                          "trong_luong"
                        )}
                        className="input_custom"
                        value={
                          product.trongluong
                        }
                        onChange={formik.handleChange(
                          `products[${index}].trongluong`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].trongluong`
                        )}
                        name={`products[${index}].trongluong`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.trongluong &&
                      formik.errors
                        .products?.[
                        index
                      ]?.trongluong ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].trongluong
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].theloai`}>
                      {t("the_loai")}
                    </label>
                    <div className="w70">
                      <input
                        style={{
                          width: "100%",
                        }}
                        type="text"
                        placeholder={t(
                          "theloai_"
                        )}
                        className="input_custom"
                        value={
                          product.theloai
                        }
                        onChange={
                          formik.handleChange
                        }
                        onBlur={
                          formik.handleBlur
                        }
                        name={`products[${index}].theloai`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.theloai &&
                      formik.errors
                        .products?.[
                        index
                      ]?.theloai ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].theloai
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].imageProduct`}>
                      {t("them_ha")}
                    </label>
                    <div
                      className="w70 d-flex justify-content-between"
                      style={{
                        height: "100%",
                      }}>
                      <div
                        className="img_post w70 d-flex align-items-center justify-content-between text-center"
                        style={{
                          height:
                            "100%",
                        }}>
                        <section className="image-section">
                        {product.imageProduct &&
                          product.imageProduct.map((i, j) => {
                            const fileName = i.url.split('/').pop().split('?')[0];
                            return (
                              <div className="position-relative" key={j}>
                                <button
                                  type="button"
                                  onClick={() => dispatch(delImg(i.public_id))}
                                  className="btn-close position-absolute"
                                  style={{
                                    top: "10px",
                                    right: "10px",
                                  }}
                                ></button>
                                <p className="file-name mb-0">{fileName}</p>
                              </div>
                            );
                          })}
                      </section>
                      </div>
                      <Dropzone
                        onDrop={(
                          acceptedFiles
                        ) => {
                          if (
                            auth !==
                            null
                          ) {
                            up(
                              acceptedFiles,
                              index
                            );
                          } else {
                            toast.warning(
                              t(
                                "ban_chua_dn"
                              )
                            );
                          }
                        }}>
                        {({
                          getRootProps,
                          getInputProps,
                        }) => (
                          <div className="">
                            <div
                              {...getRootProps()}
                              className="upload-button">
                              <input
                                {...getInputProps()}
                              />
                              <button
                                type="button"
                                className="btn_upload">
                                {t(
                                  "tai_len"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
          <hr className="text-white" />
          <div className="document-upload-container text-white">
            <h1 style={{fontSize:"60px"}}>
              {t("tai_lieu_can_thiet")}
            </h1>
            <span>
              {t("vui_long_tai_len")}
            </span>
            <div className="row mt-5 document-row">
              <div className="col-3 document-col">
                <p className="document-title" style={{fontSize:"24px"}}>
                  {t("ten_tai_lieu")}
                </p>
              </div>
              <div className="col-3 document-col">
                <p className="document-title" style={{fontSize:"24px"}}>
                  {t("trang_thai")}
                </p>
              </div>
              <div className="col-6 document-col">
                <p className="document-title" style={{fontSize:"24px"}}>
                  {t("dang_tai")}
                </p>
              </div>
            </div>
            <hr className="bg-white mb-4 mt-0 p-0" />
            {/* thông tin */}
            <div className="row">
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {t("phap_nhan")}
                </p>
              </div>
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {fileStatus[
                    "dangKyPhapNhan"
                  ]
                    ? t("da_nhan")
                    : t("chua_nhan")}
                </p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    ref={(el) =>
                      (fileInputRefs.current[
                        "dangKyPhapNhan"
                      ] = el)
                    }
                    type="file"
                    placeholder="upload"
                    className="file-input"
                    accept=".doc,.docx,.xls,.xlsx,.pdf"
                    onChange={(e) =>
                      handleFileChange(
                        "dangKyPhapNhan",
                        e.currentTarget
                          .files[0]
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {t("giay_uy_quyen")}
                </p>
              </div>
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {fileStatus[
                    "giayUyQuyen"
                  ]
                    ? t("da_nhan")
                    : t("chua_nhan")}
                </p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    ref={(el) =>
                      (fileInputRefs.current[
                        "giayUyQuyen"
                      ] = el)
                    }
                    type="file"
                    placeholder="upload"
                    className="file-input"
                    accept=".doc,.docx,.xls,.xlsx,.pdf"
                    onChange={(e) =>
                      handleFileChange(
                        "giayUyQuyen",
                        e.currentTarget
                          .files[0]
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {t("giay_kinh_doanh")}
                </p>
              </div>
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {fileStatus[
                    "giayDangKyKinhDoanh"
                  ]
                    ? t("da_nhan")
                    : t("chua_nhan")}
                </p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    ref={(el) =>
                      (fileInputRefs.current[
                        "giayDangKyKinhDoanh"
                      ] = el)
                    }
                    type="file"
                    placeholder="upload"
                    className="file-input"
                    accept=".doc,.docx,.xls,.xlsx,.pdf"
                    onChange={(e) =>
                      handleFileChange(
                        "giayDangKyKinhDoanh",
                        e.currentTarget
                          .files[0]
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {t("bang_thanh_phan")}
                </p>
              </div>
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {fileStatus[
                    "bangThanhPhan"
                  ]
                    ? t("da_nhan")
                    : t("chua_nhan")}
                </p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    ref={(el) =>
                      (fileInputRefs.current[
                        "bangThanhPhan"
                      ] = el)
                    }
                    type="file"
                    placeholder="upload"
                    className="file-input"
                    accept=".doc,.docx,.xls,.xlsx,.pdf"
                    onChange={(e) =>
                      handleFileChange(
                        "bangThanhPhan",
                        e.currentTarget
                          .files[0]
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {t("xac_nhan_lsq")}
                </p>
              </div>
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {fileStatus[
                    "xacNhanCuaLanhSuQuan"
                  ]
                    ? t("da_nhan")
                    : t("chua_nhan")}
                </p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    ref={(el) =>
                      (fileInputRefs.current[
                        "xacNhanCuaLanhSuQuan"
                      ] = el)
                    }
                    type="file"
                    placeholder="upload"
                    className="file-input"
                    accept=".doc,.docx,.xls,.xlsx,.pdf"
                    onChange={(e) =>
                      handleFileChange(
                        "xacNhanCuaLanhSuQuan",
                        e.currentTarget
                          .files[0]
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {t("giay_chung_nhan")}
                </p>
              </div>
              <div className="col-3">
                <p className="mb-4" style={{fontSize:"24px"}}>
                  {fileStatus[
                    "giayChungNhanBanHangSanXuat"
                  ]
                    ? t("da_nhan")
                    : t("chua_nhan")}
                </p>
              </div>
              <div className="col-6">
                <div className="d-flex mb-2">
                  <input
                    ref={(el) =>
                      (fileInputRefs.current[
                        "giayChungNhanBanHangSanXuat"
                      ] = el)
                    }
                    type="file"
                    placeholder="upload"
                    className="file-input"
                    accept=".doc,.docx,.xls,.xlsx,.pdf"
                    onChange={(e) =>
                      handleFileChange(
                        "giayChungNhanBanHangSanXuat",
                        e.currentTarget
                          .files[0]
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
              {
                formik.values.titleProject ===""
                ? (
                <p className="warning-box mb-0">
                  <div className="d-flex align-items-center">
                    <CiWarning
                      style={{
                        color:
                          "#FF0099",
                      }}
                      className="me-3"
                    />
                    <span className="d-block">
                      {t("chon_mot_so")}
                    </span>
                  </div>
                </p>
                ):""
              }
            <div className="d-flex gap-3 mt-3">
              <button
                className="btn_submit"
                onClick={() => {
                  setType("Bản chính");
                }}>
                {t("gui_don")}
              </button>
              <button
                className="btn_note"
                onClick={() => {
                  setType("Bản nháp");
                }}>
                {t("luu_nhap")}
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
