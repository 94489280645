import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import RegisterExperien from "./pages/RegisterExperien";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Login";
import ForgotId from "./pages/ForgotId";
import ForgotIdNext from "./pages/ForgotIdNext";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Pricing from "./pages/Pricing";
import CreatePricing from "./pages/CreatePricing";
import Marketing from "./pages/Marketing";
import PublicProduct from "./pages/PublicProduct";
import LiveCoummerce from "./pages/LiveCoummerce";
import RegisterSale from "./pages/RegisterSale";
import MyPage from "./pages/MyPage";
import ManagerStore from "./pages/ManagerStore";
import ManagerLive from "./pages/ManagerLive";
import ManagerMaketing from "./pages/ManagerMaketing";
import ManagerPublicProduct from "./pages/ManagerPublicProduct";
import ManagerRegister from "./pages/ManagerRegister";
import ManagerBuyer from "./pages/ManagerBuyer";
import ManagerAddProduct from "./pages/ManagerAddProduct";
import { AdminRouters } from "./routing/AdminRoutes";
import AdminRegisterExpirence from "./pages/AdminRegisterExpirence";
import AdminRegisterMaketing from "./pages/AdminRegisterMaketing";
import AdminRegisterPublicProduct from "./pages/AdminRegisterPublicProduct";
import AdminRegisterSale from "./pages/AdminRegisterSale";
import AdminRegisterLive from "./pages/AdminRegisterLive";
import { useTranslation } from "react-i18next";
import axios from "axios";
import StoreManager from "./pages/StoreManager";
import { LoginRouters } from "./routing/LoginRoutes";
import ManagerBaogia from "./pages/ManagerBaogia";
import MyInfoPage from "./pages/MyInfoPage";
import TruyXuatRegister from "./pages/TruyXuatRegister";
import TruyXuatRegisterSale from "./pages/TruyXuatRegisterSale";
import TruyXuatRegisterPublic from "./pages/TruyXuatRegisterPublic";
import TruyXuatRegisterMaketing from "./pages/TruyXuatRegisterMaketing";
import TruyXuatRegisterLive from "./pages/TruyXuatRegisterLive";
function App() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Layout t={t} changeLanguage={changeLanguage} />}
        >
          <Route index element={<HomePage t={t} />} />
          <Route path="experience" element={<LoginRouters><RegisterExperien t={t}/></LoginRouters> } />
          <Route path="manager-update-new" element={<LoginRouters><MyInfoPage t={t}/></LoginRouters> } />
          <Route path="manager-update-new/:id" element={<LoginRouters><MyInfoPage t={t}/></LoginRouters> } />
          <Route path="my-page" element={<LoginRouters><MyPage t={t} /></LoginRouters>} />
          <Route path="manager-store" element={<ManagerStore t={t} />} />
          <Route path="manager-live/:id" element={<ManagerLive t={t} />} />
          <Route path="manager-bao-gia/:id" element={<ManagerBaogia t={t} />} />
          <Route path="manager-maketing/:id" element={<ManagerMaketing t={t} />} />
          <Route
            path="manager-public-product/:id"
            element={<ManagerPublicProduct t={t} />}
          />
          <Route path="manager-stores" element={<LoginRouters><StoreManager t={t} /></LoginRouters>} />

          <Route path="manager-register/:id" element={<LoginRouters><ManagerRegister t={t} /></LoginRouters>} />
          <Route path="manager-buyer" element={<ManagerBuyer t={t} />} />
          <Route
            path="manager-addproduct"
            element={<ManagerAddProduct t={t} />}
          />
          <Route path="declare" element={<LoginRouters> <PublicProduct t={t} /></LoginRouters>} />
          <Route path="truyxuat-public" element={<LoginRouters> <TruyXuatRegisterPublic t={t} /></LoginRouters>} />
          <Route path="sale" element={<LoginRouters> <RegisterSale t={t} /></LoginRouters>} />
          <Route path="truyxuat-sale" element={<LoginRouters> <TruyXuatRegisterSale t={t} /></LoginRouters>} />
          <Route path="influencer-marketing" element={<LoginRouters> <Marketing t={t} /> </LoginRouters> } />
          <Route path="truyxuat-maketing" element={<LoginRouters> <TruyXuatRegisterMaketing t={t} /> </LoginRouters> } />
          <Route path="live-commerce" element={<LoginRouters><LiveCoummerce t={t} /></LoginRouters>} />
          <Route path="truyxuat-live" element={<LoginRouters><TruyXuatRegisterLive t={t} /></LoginRouters>} />
          <Route path="create-pricing" element={<LoginRouters><CreatePricing t={t} /></LoginRouters>} />
          <Route path="pricing" element={<Pricing t={t} />} />
          <Route path="login" element={<Login t={t} />} />
          <Route path="register" element={<Register t={t} />} />
          <Route path="truyxuat-register" element={<TruyXuatRegister t={t} />} />
          <Route path="forgot-pass" element={<ForgotPassword t={t} />} />
          <Route path="reset-password" element={<ResetPassword t={t} />} />
          <Route path="forgot-id" element={<ForgotId t={t} />} />
          <Route path="forgot-id-next" element={<ForgotIdNext t={t} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path="/admin"
          element={
            <AdminRouters>
              <Layout />
            </AdminRouters>
          }
        >
          <Route
            path="manager-register-expirience"
            element={<AdminRegisterExpirence t={t} />}
          />
          <Route
            path="manager-maketing"
            element={<AdminRegisterMaketing t={t} />}
          />
          <Route
            path="manager-public-product"
            element={<AdminRegisterPublicProduct t={t} />}
          />
          <Route path="manager-sale" element={<AdminRegisterSale t={t} />} />
          <Route path="manager-live" element={<AdminRegisterLive t={t} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
