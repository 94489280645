import React, {
  useEffect,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotId } from "../features/users/userSlice";
import { useFormik } from "formik";
import live from "../images/live.jpg";
import tiktok from "../images/tiktok.png";
import ins from "../images/ins.png";
import * as Yup from "yup";
export default function ForgotId({
  t,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userSchema = Yup.object().shape({
    name: Yup.string().required(
      t("yc_ten")
    ),
    email: Yup.string()
      .email(t("email_khong_hop"))
      .required(t('yc_nhap_email')),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      dispatch(forgotId(values));
      navigate("/forgot-id-next");
    },
  });
  // useEffect(() => {
  //   // Disable vertical scroll on mount
  //   document.body.style.overflowY = 'hidden';

  //   // Re-enable scroll on unmount
  //   return () => {
  //     document.body.style.overflowY = 'auto';
  //   };
  // }, []);
  return (
    <>
      <div>
        <div
          className="container py-5 mt-5"
          style={{
            position: "relative",
            zIndex: 10000,
          }}>
          <div
            className="text-white py-5 mx-auto"
            style={{ width: "500px" }}>
            <div className="text-center">
              <h1>{t("quenID")}</h1>
              <h3>{t("ban_quenID")}</h3>
            </div>
            <form
              onSubmit={
                formik.handleSubmit
              }>
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label">
                  {t("ten")}
                </label>
                <input
                  type="text"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder={t("ten")}
                  value={
                    formik.values.name
                  }
                  onChange={formik.handleChange(
                    "name"
                  )}
                  onBlur={formik.handleBlur(
                    "name"
                  )}
                />
                <div className="error mb-3">
                  {formik.touched
                    .name &&
                  formik.errors.name ? (
                    <div>
                      {
                        formik.errors
                          .name
                      }
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label">
                  {t("mail")}
                </label>
                <input
                  type="email"
                  className="form-control input_custom"
                  id="exampleInputPassword1"
                  placeholder={t(
                    "mail"
                  )}
                  value={
                    formik.values.email
                  }
                  onChange={formik.handleChange(
                    "email"
                  )}
                  onBlur={formik.handleBlur(
                    "email"
                  )}
                />
                <div className="error mb-3">
                  {formik.touched
                    .email &&
                  formik.errors
                    .email ? (
                    <div>
                      {
                        formik.errors
                          .email
                      }
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="text-end">
                <p
                  style={{
                    fontSize: "12px",
                  }}>
                  {t("vui_long_chu_y")}
                </p>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn_submit">
                  {t("tieptheo")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: "30px",
          zIndex: 9999,
        }}>
        <div>
          <img src={ins} alt="" />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "-240px",
            right: "20px",
          }}>
          <img src={tiktok} alt="" />
        </div>
      </div>
    </>
  );
}
