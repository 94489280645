import React, { useEffect } from 'react'
import { IoReload } from 'react-icons/io5'
import h1 from "../images/h1.png";
import h2 from "../images/h2.png";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExprience } from '../features/registerExprience/resExpriSlice';
export default function TruyXuatRegister({t}) {
  const dispatch = useDispatch();
    useEffect(() => {
        window.scroll(0, 0);
        dispatch(getAllExprience())
      }, []);
    const user = useSelector(state=>state.auth.user)
    const experian = useSelector(state=>state.registerPri.resExpri?.getAll) 
    const getAll = experian?.filter(e=>e?.type ==="Bản nháp" && e?.registerBy?._id === user?._id)
    return (
    <div>
       <section className="container py-5">
        <div className="row text-white">
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: 1 }}>
            <h1 className="py-3 display-4" style={{fontSize:"70px"}}>
              {t("xin_chao")}
            </h1>
            <p
              className="py-4 fs-5"
              style={{fontSize:"40px"}}
              dangerouslySetInnerHTML={{
                __html: t(
                  "ban_muon_dang_ky"
                ),
              }}></p>
            <div className="d-flex align-items-end">
              <button className="btn_cus d-flex align-items-center">
                <IoReload className="me-3" />
                <span className="d-block">
                  {t("truy_xuat")}
                </span>
              </button>
              <span className="ps-3">
                {t(
                  "truy_xuat_thong_tin"
                )}
              </span>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: "0" }}>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: "47px",
              }}>
              <img
                src={h1}
                alt=""
                width="80%"
              />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: "40px",
              }}>
              <img src={h2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <hr className="text-white"/>
      <div className="container">
        <div className="accordion" id="accordionExample">
            {getAll && (
            <div className="accordion-item">
                <h2 className="accordion-header">
                <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseIdEx"
                    aria-expanded="true"
                    aria-controls="collapseIdEx"
                >
                    {t("ten_du_an")}
                </button>
                </h2>
                <div
                id="collapseIdEx"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
                >
                <div className="accordion-body">
                    <div className="row">
                    <div className="col-4">
                        <strong className="d-block pb-3">{t("ten_du_an")}</strong>
                    </div>
                    <div className="col-4">
                        <strong className="d-block pb-3">
                        {t("thoi_gian_mong_muon")}
                        </strong>
                    </div>
                    <div className="col-4">
                        <strong className="d-block pb-3">{t("pham_vi")}</strong>
                    </div>
                    </div>
                    {getAll.map((item, index) => (
                    <div key={index}>
                        <div className="row">
                        <div className="col-4">
                            <p>{item?.titleProject}</p>
                        </div>
                        <div className="col-4">
                            <p>
                            {item.schedule?.startDate && item.schedule?.endDate
                                ? `${moment(item.schedule.startDate).format('DD/MM/YYYY')} ~ ${moment(item.schedule.endDate).format('DD/MM/YYYY')}`
                                : 'N/A'}
                            </p>
                        </div>
                        <div className="col-4 d-flex gap-30">
                            <p>{item?.phamvi}</p>
                        </div>
                        </div>
                    </div>
                    ))}
                </div>
                </div>
            </div>
            )}
        </div>
      </div>
    </div>
  )
}
