import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { register } from "../features/users/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import live from "../images/live.jpg";
import tiktok from "../images/tiktok.png";
import ins from "../images/ins.png";
export default function Register({ t }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cfPass, setCfPass] = useState("");
  let userSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("email_khong_hop"))
      .required(t("yc_nhap_email")), //required là hiển thị dòng lỗi phía dưới của input khi dữ liệu trống
    name: Yup.string().required(t("yc_ten")),
    password: Yup.string().required(t("yc_mk")),
    idName: Yup.string().required(t("yc_id")),
    mobile: Yup.string().required(t("yc_nhap_sdt")),
  });
  const userState = useSelector((state) => state.auth.user);
  const registerState = useSelector((state) => state.auth.regis);

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      idName: "",
      mobile: "",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (values.password === cfPass) {
        dispatch(register(values));
        if (registerState !== null) {
          navigate("/login");
        }
      } else {
        toast.warning(t("mk_khong_khop"));
      }
    },
  });
  return (
    <>
      <div
        className="container text-white py-5"
  
      >
        <div className="pb-5">
          <h1>{t("chao_mung_ban")}</h1>
          <p className="mb-3" style={{ fontSize: "18px" }}>
            {t("cung_viety_xk_vn")}
          </p>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-4">
              <div className="mb-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  ID
                </label>
                <input
                  type="text"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={formik.values.idName}
                  onChange={formik.handleChange("idName")}
                  onBlur={formik.handleBlur("idName")}
                  placeholder="ID"
                />
                <div className="error">
                  {formik.touched.idName && formik.errors.idName ? (
                    <div>{formik.errors.idName}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  {t("ten")}
                </label>
                <input
                  type="text"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder={t("ten")}
                  value={formik.values.name}
                  onChange={formik.handleChange("name")}
                  onBlur={formik.handleBlur("name")}
                />
                <div className="error mb-3">
                  {formik.touched.name && formik.errors.name ? (
                    <div>{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  {t("sdt")}
                </label>
                <input
                  type="number"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder={t("sdt")}
                  value={formik.values.mobile}
                  onChange={formik.handleChange("mobile")}
                  onBlur={formik.handleBlur("mobile")}
                />
                <div className="error mb-3">
                  {formik.touched.mobile && formik.errors.mobile ? (
                    <div>{formik.errors.mobile}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="mb-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Password*
                </label>
                <input
                  type="password"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  onBlur={formik.handleBlur("password")}
                />
                <div className="error mb-3">
                  {formik.touched.password && formik.errors.password ? (
                    <div>{formik.errors.password}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  {t("mail")}
                </label>
                <input
                  type="email"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="example@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur("email")}
                />
                <div className="error mb-3">
                  {formik.touched.email && formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="mb-4">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  {t("nhap_lai")} Password*
                </label>
                <input
                  type="password"
                  value={cfPass}
                  onChange={(e) => setCfPass(e.target.value)}
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start mt-3">
            <button type="submit" className="btn_submit">
              {t("dk_tv")}
            </button>
          </div>
        </form>
      </div>
     
    </>
  );
}
