import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

const getNamePrice = async (data) => {
  console.log("Request data:", data);
  const res = await axios.get(
    `${base_url}company/get-name-company/${data?.idName}/${data?.email}`,config
    
  );
  return res.data;
};
const getIdCompany = async (id) => {
  const res = await axios.get(
    `${base_url}company/get-id-company/${id}`,config
  );
  return res.data;
};
const updateCompany = async (data) => {
  const res = await axios.put(
    `${base_url}company/update-company/${data.id}`,data.data,config
  );
  return res.data;
};
const uploadService = {
  getNamePrice,getIdCompany,updateCompany
};

export default uploadService;
