import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

// tham so la img
const addLive = async (data) => {
  const res = await axios.post(
    `${base_url}live/addregister-live`,
    data,
    config
  );
  return res.data;
};
const getAllLive = async () => {
  const res = await axios.get(
    `${base_url}live/get-all-live`,
    config
  );
  return res.data;
};
const getIdLive = async (id) => {
  const res = await axios.get(
    `${base_url}live/get-id-live/${id}`,
    config
  );
  return res.data;
};
const liveService = {
  addLive,getAllLive,getIdLive
};

export default liveService;
