import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getIdExprience } from "../features/registerExprience/resExpriSlice";
import moment from "moment";

export default function ManagerRegister({ t }) {
  const {id} = useParams()
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getIdExprience(id))
    window.scroll(0,0)
  },[])
  const idEx = useSelector(state=>state.registerPri.idEx)
  return (
    <div className="" style={{background:"#333333"}}>
      <section className="container py-5 text-white">
        {/* tên dự án */}
        <div className="py-4">
          <h6 className="mb-3">{t("dang_ky_trai_nghiem")}</h6>
          <h1 className="mb-3" style={{fontSize:"50px"}}>{idEx?.getId?.titleProject}</h1>
          <h6 className="mb-5">
            {t("thoigian")} : {idEx?.getId?.schedule?.startDate && idEx?.getId?.schedule?.endDate
                              ? `${moment(idEx?.getId.schedule.startDate).format('DD/MM/YYYY')} ~ ${moment(idEx?.getId.schedule.endDate).format('DD/MM/YYYY')}`
                            : 'N/A'}
          </h6>
        </div>
        <hr className="bg-white mb-4 mt-0 p-0" />

        {/* thông tin */}
        {
          idEx?.getId?.infoProduct?.map((e,index)=>(
          <div key={index}>
            <h3 className="pb-4">{t("thong_tin_sp1")}</h3>
            <div className="row pb-4">
              <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                <label className="me-3" htmlFor={t("ten_thuong_hieu")}>
                  {t("ten_thuong_hieu")}
                </label>
                <input
                  disabled
                  value={e.brand}
                  type="text"
                  placeholder={t("ten_thuong_hieu")}
                  className="input_custom disable"
                />
              </div>
              <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                <label className="me-3" htmlFor={t("loai_sp")}>
                  {t("loai_sp")}
                </label>
                <select
                  disabled
                  defaultValue={e.typeProduct}
                  className="form-select input_custom disable"
                  aria-label="Default select example"
                  style={{
                    width: "200px",
                    borderRadius: "24px",
                    background: "#1a1a1a",
                    color: "white",
                    outline: "none",
                    border: "2px solid #fff",
                  }}
                >
                  <option value={t("skincare")}>{t("skincare")}</option>
                  <option value={t("doan")}>{t("doan")}</option>
                  <option value={t("dogiadung")}>{t("dogiadung")}</option>
                  <option value={t("sach")}>{t("sach")}</option>
                </select>
              </div>
              <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                <label className="me-3" htmlFor={t("ten_sp")}>
                  {t("ten_sp")}
                </label>
                <input
                  type="text"
                  disabled
                  value={e.titleProduct}
                  placeholder={t("ten_sp")}
                  className="input_custom disable "
                />
              </div>
            </div>
            <div className="row pb-4">
              <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                <label className="me-3" htmlFor={t("trong_luong")}>
                  {t("trong_luong")}
                </label>
                <input
                  type="number"
                  value={e.trongluong}
                  disabled
                  placeholder={t("trong_luong")}
                  className="input_custom disable "
                />
              </div>
              <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                <label className="me-3" htmlFor={t("the_loai")}>
                  {t("the_loai")}
                </label>
                <input
                  type="text"
                  disabled
                  value={e.theloai}
                  placeholder={t("the_loai")}
                  className="input_custom disable "
                />
              </div>
              <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                <label className="me-3" htmlFor={t("them_ha")}>
                  {t("them_ha")}
                </label>
                <div className="text-end d-flex justify-content-end">
                <img
                    src={
                      e?.imageProduct[0]?.url
                    }
                    width="201px"
                    height="47px"
                    alt=""
                    className="disable"
                  />
                </div>
              </div>
            </div>
          </div>
          ))
        }
        <hr className="text-white my-5" />
        <div className="py-4">
          <div className="py-3">
            <h3>{t("kq_du_an")}</h3>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="d-flex gap-30">
                <p>{t("loai_da")}</p>
                <p>{t("dang_ky_trai_nghiem")}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex gap-30">
                <p>{t("nhom_tuoi")}</p>
                <p>{idEx?.getId?.age}</p>
              </div>
            </div>
            <div className="col-4"></div>
          </div>

          <div className="row">
            <div className="col-4">
              <div className="d-flex gap-30">
                <p>{t("nen_tang")}</p>
                <p>Tiktok</p>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex gap-30">
                <p>{t("gioitinh")}</p>
                <p>{idEx?.getId?.sex}</p>
              </div>
            </div>
            <div className="col-4"></div>
          </div>

          <div className="row">
            <div className="col-4">
              <div className="d-flex gap-30">
                <p>{t("so_nguoi_xem")}</p>
                <p>10.000 </p>
              </div>
            </div>
            <div className="col-4"></div>
            <div className="col-4"></div>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <p className="mb-0 me-4">{t("kq_dg")}</p>
            <div className="vode ">
              <p className="mb-0">{t("kq_dg")}</p>
              <button className="btn_chek">{t("check")}</button>
            </div>
          </div>
          <div className="d-flex">
            <div className="vode">
              <p className="mb-0">{t("link_kq")}</p>
              <button className="btn_chek">{t("check")}</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
