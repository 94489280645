import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIdLive } from "../features/live/liveSlice";
import moment from "moment";
import { useParams } from "react-router-dom";
export default function ManagerLive({ t }) {
  const {id} = useParams()
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getIdLive(id))
    window.scroll(0,0)
  },[])
  const idLive = useSelector(state=>state.live.idLive)
  return (
    <section className="container py-5 text-white">

          <div >
          <div  className="py-4">
            <h6 className="mb-3">{t("live_commerce")}</h6>
            <h1 className="mb-3">{idLive?.getId?.titleProject}</h1>
            <h6 className="mb-5">
            {t("thoigian")} : {idLive?.getId?.schedule?.startDate && idLive?.getId?.schedule?.endDate
                            ? `${moment(idLive?.getId.schedule.startDate).format('DD/MM/YYYY')} ~ ${moment(idLive?.getId.schedule.endDate).format('DD/MM/YYYY')}`
                          : 'N/A'}
            </h6>
          </div>
            {
              idLive&&idLive?.getId?.infoProduct?.map((e,index)=>(
                <div key={index}>
                  <h3 className="pb-4">{t("thong_tin_sp1")}</h3>
                  <div className="row pb-4">
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                      <label className="me-3" htmlFor={t("ten_thuong_hieu")}>
                        {t("ten_thuong_hieu")}
                      </label>
                      <input
                        type="text"
                        placeholder={t("ten_thuong_hieu")}
                        value={e?.titleProduct}
                        className="input_custom disable"
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                      <label className="me-3" htmlFor={t("loai_sp")}>
                        {t("loai_sp")}
                      </label>
                      <select
                        className="form-select input_custom disable"
                        aria-label="Default select example"
                        value={e?.typeProduct}
                        style={{
                          width: "200px",
                          borderRadius: "24px",
                          background: "#1a1a1a",
                          color: "white",
                          outline: "none",
                          border: "2px solid #fff",
                        }}
                      >
                        <option value={t("skincare")}>{t("skincare")}</option>
                        <option value={t("doan")}>{t("doan")}</option>
                        <option value={t("dogiadung")}>{t("dogiadung")}</option>
                        <option value={t("sach")}>{t("sach")}</option>
                      </select>
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                      <label className="me-3" htmlFor={t("ten_sp")}>
                        {t("ten_sp")}
                      </label>
                      <input
                        type="text"
                        value={e?.titleProduct}
                        placeholder={t("ten_sp")}
                        className="input_custom disable"
                      />
                    </div>
                  </div>
                  <div className="row pb-4">
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                      <label className="me-3" htmlFor={t("trong_luong")}>
                        {t("trong_luong")}
                      </label>
                      <input
                        type="number"
                        value={e?.trongluong}
                        placeholder={t("trong_luong")}
                        className="input_custom disable"
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                      <label className="me-3" htmlFor={t("the_loai")}>
                        {t("the_loai")}
                      </label>
                      <input
                        type="text"
                        value={e?.theloai}
                        placeholder={t("the_loai")}
                        className="input_custom disable"
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
                      <label className="me-3" htmlFor={t("them_ha")}>
                        {t("them_ha")}
                      </label>
                      <div className="text-end d-flex justify-content-end">
                      <img
                        src={
                          e?.imageProduct[0]?.url
                        }
                        width="201px"
                        height="47px"
                        alt=""
                        className="disable"
                      />
                      </div>
                    </div>
                  </div>
                  <div className="row pb-4">
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-2">
                      <label className="me-3" htmlFor={t("gia_cai")}>
                        {t("gia_cai")}
                      </label>
                      <input
                        type="number"
                        value={e?.price}
                        placeholder={t("gia_cai")}
                        className="input_custom disable"
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-2">
                      <label className="me-3" htmlFor={t("hsd")}>
                        {t("hsd")}
                      </label>
                      <input
                        type="date"
                        value={e?.HSD}
                        placeholder="example@example.com"
                        className="input_custom disable"
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-2">
                      <label className="me-3" htmlFor={t("hinh_nen_trang")}>
                        {t("hinh_nen_trang")}
                      </label>
                      <div className="text-end d-flex justify-content-end">
                        <img
                          src={
                            e?.imageBg[0]?.url
                          }
                          width="201px"
                          height="47px"
                          alt=""
                          className="disable"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pb-4">
                    <div className="col-md-4 d-flex align-items-center justify-content-between mb-2">
                      <label className="me-3" htmlFor={t("so_to")}>
                        {t("so_to")}
                      </label>
                      <input
                        type="text"
                        value={e?.countNK}
                        placeholder={t("vl_nhapso")}
                        className="input_custom disable"
                      />
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
              ))
            }
          <div className="py-4">
            <div className="py-3">
              <h3>{t("kq_du_an")}</h3>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("loai_da")}</p>
                  <p>{t("live_commerce")}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("nhom_tuoi")}</p>
                  <p>{idLive?.getId?.age}</p>
                </div>
              </div>
              <div className="col-4"></div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("nen_tang")}</p>
                  {idLive?.getId?.nentang?.map((e,index)=>(
                    <p key={index}>{e}</p>
                  ))}
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("gioitinh")}</p>
                  <p>{idLive?.getId?.sex}</p>
                </div>
              </div>
              <div className="col-4"></div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("so_nguoi_xem")}</p>
                  <p>{idLive?.getId?.countLive}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("hang_da_ban")}</p>

                  <p>500.000</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("hang_da_ban")}</p>
                  <p>500.000</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("tg_xem")}</p>
                  <p>500.000 phút</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("luoc_share")}</p>
                  <p>500.000</p>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex gap-30">
                  <p>{t("hang_ton_kho")}</p>
                  <p>500.000</p>
                </div>
              </div>
            </div>
          </div>
          </div>
    </section>
  );
}
