import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import resSaleService from "./resSaleService";

export const addRegisterSale = createAsyncThunk(
  "res/resprience",
  async (data, thunkApi) => {
    try {
      return await resSaleService.addRegisterSale(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getAllSale = createAsyncThunk("Sale/get-all-sale", async (thunkApi) => {
  try {
    return await resSaleService.getAllSale();
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
export const updateProcessSale = createAsyncThunk(
  "Sale/update",
  async (data, thunkApi) => {
    try {
      return await resSaleService.updateProcess(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
const initialState = {
  resSale: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const resExpriSlice = createSlice({
  name: "resSale",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRegisterSale.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRegisterSale.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.resSale = action.payload;
      })
      .addCase(addRegisterSale.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getAllSale.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSale.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.resSale = action.payload;
      })
      .addCase(getAllSale.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.resSale = null;
      })

      .addCase(updateProcessSale.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProcessSale.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(updateProcessSale.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });
  },
});
export const { resetImages } = resExpriSlice.actions;
export default resExpriSlice.reducer;
