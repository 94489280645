import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

// tham so la img
const addRegisterExprience = async (
  data
) => {
  const res = await axios.post(
    `${base_url}res/addregister-exprience`,
    data,
    config
  );
  return res.data;
};
const getAllResEx = async () => {
  const res = await axios.get(
    `${base_url}res/get-all-exprience`,
    config
  );
  return res.data;
};
const getIdEx = async (id) => {
  const res = await axios.get(
    `${base_url}res/get-id-exprience/${id}`,
    config
  );
  return res.data;
};
const updateProcess = async (data) => {
  const res = await axios.put(
    `${base_url}res/update-process/${data._id}/${data.status}`,
    "",
    config
  );
  return res.data;
};

const uploadService = {
  addRegisterExprience,
  getAllResEx,getIdEx,
  updateProcess,
};

export default uploadService;
