import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const LoginRouters = ({
  children,
}) => {
  const user = useSelector(
    (state) => state.auth.user
  );

  return user?.role === "user" ? (
    children
  ) : (
    <Navigate
      to={"/login"}
      replace={true}
    />
  );
};
