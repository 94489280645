import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

// tham so la img
const addRegisterSale = async (
  data
) => {
  const res = await axios.post(
    `${base_url}sale/addregister-sale`,
    data,
    config
  );
  return res.data;
};
const getAllSale = async () => {
  const res = await axios.get(
    `${base_url}sale/get-all-sale`,
    config
  );
  return res.data;
};
const updateProcess = async (data) => {
  const res = await axios.put(
    `${base_url}sale/update-process/${data._id}/${data.status}`,
    "",
    config
  );
  return res.data;
};
const resSaleService = {
  addRegisterSale,
  updateProcess,
  getAllSale,
};

export default resSaleService;
