import React, {
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  EmailOTP,
  forgotPass,
} from "../features/users/userSlice";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { toast } from "react-toastify";
import tiktok from "../images/tiktok.png";
import ins from "../images/ins.png";
export default function ForgotPassword({
  t,
}) {
  let userSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("email_khong_hop"))
      .required(t("yc_nhap_email")), //required là hiển thị dòng lỗi phía dưới của input khi dữ liệu trống
    idName: Yup.string().required(
      t("yc_id")
    ),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [OTPValue, setOTPValue] =
    useState("");
  const OTPState = useSelector(
    (state) => state.auth.OTP?.OTP
  );
  useEffect(() => {
    setOTP(OTPState);
  }, [OTPState]);
  const formik = useFormik({
    initialValues: {
      email: "",
      idName: "",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (OTPValue === OTP) {
        dispatch(forgotPass(values));
        navigate("/reset-password");
      } else if (OTPValue == "") {
        toast.error(t("yc_otp"));
      } else {
        toast.error(t("otp_sai"));
      }
    },
  });
  const handleSubmit = () => {
    dispatch(EmailOTP(formik.values.email))
      .unwrap()
      .then(() => {
        toast.success(t("dang_gui_OTP"));
      })
      .catch((error) => {
        console.error(`Error dispatching OTP: ${error}`);
        toast.error(t("loi_gui_OTP"));
      });
  };
  return (
    <>
      <div
        className="container text-white py-5 mt-5"
        style={{
          position: "relative",
          zIndex: 10000,
        }}>
        <div className="pb-5">
          <h1>{t("qmk")}</h1>
          <p
            className="mb-3"
            style={{
              fontSize: "18px",
            }}>
            {t("ban_qmk")}
          </p>
        </div>
        <form
          onSubmit={
            formik.handleSubmit
          }>
          <div className="row">
            <div className="col-4">
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label">
                  ID
                </label>
                <input
                  type="text"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="ID"
                  value={
                    formik.values.idName
                  }
                  onChange={formik.handleChange(
                    "idName"
                  )}
                  onBlur={formik.handleBlur(
                    "idName"
                  )}
                />
                <div className="error mb-3">
                  {formik.touched
                    .idName &&
                  formik.errors
                    .idName ? (
                    <div>
                      {
                        formik.errors
                          .idName
                      }
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label">
                  {t("mail")}*
                </label>
                <input
                  type="email"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="example@example.com"
                  value={
                    formik.values.email
                  }
                  onChange={formik.handleChange(
                    "email"
                  )}
                  onBlur={formik.handleBlur(
                    "email"
                  )}
                />
                <p
                  className="button-res mb-0 mt-2 d-flex justify-content-end text-end"
                  style={{
                    cursor: "pointer",
                    borderRadius:
                      "24px",
                    width:
                      "fit-content",
                  }}
                  onClick={handleSubmit}>
                  {t("xn_otp")}
                </p>
                <div className="error mb-3">
                  {formik.touched
                    .email &&
                  formik.errors
                    .email ? (
                    <div>
                      {
                        formik.errors
                          .email
                      }
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label">
                  {t("nhap_ma")}
                </label>
                <input
                  type="text"
                  className="form-control input_custom"
                  placeholder={t(
                    "nhap_ma"
                  )}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    setOTPValue(
                      e.target.value
                    );
                  }}
                />
              </div>
              <p
                style={{
                  fontSize: "12px",
                }}>
                {t("da_xm")}
              </p>
            </div>
            <div className="col-4"></div>
          </div>
          <div className="d-flex justify-content-start mt-3">
            <button
              type="submit"
              className="btn_submit">
              {t("tieptheo")}
            </button>
          </div>
        </form>
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: "30px",
          zIndex: 9999,
        }}>
        <div>
          <img src={ins} alt="" />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "-240px",
            right: "20px",
          }}>
          <img src={tiktok} alt="" />
        </div>
      </div>
    </>
  );
}
