import React from "react";

export default function InputChoiceNT({
  type,
  value,
  onChange,
  onBlur,
  title,
  checked,
  defaultValue,
  id,
  img,
}) {
  return (
    <div className="form-check">
      <input
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        checked={checked}
        className="form-check-input"
        type={type}
        defaultValue={defaultValue}
        id={id}
      />
      <div className="">
        <img src={img} alt="" />
        <label className="form-check-label d-block py-2 text-center">
          {title}
        </label>
      </div>
    </div>
  );
}
