import React from "react";

export default function InputRadioCustom({
  type,
  value,
  onChange,
  className,
  onBlur,
  title,
  defaultValue,
  checked,
  id,
}) {
  return (
    <div
      className={`form-check mb-3 ${className}`}>
      <div
        className=""
        style={
          className
            ? { padding: "4px 12px" }
            : {}
        }>
        <input
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          checked={checked}
          className="form-check-input"
          type={type}
          defaultValue={defaultValue}
          id={id}
        />
        <label
          className="form-check-label"
          htmlFor={id}>
          {title}
        </label>
      </div>
    </div>
  );
}
