import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

// tham so la img
const addMaketing = async (data) => {
  const res = await axios.post(
    `${base_url}maketing/addregister-maketing`,
    data,
    config
  );
  return res.data;
};
const getAllMaketing = async () => {
  const res = await axios.get(
    `${base_url}maketing/get-all-maketing`,
    config
  );
  return res.data;
};
const getIdMaketing = async (id) => {
  const res = await axios.get(
    `${base_url}maketing/get-id-maketing/${id}`,
    config
  );
  return res.data;
};
const updateProcess = async (data) => {
  const res = await axios.put(
    `${base_url}maketing/update-process/${data._id}/${data.status}`,
    "",
    config
  );
  return res.data;
};

const maketingService = {
  addMaketing,
  getAllMaketing,getIdMaketing,
  updateProcess,
};

export default maketingService;
