import React, { useEffect } from "react";
import InputLableCustom from "../components/InputLableCustom";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNamecompany } from "../features/company/companySlice";

const StoreManager = ({ t }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector(state=>state.auth.user)
  useEffect(() => {
    if (auth?.idName && auth?.email) {
      const data = {
        idName: auth.idName,
        email: auth.email,
      };
      dispatch(getNamecompany(data));
    }
  }, [auth, dispatch]);

  const company = useSelector(state=>state.company.idcompany)
  return (
    <section className="container py-5 text-white">
      {/* tên dự án */}
      <div className="py-4">
        <h3 style={{fontSize:"70px"}}>{t("ql_cua_hang")}</h3>
      </div>
      <div className="pt-5">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="pb-4" style={{fontSize:"50px"}}>{t("thong_tin_thanh_vien")}</h3>
          <button className="btn btn-primary" onClick={()=>navigate(`/manager-update-new${company?.company?._id ? `/${company?.company?._id}` :""}`)}>{t("edit")}</button>
        </div>
        <div className="row pb-4">
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("ten_cty")}>
              {t("ten_cty")}
            </label>
            <input
              type="text"
              disabled
              value={company?.company?.titleCompany}
              placeholder={t("ten_cty")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("linh_vuc")}>
              {t("linh_vuc")}
            </label>
            <select
              className="form-select input_custom"
              disabled
              defaultValue={company?.company?.linhvuc}
              aria-label="Default select example"
              style={{
                width: "200px",
                borderRadius: "24px",
                background: "#1a1a1a",
                color: "white",
                outline: "none",
                border: "2px solid #fff",
              }}
            >
              <option value={t("skincare")}>{t("skincare")}</option>
              <option value={t("doan")}>{t("doan")}</option>
              <option value={t("dogiadung")}>{t("dogiadung")}</option>
              <option value={t("sach")}>{t("sach")}</option>
            </select>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("sdt")}>
              {t("sdt")}
            </label>
            <input
              disabled  
              type="text"
              value={company?.company?.phoneCompany}
              placeholder={t("sdt")}
              className="input_custom"
            />
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("nguoi_phu_trach")}>
              {t("nguoi_phu_trach")}
            </label>
            <input
              disabled
              value={company?.company?.phutrach}
              type="text"
              placeholder={t("nguoi_phu_trach")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("mail")}>
              {t("mail")}
            </label>
            <input
              type="text"
              value={company?.company?.emailCompany}
              disabled
              placeholder={t("mail")}
              className="input_custom"
            />
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
            <label className="me-3" htmlFor={t("gpkd")}>
              {t("logo")}
            </label>
            <div className="text-end d-flex justify-content-end"
            style={{
              width:"203px"
            }}>
              <div
                className="w100 d-flex justify-content-between"
                style={{
                }}
              >
                  <div
                  className="img_post  d-flex align-items-center justify-content-end text-center"
                  style={{
                      height: "100%",
                  }}
                  >
                    <section className="image-section">
                      <div className="position-relative">
                        <img
                          src={company?.company?.images[0]?.url}
                          alt=""
                          // width={200}
                          height={200}
                      />
                      </div>
                    </section>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="bg-white mb-4 mt-0 p-0" />
      <div>
        <div className="py-5">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <h1 className="mb-3">{t("ql_san_pham")}</h1>
            <Link to="/manager-addproduct">
              <button className="btn_add">{t("them_san_pham")}</button>
            </Link>
          </div>
        </div>
        <div>
          <div className="row pb-4">
            <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
              <label className="me-3" htmlFor={t("hinh_thai")}>
                {t("hinh_thai")}
              </label>
              <select
                className="form-select input_custom"
                aria-label="Default select example"
                style={{
                  width: "200px",
                  borderRadius: "24px",
                  background: "#1a1a1a",
                  color: "white",
                  outline: "none",
                  border: "2px solid #fff",
                }}
              >
                <option selected>{t("toan_bo")}</option>
                <option value="1">{t("offline")}</option>
                <option value="2">{t("online")}</option>
              </select>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
              <label className="me-3" htmlFor={t("hinh_thai")}>
                {t("hinh_thai")}
              </label>
              <select
                className="form-select input_custom"
                aria-label="Default select example"
                style={{
                  width: "200px",
                  borderRadius: "24px",
                  background: "#1a1a1a",
                  color: "white",
                  outline: "none",
                  border: "2px solid #fff",
                }}
              >
                <option selected>{t("toan_bo")}</option>
                <option value={t("offline")}>{t("offline")}</option>
                <option value={t("online")}>{t("online")}</option>
              </select>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
              <label className="me-3" htmlFor={t("cua_hang")}>
                {t("cua_hang")}
              </label>
              <select
                className="form-select input_custom"
                aria-label="Default select example"
                style={{
                  width: "200px",
                  borderRadius: "24px",
                  background: "#1a1a1a",
                  color: "white",
                  outline: "none",
                  border: "2px solid #fff",
                }}
              >
                <option selected>{t("toan_bo")}</option>
                <option value="1">{t("offline")}</option>
                <option value="2">{t("online")}</option>
              </select>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
              {/* <label className="me-3" htmlFor={t("trong_luong")}>
                {t("thoigian")}
              </label> */}
              <InputLableCustom title={t("thoi_gian_bat_dau")} type="date" />
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-between mb-3">
              {/* <label className="me-3" htmlFor={t("trong_luong")}>
                {t("thoigian")}
              </label> */}
              <InputLableCustom title={t("thoi_gian_ket_thuc")} type="date" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StoreManager;
