import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = ({t,changeLanguage}) => {
  return (
    <div
      className="bg-black"
      style={{ overflow: "hidden" }}>
      <Header t={t} changeLanguage={changeLanguage} />
      <div
        style={{ minHeight: "100vh" }}>
        <Outlet />
      </div>
      <Footer t={t} />
      <ToastContainer
        position="bottom-right"
        autoClose={8080}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Layout;
