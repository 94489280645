import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

// tham so la img
const addProducts = async (
  data
) => {
  const res = await axios.post(
    `${base_url}product/add-product`,
    data,
    config
  );
  return res.data;
};
const getAllProduct = async () => {
  const res = await axios.get(
    `${base_url}product/get-all-product`,
    config
  );
  return res.data;
};
const updateProduct = async (data) => {
  const res = await axios.put(
    `${base_url}product/update-product/${data._id}/${data.status}`,
    "",
    config
  );
  return res.data;
};
const resSaleService = {
  addProducts,
  updateProduct,
  getAllProduct,
};

export default resSaleService;
