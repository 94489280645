import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import resExprienceService from "./resExprienceService";

export const addRegisterExprience = createAsyncThunk(
  "res/resprience",
  async (data, thunkApi) => {
    try {
      return await resExprienceService.addRegisterExprience(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getAllExprience = createAsyncThunk(
  "res/get-all",
  async (data, thunkApi) => {
    try {
      return await resExprienceService.getAllResEx(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getIdExprience = createAsyncThunk(
  "res/get-id",
  async (id, thunkApi) => {
    try {
      return await resExprienceService.getIdEx(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const updateProcessExprience = createAsyncThunk(
  "res/update",
  async (data, thunkApi) => {
    try {
      return await resExprienceService.updateProcess(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
const initialState = {
  resExpri: [],
  idEx:{},
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const resExpriSlice = createSlice({
  name: "resExpris",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRegisterExprience.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRegisterExprience.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(addRegisterExprience.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getAllExprience.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllExprience.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.resExpri = action.payload;
      })
      .addCase(getAllExprience.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.resExpri = null;
      })
      .addCase(getIdExprience.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIdExprience.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.idEx = action.payload;
      })
      .addCase(getIdExprience.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.idEx = null;
      })
      .addCase(updateProcessExprience.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProcessExprience.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(updateProcessExprience.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      });
  },
});
export const { resetImages } = resExpriSlice.actions;
export default resExpriSlice.reducer;
