import React, {
  useEffect,
  useState,
} from "react";
import { IoReload } from "react-icons/io5";
import { CiWarning } from "react-icons/ci";
import h1 from "../images/m4.png";
import h2 from "../images/m.png";
import { FaAngleDown } from "react-icons/fa";
import {
  storeOffline,
  storeOnline,
} from "../data/data";
import Dropzone from "react-dropzone";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputLableCustom from "../components/InputLableCustom";
import InputChoiceNT from "../components/InputChoiceNT";
import off1 from "../images/off1.png";
import off2 from "../images/off2.png";
import off3 from "../images/off3.png";
import off4 from "../images/off4.png";
import off5 from "../images/off5.png";
import off6 from "../images/off6.png";
import off7 from "../images/off7.png";
import off8 from "../images/off8.png";
import off9 from "../images/off9.png";
import off10 from "../images/off10.png";
import off11 from "../images/off11.png";
import tt from "../images/tt.png";
import ig from "../images/ig.png";
import yt from "../images/yt.png";
import fb from "../images/fb.png";
import {
  delImg,
  delImgGpkd,
  resetImages,
  uploadImgGpkd,
} from "../features/upload/uploadSlice";
import { toast } from "react-toastify";
import { addRegisterSale } from "../features/registerSale/resSaleSlice";
import { config } from "../utils/axiosconfig";
import { base_url } from "../utils/base_url";
import axios from "axios";
import {
  DatePicker,
  Space,
} from "antd";
import { useNavigate } from "react-router-dom";
const { RangePicker } = DatePicker;

export default function RegisterSale({
  t,
}) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const storeOnline = [
    {
      title: t("ins"),
      value: t("ins"),
      id: t("ins"),
      img: ig,
    },
    {
      title: t("yt"),
      value: t("yt"),
      id: t("yt"),
      img: yt,
    },
    {
      title: t("fb"),
      value: t("fb"),
      id: t("fb"),
      img: fb,
    },
    {
      title: t("tiktok"),
      value: t("tiktok"),
      id: t("tiktok"),
      img: tt,
    },
  ];
  const storeOffline = [
    {
      title: "Aeon",
      value: "Aeon",
      id: "Aeon",
      img: off1,
    },
    {
      title: "Trang Tien Plaza",
      value: "Trang Tien Plaza",
      id: "Trang Tien Plaza",
      img: off2,
    },
    {
      title: "Watsons",
      value: "Watsons",
      id: "Watsons",
      img: off3,
    },
    {
      title: "Guardian",
      value: "Guardian",
      id: "Guardian",
      img: off4,
    },
    {
      title: "Vincom",
      value: "Vincom",
      id: "Vincom",
      img: off5,
    },
    {
      title: "Parkson",
      value: "Parkson",
      id: "Parkson",
      img: off6,
    },
    {
      title: "Sammishop",
      value: "Sammishop",
      id: "Sammishop",
      img: off7,
    },
    {
      title: "Hasaki",
      value: "Hasaki",
      id: "Hasaki",
      img: off8,
    },
    {
      title: t("lottemart"),
      value: t("lottemart"),
      id: t("lottemart"),
      img: off9,
    },
    {
      title: t("nhathuoc"),
      value: t("nhathuoc"),
      id: t("nhathuoc"),
      img: off10,
    },
    {
      title: "The Garden",
      value: "The Garden",
      id: "The Garden",
      img: off11,
    },
  ];
  const [
    selectedOnline,
    setSelectedOnline,
  ] = useState(storeOnline[0].value);
  const [
    selectedOffline,
    setSelectedOffline,
  ] = useState(storeOffline[0].value);
  const [type, setType] = useState(
    t("ban_chinh")
  );
  let userSchema = Yup.object().shape({
    titleProject: Yup.string().required(
      t("yc_nhap_ten_da")
    ),
    nameCompany: Yup.string().required(
      t("yc_nhap_ten_cty")
    ),
    email: Yup.string()
      .email(t("chua_dung_email"))
      .required(t("yc_nhap_email")),
    phone: Yup.string().required(
      t("yc_nhap_sdt")
    ),
    phutrach: Yup.string().required(
      t("yc_nhap_npt")
    ),
    products: Yup.array()
      .of(
        Yup.object().shape({
          brand: Yup.string().required(
            t("yc_nhap_thuong_hieu")
          ),
          typeProduct:
            Yup.string().required(
              t("yc_nhap_loai_sp")
            ),
          titleProduct:
            Yup.string().required(
              t("yc_nhap_ten_sp")
            ),
          trongluong:
            Yup.string().required(
              t("yc_nhap_trong_luong")
            ),
          theloai:
            Yup.string().required(
              t("yc_nhap_the_loai")
            ),
          price: Yup.string().required(
            t("yc_nhap_gia_tien")
          ),
          countNK:
            Yup.string().required(
              t("yc_nhap_sl_nhap_khau")
            ),
          HSD: Yup.string().required(
            t("yc_chon_hang_sd")
          ),
          FOB: Yup.string().required(
            t("yc_nhap_FOB")
          ),
          priceHQ:
            Yup.string().required(
              t("yc_nhap_tien_hq")
            ),
          priceVN:
            Yup.string().required(
              t("yc_nhap_tien_vn")
            ),
        })
      )
      .min(1, t("yc_them_sp")), // Ensure at least one product is added
  });
  const imageGpkdState = useSelector(
    (state) => state.upload.imageGpkd
  );
  const auth = useSelector(
    (state) => state.auth.user
  );
  const onOk = (value) => {
    console.log("onOk: ", value);
  };
  const imggpkd = [];
  imageGpkdState?.forEach((i) => {
    imggpkd.push({
      public_id: i.public_id,
      url: i.url,
    });
  });
  useEffect(() => {
    formik.values.imageGpkd = imggpkd;
  }, [imggpkd]);
  const up = async (
    acceptedFiles,
    productIndex
  ) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) =>
      formData.append("images", file)
    ); // Make sure the field name matches

    try {
      const res = await axios.post(
        `${base_url}upload/upload`,
        formData,
        config
      );
      const updatedImages = res.data;
      formik.setFieldValue(
        `products[${productIndex}].imageProduct`,
        updatedImages
      );
      console.log(res.data);
      // Handle response, e.g., update formik field
    } catch (error) {
      console.error(
        "Error uploading file:",
        error
      );
      toast.error(t("upload_failed"));
    }
  };
  const upbg = async (
    acceptedFiles,
    productIndex
  ) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) =>
      formData.append("images", file)
    );

    try {
      const res = await axios.post(
        `${base_url}upload/upload`,
        formData,
        config
      );
      const updatedImages = res.data;
      formik.setFieldValue(
        `products[${productIndex}].imageBg`,
        updatedImages
      );
      console.log(res.data);
    } catch (error) {
      console.error(
        "Error uploading file:",
        error
      );
      toast.error(t("upload_failed"));
    }
  };
  const handleRemoveLastProduct = () => {
    // Kiểm tra nếu có ít nhất một sản phẩm
    if (formik.values.products.length > 0) {
      // Loại bỏ phần tử cuối cùng
      const updatedProducts = formik.values.products.slice(0, -1);
      formik.setFieldValue("products", updatedProducts);
    }
  };
  
  const formik = useFormik({
    initialValues: {
      titleProject: "",

      nameCompany: "",
      typeCompanyProduct: t("skincare"),
      phone: "",
      phutrach: "",
      email: "",
      imageGpkd: "",

      products: [
        {
          brand: "",
          typeProduct: t("skincare"),
          titleProduct: "",
          trongluong: "",
          theloai: "",
          imageProduct: "",
          imageBg: "",
          countNK: "",
          price: "",
          HSD: "",
          FOB: "",
          priceHQ: "",
          priceVN: "",
        },
      ],

      storeOnline: selectedOnline,
      storeOffline: selectedOffline,

      startDate: "",
      endDate: "",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      if (auth !== null) {
        const hasImagesInProducts =
          values.products.every(
            (product) =>
              product.imageProduct
                .length > 0
          );
        const hasImagesInProductBg =
          values.products.every(
            (product) =>
              product.imageBg.length > 0
          );

        if (
          !hasImagesInProducts &&
          !hasImagesInProductBg &&
          imageGpkdState.length < 1
        ) {
          toast.warning(
            t("ban_chua_chon_anh")
          );
          return; // Prevent form submission
        } else {
          let data = {
            titleProject:
              values.titleProject,
            infoBrand: {
              nameCompany:
                values.nameCompany,
              typeCompanyProduct:
                values.typeCompanyProduct,
              phone: values.phone,
              phutrach: values.phutrach,
              email: values.email,
              imgGPKD: values.imageGpkd,
            },
            infoProduct:
              values.products,
            storeOnline: selectedOnline,
            storeOffline:
              selectedOffline,
            schedule: {
              startDate:
                values.startDate,
              endDate: values.endDate,
            },
            type: type,
          };
          console.log("data", data);

          try {
            dispatch(
              addRegisterSale(data)
            );
            setTimeout(() => {
              formik.resetForm();
              dispatch(resetImages());
              toast.success(t("save"));
              setSelectedOnline(
                t("ins")
              );
              setSelectedOffline(
                t("Aeon")
              );
            }, 300);
          } catch (error) {
            toast.error(t("dk_tb"));
          }
        }
      } else {
        toast.warning(t("ban_chua_dn"));
      }
    },
  });
  return (
    <>
      <section className="container py-5">
        <div className="row text-white">
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: 1 }}>
            <h1 className="py-3 display-4" style={{fontSize:"70px"}}>
              {t("xin_chao")}
            </h1>
            <p  
              style={{fontSize:"50px"}}
              className="py-4 fs-5"
              dangerouslySetInnerHTML={{
                __html: t(
                  "ban_muon_dang_ky"
                ),
              }}></p>
            <div className="d-flex align-items-end" onClick={()=>navigate('/truyxuat-sale')} >
              <button className="btn_cus d-flex align-items-center">
                <IoReload className="me-3" />
                <span className="d-block">
                  {t("truy_xuat")}
                </span>
              </button>
              <span className="ps-3">
              {t(
                  "truy_xuat_thong_tin"
                )}
              </span>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12"
            style={{ zIndex: "0" }}>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: "-60px",
              }}>
              <img
                src={h1}
                alt=""
                width="80%"
              />
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}>
              <img src={h2} alt="" />
            </div>
          </div>
        </div>
      </section>
      <hr className="text-white my-5" />
      <section className="container py-4 text-white">
        <form
          onSubmit={
            formik.handleSubmit
          }>
          <div className="row py-3">
            <h1 className="pb-4" style={{fontSize:"60px"}}>
              {t("thong_tin_du_an")}
            </h1>
            <div className="col-lg-8 col-md-8 position-relative">
              <div className="d-flex flex-column align-items-center mb-3 w100">
                <div
                  className="gap-3 align-items-center justify-content-between w100"
                  style={{
                    height: "50px",
                  }}>
                  <div className="d-flex gap-3 align-items-center justify-content-between">
                    <label
                      htmlFor={t(
                        "ten_du_an"
                      )}
                      className="me-3 mb-2"
                      style={{
                        flex: 1,
                      }}>
                      {t("ten_du_an")}
                    </label>
                    <div
                      style={{
                        position:
                          "relative",
                        display:
                          "inline-block",
                        width: "40%",
                      }}>
                      <select
                        className="form-select input_custom"
                        aria-label="Default select example"
                        style={{
                          flex: 3,
                          width: "100%",
                          borderRadius:
                            "24px",
                          background:
                            "#1a1a1a",
                          color:
                            "white",
                          outline:
                            "none",
                          border:
                            "2px solid #fff",
                          appearance:
                            "none", // Hide the default dropdown arrow
                          paddingRight:
                            "2.5rem", // Add space for the icon
                        }}>
                        <option
                          selected>
                          {t("tao_moi")}
                        </option>
                        <option
                          value={t(
                            "tao_cu"
                          )}>
                          {t("tao_cu")}
                        </option>
                        <option
                          value={t(
                            "ban_nhap"
                          )}>
                          {t(
                            "ban_nhap"
                          )}
                        </option>
                      </select>
                      <div
                        style={{
                          position:
                            "absolute",
                          top: "50%",
                          right: "1rem", // Adjust the position from the right
                          transform:
                            "translateY(-50%)",
                          pointerEvents:
                            "none", // Allow clicks to pass through to the select element
                        }}>
                        <FaAngleDown />
                      </div>
                    </div>
                    <input
                      style={{
                        flex: 3,
                      }}
                      type="text"
                      placeholder={t(
                        "ten_du_an"
                      )}
                      className="input_custom"
                      value={
                        formik.values
                          .titleProject
                      }
                      onChange={formik.handleChange(
                        "titleProject"
                      )}
                      onBlur={formik.handleBlur(
                        "titleProject"
                      )}
                    />
                  </div>
                </div>
                <div
                  className="error mb-3 d-flex justify-content-end"
                  style={{
                    top: "100%",
                  }}>
                  {formik.touched
                    .titleProject &&
                  formik.errors
                    .titleProject ? (
                    <div>
                      {
                        formik.errors
                          .titleProject
                      }
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4"></div>
          </div>
          <div className="">
            <div className="py-5">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <h1 className="mb-3" style={{fontSize:"60px"}}>
                  {t("i4_thuonghieu")}
                </h1>
              </div>
            </div>
            <div>
              <h3 className="pb-4 mb-5">
                {t("i4_thuonghieu")}
              </h3>
              <div className="row pb-4">
                <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={t(
                      "ten_cty"
                    )}>
                    {t("ten_cty")}
                  </label>
                  <div className="w70">
                    <input
                      type="text"
                      placeholder={t(
                        "ten_cty"
                      )}
                      className="input_custom w100"
                      value={
                        formik.values
                          .nameCompany
                      }
                      onChange={formik.handleChange(
                        "nameCompany"
                      )}
                      onBlur={formik.handleBlur(
                        "nameCompany"
                      )}
                      name="nameCompany" // Ensure you include the name attribute
                    />
                    {formik.touched
                      .nameCompany &&
                    formik.errors
                      .nameCompany ? (
                      <div className="error mb-3">
                        {
                          formik.errors
                            .nameCompany
                        }
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={t(
                      "loai_sp"
                    )}>
                    {t("loai_sp")}
                  </label>
                  <div
                    className="w70"
                    style={{
                      position:
                        "relative",
                    }}>
                    <select
                      className="form-select input_custom w100"
                      aria-label="Default select example"
                      defaultValue={
                        "Skincare"
                      }
                      style={{
                        borderRadius:
                          "24px",
                        background:
                          "#1a1a1a",
                        color: "white",
                        outline: "none",
                        border:
                          "2px solid #fff",
                      }}
                      value={
                        formik.values
                          .typeCompanyProduct
                      }
                      onChange={formik.handleChange(
                        "typeCompanyProduct"
                      )}
                      onBlur={formik.handleBlur(
                        "typeCompanyProduct"
                      )}
                      name="typeCompanyProduct">
                      <option
                        value={t(
                          "skincare"
                        )}>
                        {t("skincare")}
                      </option>
                      <option
                        value={t(
                          "doan"
                        )}>
                        {t("doan")}
                      </option>
                      <option
                        value={t(
                          "dogiadung"
                        )}>
                        {t("dogiadung")}
                      </option>
                      <option
                        value={t(
                          "sach"
                        )}>
                        {t("sach")}
                      </option>
                    </select>
                    <div
                      style={{
                        position:
                          "absolute",
                        top: "50%",
                        right: "1rem", // Adjust the position from the right
                        transform:
                          "translateY(-50%)",
                        pointerEvents:
                          "none", // Allow clicks to pass through to the select element
                      }}>
                      <FaAngleDown />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                  <label
                    className="me-3 w30 "
                    htmlFor={t("sdt")}>
                    {t("sdt")}
                  </label>
                  <div className="w70">
                    <input
                      type="number"
                      placeholder={t(
                        "sdt"
                      )}
                      className="input_custom w100"
                      value={
                        formik.values
                          .phone
                      }
                      onChange={formik.handleChange(
                        "phone"
                      )}
                      onBlur={formik.handleBlur(
                        "phone"
                      )}
                    />
                    {formik.touched
                      .phone &&
                    formik.errors
                      .phone ? (
                      <div className="error mb-3">
                        {
                          formik.errors
                            .phone
                        }
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row pb-4">
                <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                  <label
                    className="me-3 w30 w30"
                    htmlFor={t(
                      "nguoi_phu_trach"
                    )}>
                    {t(
                      "nguoi_phu_trach"
                    )}
                  </label>
                  <div className="w70">
                    <input
                      type="text"
                      placeholder={t(
                        "nguoi_phu_trach"
                      )}
                      className="input_custom w100"
                      value={
                        formik.values
                          .phutrach
                      }
                      onChange={formik.handleChange(
                        "phutrach"
                      )}
                      onBlur={formik.handleBlur(
                        "phutrach"
                      )}
                    />
                    {formik.touched
                      .phutrach &&
                    formik.errors
                      .phutrach ? (
                      <div className="error mb-3">
                        {
                          formik.errors
                            .phutrach
                        }
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                  <label
                    className="me-3 w30 w30"
                    htmlFor={t("mail")}>
                    {t("mail")}
                  </label>
                  <div className="w70">
                    <input
                      type="text"
                      placeholder={t(
                        "mail"
                      )}
                      className="input_custom w100"
                      value={
                        formik.values
                          .email
                      }
                      onChange={formik.handleChange(
                        "email"
                      )}
                      onBlur={formik.handleBlur(
                        "email"
                      )}
                    />
                    {formik.touched
                      .email &&
                    formik.errors
                      .email ? (
                      <div className="error mb-3">
                        {
                          formik.errors
                            .email
                        }
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                  <label
                    className="me-3 w30"
                    htmlFor={t("gpkd")}>
                    {t("gpkd")}
                  </label>
                  <div
                    className="w70 d-flex justify-content-between"
                    style={{
                      height: "100%",
                    }}>
                    <div
                      style={{
                        height: "100%",
                      }}
                      className="img_post w70 d-flex align-items-center justify-content-between text-center">
                         <section className="image-section">
                          {imageGpkdState &&
                            imageGpkdState?.map((i, j) => {
                              const fileName = i.url.split('/').pop().split('?')[0];
                              return (
                                <div className="position-relative" key={j}>
                                  <button
                                    type="button"
                                    onClick={() => dispatch(delImgGpkd(i.public_id))}
                                    className="btn-close position-absolute"
                                    style={{
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></button>
                                  <p className="file-name mb-0">{fileName}</p>
                                </div>
                              );
                            })}
                        </section>
                      {/* <section className="image-section">
                        {imageGpkdState &&
                          imageGpkdState?.map(
                            (i, j) => (
                              <div
                                className=" position-relative"
                                key={j}>
                                {" "}
                                <button
                                  type="button"
                                  onClick={() =>
                                    dispatch(
                                      delImgGpkd(
                                        i.public_id
                                      )
                                    )
                                  }
                                  className="btn-close position-absolute"
                                  style={{
                                    top: "10px",
                                    right:
                                      "10px",
                                  }}></button>{" "}
                                <img
                                  src={
                                    i.url
                                  }
                                  alt=""
                                  width={
                                    200
                                  }
                                  height={
                                    200
                                  }
                                />{" "}
                              </div>
                            )
                          )}
                      </section> */}
                    </div>
                    <Dropzone
                      onDrop={(
                        acceptedFiles
                      ) =>
                        dispatch(
                          uploadImgGpkd(
                            acceptedFiles
                          )
                        )
                      }>
                      {({
                        getRootProps,
                        getInputProps,
                      }) => (
                        <div>
                          <div
                            className="upload-button"
                            {...getRootProps()}>
                            <input
                              {...getInputProps()}
                            />

                            <button
                              type="button"
                              className="btn_upload">
                              {t(
                                "tai_len"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        <hr className="text-white my5" />
          <div className="py-5">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h1 className="mb-3" style={{fontSize:"60px"}}>
                {t("nhap_thong_tin_sp")}
              </h1>
              <div className="">
                <div className="boxed">
                  <button
                    className="btn_add"
                    onClick={() => {
                      const newProduct = {
                        brand: "",
                        typeProduct:
                          t("skincare"),
                        titleProduct: "",
                        trongluong: "",
                        theloai: "",
                        imageProduct: "",
                        imageBg: "",
                        countNK: "",
                        price: "",
                        HSD: "",
                        FOB: "",
                        priceHQ: "",
                        priceVN: "",
                      };
                      formik.setFieldValue(
                        "products",
                        [
                          ...formik.values
                            .products,
                          newProduct,
                        ]
                      );
                    }}>
                    {t("them_san_pham")}
                  </button>
                </div>
                <button
                    className="btn btn-primary mt-3"
                    style={{width:"178px",borderRadius:"24px"}}
                    onClick={handleRemoveLastProduct}
                  >
                    {t("xoa_san_pham")}
                  </button>
              </div>
            </div>
          </div>
          {formik.values.products?.map(
            (product, index) => (
              <div key={index}>
                <h3 className="pb-4">
                  {t("thong_tin_sp")}{" "}
                  {index + 1}
                </h3>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].brand`}>
                      {t(
                        "ten_thuong_hieu"
                      )}
                    </label>

                    <div className="w70">
                      <input
                        type="text"
                        style={{
                          width: "100%",
                        }}
                        placeholder={t(
                          "ten_thuong_hieu"
                        )}
                        className="input_custom"
                        value={
                          product.brand
                        }
                        onChange={formik.handleChange(
                          `products[${index}].brand`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].brand`
                        )}
                        name={`products[${index}].brand`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.brand &&
                      formik.errors
                        .products?.[
                        index
                      ]?.brand ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].brand
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].typeProduct`}>
                      {t("loai_sp")}
                    </label>
                    <div
                      className="w70"
                      style={{
                        position:
                          "relative",
                      }}>
                      <select
                        className="form-select input_custom"
                        aria-label="Default select example"
                        defaultValue={
                          "Skincare"
                        }
                        style={{
                          width: "100%",
                          borderRadius:
                            "24px",
                          background:
                            "#1a1a1a",
                          color:
                            "white",
                          outline:
                            "none",
                          border:
                            "2px solid #fff",
                        }}
                        value={
                          product.typeProduct
                        }
                        onChange={
                          formik.handleChange
                        }
                        onBlur={
                          formik.handleBlur
                        }
                        name={`products[${index}].typeProduct`}>
                        <option
                          value={t(
                            "skincare"
                          )}>
                          {t(
                            "skincare"
                          )}
                        </option>
                        <option
                          value={t(
                            "doan"
                          )}>
                          {t("doan")}
                        </option>
                        <option
                          value={t(
                            "dogiadung"
                          )}>
                          {t(
                            "dogiadung"
                          )}
                        </option>
                        <option
                          value={t(
                            "sach"
                          )}>
                          {t("sach")}
                        </option>
                      </select>
                      <div
                        style={{
                          position:
                            "absolute",
                          top: "50%",
                          right: "1rem", // Adjust the position from the right
                          transform:
                            "translateY(-50%)",
                          pointerEvents:
                            "none", // Allow clicks to pass through to the select element
                        }}>
                        <FaAngleDown />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].titleProduct`}>
                      {t("ten_sp")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        style={{
                          width: "100%",
                        }}
                        placeholder={t(
                          "ten_sp"
                        )}
                        className="input_custom"
                        value={
                          product.titleProduct
                        }
                        onChange={formik.handleChange(
                          `products[${index}].titleProduct`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].titleProduct`
                        )}
                        name={`products[${index}].titleProduct`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.titleProduct &&
                      formik.errors
                        .products?.[
                        index
                      ]
                        ?.titleProduct ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ]
                              .titleProduct
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].trongluong`}>
                      {t("trong_luong")}
                    </label>
                    <div className="w70">
                      <input
                        style={{
                          width: "100%",
                        }}
                        type="text"
                        placeholder={t(
                          "trong_luong"
                        )}
                        className="input_custom"
                        value={
                          product.trongluong
                        }
                        onChange={formik.handleChange(
                          `products[${index}].trongluong`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].trongluong`
                        )}
                        name={`products[${index}].trongluong`}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.trongluong &&
                      formik.errors
                        .products?.[
                        index
                      ]?.trongluong ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].trongluong
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].theloai`}>
                      {t("the_loai")}
                    </label>
                    <div className="w70">
                      <input
                        style={{
                          width: "100%",
                        }}
                        type="text"
                        placeholder={t(
                          "theloai_"
                        )}
                        className="input_custom"
                        value={
                          product.theloai
                        }
                        onChange={
                          formik.handleChange
                        }
                        onBlur={
                          formik.handleBlur
                        }
                        name={`products[${index}].theloai`}
                      />
                      <div>
                        {formik.touched
                          .products?.[
                          index
                        ]?.theloai &&
                        formik.errors
                          .products?.[
                          index
                        ]?.theloai ? (
                          <div className="error mb-3">
                            {
                              formik
                                .errors
                                .products[
                                index
                              ].theloai
                            }
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].imageProduct`}>
                      {t("them_ha")}
                    </label>
                    <div
                      className="w70 d-flex justify-content-between"
                      style={{
                        height: "100%",
                      }}>
                      <div
                        className="img_post w70 d-flex align-items-center justify-content-between text-center"
                        style={{
                          height:
                            "100%",
                        }}>
                          <section className="image-section">
                          {product.imageProduct &&
                            product.imageProduct?.map((i, j) => {
                              const fileName = i.url.split('/').pop().split('?')[0];
                              return (
                                <div className="position-relative" key={j}>
                                  <button
                                    type="button"
                                    onClick={() => dispatch(delImg(i.public_id))}
                                    className="btn-close position-absolute"
                                    style={{
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></button>
                                  <p className="file-name mb-0">{fileName}</p>
                                </div>
                              );
                            })}
                        </section>
                        {/* <section className="image-section">
                          {product.imageProduct &&
                            product.imageProduct.map(
                              (
                                i,
                                j
                              ) => (
                                <div
                                  className="position-relative"
                                  key={
                                    j
                                  }>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      dispatch(
                                        delImg(
                                          i.public_id
                                        )
                                      )
                                    }
                                    className="btn-close position-absolute"
                                    style={{
                                      top: "10px",
                                      right:
                                        "10px",
                                    }}></button>
                                  <img
                                    src={
                                      i.url
                                    }
                                    alt=""
                                    width={
                                      200
                                    }
                                    height={
                                      200
                                    }
                                  />
                                </div>
                              )
                            )}
                        </section> */}
                      </div>
                      <Dropzone
                        onDrop={(
                          acceptedFiles
                        ) => {
                          if (
                            auth !==
                            null
                          ) {
                            up(
                              acceptedFiles,
                              index
                            );
                          } else {
                            toast.warning(
                              t(
                                "ban_chua_dn"
                              )
                            );
                          }
                        }}>
                        {({
                          getRootProps,
                          getInputProps,
                        }) => (
                          <div className="">
                            <div
                              {...getRootProps()}
                              className="upload-button">
                              <input
                                {...getInputProps()}
                              />
                              <button
                                type="button"
                                className="btn_upload">
                                {t(
                                  "tai_len"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                <div className="row pb-4 mt-2">
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={`products[${index}].price`}>
                      {t("gia_cai")}
                    </label>
                    <div className="w70">
                      <input
                        style={{
                          width: "100%",
                        }}
                        type="number"
                        placeholder={t(
                          "gia_cai"
                        )}
                        className="input_custom"
                        value={
                          product.price
                        }
                        onChange={formik.handleChange(
                          `products[${index}].price`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].price`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.price &&
                      formik.errors
                        .products?.[
                        index
                      ]?.price ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].price
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={t(
                        `products[${index}].HSD`
                      )}>
                      {t("hsd")}
                    </label>
                    <div className="w70">
                      <input
                        style={{
                          width: "100%",
                        }}
                        type="date"
                        placeholder="example@example.com"
                        className="input_custom"
                        value={
                          product.HSD
                        }
                        onChange={formik.handleChange(
                          `products[${index}].HSD`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].HSD`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.HSD &&
                      formik.errors
                        .products?.[
                        index
                      ]?.HSD ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].HSD
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label className="me-3 w30">
                      {t(
                        "hinh_nen_trang"
                      )}
                    </label>
                    <div
                      className="w70 d-flex justify-content-between"
                      style={{
                        height: "100%",
                      }}>
                      <div
                        className="img_post w70 d-flex align-items-center justify-content-between text-center"
                        style={{
                          height:
                            "100%",
                        }}>
                        <section className="image-section">
                          {product.imageBg  &&
                            product.imageBg ?.map((i, j) => {
                              const fileName = i.url.split('/').pop().split('?')[0];
                              return (
                                <div className="position-relative" key={j}>
                                  <button
                                    type="button"
                                    onClick={() => dispatch(delImg(i.public_id))}
                                    className="btn-close position-absolute"
                                    style={{
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  ></button>
                                  <p className="file-name mb-0">{fileName}</p>
                                </div>
                              );
                            })}
                        </section>
                        {/* <section className="image-section">
                          {product.imageBg &&
                            product.imageBg.map(
                              (
                                i,
                                j
                              ) => (
                                <div
                                  className="position-relative"
                                  key={
                                    j
                                  }>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      dispatch(
                                        delImg(
                                          i.public_id
                                        )
                                      )
                                    }
                                    className="btn-close position-absolute"
                                    style={{
                                      top: "10px",
                                      right:
                                        "10px",
                                    }}></button>
                                  <img
                                    src={
                                      i.url
                                    }
                                    alt=""
                                    width={
                                      200
                                    }
                                    height={
                                      200
                                    }
                                  />
                                </div>
                              )
                            )}
                        </section> */}
                      </div>
                      <Dropzone
                        onDrop={(
                          acceptedFiles
                        ) => {
                          if (
                            auth !==
                            null
                          ) {
                            // fix
                            upbg(
                              acceptedFiles,
                              index
                            );
                          } else {
                            toast.warning(
                              t(
                                "ban_chua_dn"
                              )
                            );
                          }
                        }}>
                        {({
                          getRootProps,
                          getInputProps,
                        }) => (
                          <div>
                            <div
                              {...getRootProps()}
                              className="upload-button">
                              <input
                                {...getInputProps()}
                              />
                              <button
                                type="button"
                                className="btn_upload">
                                {t(
                                  "tai_len"
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={t(
                        "so_to"
                      )}>
                      {t("so_to")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "so_to"
                        )}
                        className="input_custom w100"
                        value={
                          product.countNK
                        }
                        onChange={formik.handleChange(
                          `products[${index}].countNK`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].countNK`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.countNK &&
                      formik.errors
                        .products?.[
                        index
                      ]?.countNK ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].countNK
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30"
                      htmlFor={t(
                        "gia"
                      )}>
                      {t("gia")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "vl_nhapso"
                        )}
                        className="input_custom w100"
                        value={
                          product.FOB
                        }
                        onChange={formik.handleChange(
                          `products[${index}].FOB`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].FOB`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.FOB &&
                      formik.errors
                        .products?.[
                        index
                      ]?.FOB ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].FOB
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30 "
                      htmlFor={t(
                        "gia_tien_hq"
                      )}>
                      {t("gia_tien_hq")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "vl_nhapso"
                        )}
                        className="input_custom w100"
                        value={
                          product.priceHQ
                        }
                        onChange={formik.handleChange(
                          `products[${index}].priceHQ`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].priceHQ`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.priceHQ &&
                      formik.errors
                        .products?.[
                        index
                      ]?.priceHQ ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].priceHQ
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row pb-4">
                  <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                    <label
                      className="me-3 w30 "
                      htmlFor={t(
                        "gia_tien_vn"
                      )}>
                      {t("gia_tien_vn")}
                    </label>
                    <div className="w70">
                      <input
                        type="text"
                        placeholder={t(
                          "vl_nhapso"
                        )}
                        className="input_custom w100"
                        value={
                          product.priceVN
                        }
                        onChange={formik.handleChange(
                          `products[${index}].priceVN`
                        )}
                        onBlur={formik.handleBlur(
                          `products[${index}].priceVN`
                        )}
                      />
                      {formik.touched
                        .products?.[
                        index
                      ]?.priceVN &&
                      formik.errors
                        .products?.[
                        index
                      ]?.priceVN ? (
                        <div className="error mb-3">
                          {
                            formik
                              .errors
                              .products[
                              index
                            ].priceVN
                          }
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4"></div>
                </div>
              </div>
            )
          )}
          <hr
            className="text-white my-5"
          />
          <div className="py-4 mt-5">
            <div className="mt-5">
              <h3 className="mb-2" style={{fontSize:"60px"}}>
                {t("lua_chon_cua_hang")}
              </h3>
              <h5 className="mb-5">
                {t("chon_nen_tang")}
              </h5>
              <h3 className="py-4">
                {t("online")}
              </h3>
              <div className="d-flex gap-30">
                {storeOnline.map(
                  (e, index) => (
                    <InputChoiceNT
                      title={e.title}
                      key={index}
                      defaultValue={
                        selectedOnline
                      }
                      img={e.img}
                      type="checkbox"
                      value={e.value}
                      onChange={(
                        event
                      ) => {
                        const valueArray =
                          Array.isArray(
                            formik
                              .values
                              .storeOnline
                          )
                            ? [
                                ...formik
                                  .values
                                  .storeOnline,
                              ]
                            : [];
                        if (
                          event.target
                            .checked
                        ) {
                          if (
                            !valueArray.includes(
                              event
                                .target
                                .value
                            )
                          ) {
                            valueArray.push(
                              event
                                .target
                                .value
                            );
                          }
                        } else {
                          const index =
                            valueArray.indexOf(
                              event
                                .target
                                .value
                            );
                          if (
                            index > -1
                          ) {
                            valueArray.splice(
                              index,
                              1
                            );
                          }
                        }
                        formik.setFieldValue(
                          "storeOnline",
                          valueArray
                        );
                        setSelectedOnline(
                          valueArray
                        );
                        console.log(
                          valueArray
                        );
                      }}
                      checked={
                        Array.isArray(
                          formik.values
                            .storeOnline
                        ) &&
                        formik.values.storeOnline.includes(
                          e.value
                        )
                      }
                    />
                  )
                )}
              </div>
              <h3 className="py-4">
                {t("offline")}
              </h3>
              <div className="d-flex flex-wrap gap-30">
                {storeOffline.map(
                  (e, index) => (
                    <InputChoiceNT
                      title={e.title}
                      key={index}
                      defaultValue={
                        selectedOffline
                      }
                      img={e.img}
                      type="checkbox"
                      value={e.value}
                      onChange={(
                        event
                      ) => {
                        const valueArray =
                          Array.isArray(
                            formik
                              .values
                              .storeOffline
                          )
                            ? [
                                ...formik
                                  .values
                                  .storeOffline,
                              ]
                            : [];
                        if (
                          event.target
                            .checked
                        ) {
                          if (
                            !valueArray.includes(
                              event
                                .target
                                .value
                            )
                          ) {
                            valueArray.push(
                              event
                                .target
                                .value
                            );
                          }
                        } else {
                          const index =
                            valueArray.indexOf(
                              event
                                .target
                                .value
                            );
                          if (
                            index > -1
                          ) {
                            valueArray.splice(
                              index,
                              1
                            );
                          }
                        }
                        formik.setFieldValue(
                          "storeOffline",
                          valueArray
                        );
                        setSelectedOffline(
                          valueArray
                        );
                        console.log(
                          valueArray
                        );
                      }}
                      checked={
                        Array.isArray(
                          formik.values
                            .storeOffline
                        ) &&
                        formik.values.storeOffline.includes(
                          e.value
                        )
                      }
                    />
                  )
                )}
              </div>
            </div>

            <hr
              className="bg-white"
              style={{
                paddingTop: "1px",
              }}
            />
            <div className="mt-5 mb-5">
              <h1 style={{fontSize:"60px"}}>{t("lich_trinh")}</h1>
              <p>
                {t(
                  "vui_long_nhap_khoan_tg"
                )}
              </p>
              <RangePicker
                className=" custom-range-picker bg-dark text-white w25"
                format="YYYY-MM-DD"
                placeholder={[
                  t(
                    "thoi_gian_bat_dau"
                  ),
                  t(
                    "thoi_gian_ket_thuc"
                  ),
                ]}
                onChange={(
                  value,
                  dateString
                ) => {
                  console.log(
                    "Formatted Selected Time: ",
                    dateString
                  );
                  formik.setFieldValue(
                    "startDate",
                    dateString[0]
                  );
                  formik.setFieldValue(
                    "endDate",
                    dateString[1]
                  );
                }}
                onOk={onOk}
              />
            </div>
            <div className="mt-5">
            {
                formik.values.email ==="" || formik.values.titleProject ==="" || formik.values.endDate==="" ||
                formik.values.startDate ==="" || formik.values.nameCompany===""
                || formik.values.phone==="" || formik.values.typeCompanyProduct===""
                || formik.values.phutrach ==="" ? (
                <p className="warning-box mb-0">
                  <div className="d-flex align-items-center">
                    <CiWarning
                      style={{
                        color:
                          "#FF0099",
                      }}
                      className="me-3"
                    />
                    <span className="d-block">
                      {t("chon_mot_so")}
                    </span>
                  </div>
                </p>
                ):""
              }
              <div className="d-flex gap-3 mt-3">
                <button
                  style={{
                    width: "15%",
                  }}
                  className="btn_submit"
                  type="submit"
                  onClick={() => {
                    setType(
                      "Bản chính"
                    );
                  }}>
                  {t("gui_don")}
                </button>
                <button
                  style={{
                    width: "15%",
                  }}
                  className="btn_note"
                  type="submit"
                  onClick={() => {
                    setType("Bản nháp");
                  }}>
                  {t("luu_nhap")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
