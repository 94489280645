import React, { useEffect } from "react";
import mypage from "../images/mypage.png";
import { IoReload } from "react-icons/io5";
import moment from 'moment';
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { Link } from "react-router-dom";
import { getAllLive } from "../features/live/liveSlice";
import { getAllExprience } from "../features/registerExprience/resExpriSlice";
import { getAllPrice } from "../features/createPrice/createPriceSlice";
import { getAllSale } from "../features/registerSale/resSaleSlice";
import { getAllPublic } from "../features/publicProduct/publicProductSlice";
import { getAllMaketing } from "../features/maketing/maketingSlice";
import { getNamecompany } from "../features/company/companySlice";
export default function MyPage({ t }) {
  const dispatch = useDispatch()
  const auth = useSelector(state=>state.auth.user)
  useEffect(()=>{
    dispatch(getAllExprience())
    dispatch(getAllPrice())
    dispatch(getAllSale())
    dispatch(getAllLive())
    dispatch(getAllPublic())
    dispatch(getAllMaketing())
  },[])
  useEffect(() => {
    if (auth?.idName && auth?.email) {
      const data = {
        idName: auth.idName,
        email: auth.email,
      };
      dispatch(getNamecompany(data));
    }
  }, [auth, dispatch]);

  const company = useSelector(state=>state.company.idcompany)
  // dk trải nghiệm
  const allEx = useSelector(state=>state.registerPri.resExpri.getAll)
  const IdEx = allEx && allEx?.filter(e=>e.registerBy?._id===auth?._id)
  
  const allprice = useSelector(state=>state.price.price.getAll)
  const IdPrice = allprice && allprice?.filter(e=>e.registerBy?._id===auth?._id)

  const allSale = useSelector(state=>state.registerSale.resSale.getAll)
  const IdSale = allSale && allSale?.filter(e=>e.registerBy?._id===auth?._id)

  const allLive = useSelector(state=>state.live.live.newLive)
  const IdLive = allLive && allLive?.filter(e=>e.registerBy?._id===auth?._id)

  const allPublic = useSelector(state=>state.publicProduct.publicProduct.getAll)
  const IdPublic = allPublic && allPublic?.filter(e=>e.registerBy?._id===auth?._id)

  const allMaketing = useSelector(state=>state.maketing.maketing.getAll)
  const IdMaketing = allMaketing && allMaketing?.filter(e=>e.registerBy?._id===auth?._id)

  console.log(IdEx,IdPrice,IdSale,IdLive,IdPublic,IdMaketing)  
  return (
    <section className="container py-5 d-flex flex-column gap-5 text-white">
      <div className="mt-5">
        <h1 style={{ fontSize: "70px" }}>{t("trang_cua_toi")}</h1>
      </div>
      <div className="d-flex gap-30 align-items-center">
        <img src={company?.company?.images[0]?.url} width={100} alt="" style={{borderRadius:"50%"}} />
        <div className="">
          <h3 style={{ fontSize: "50px" }}>{company?.company?.titleCompany}</h3>
          <p style={{ fontSize: "24px" }}>{t("tham_gia_day")} {`${moment(company?.company?.createdAt).format('DD/MM/YYYY')}`}</p>
        </div>
      </div>
      <div className="">
        <div className="d-flex align-items-center justify-content-between">
          <h3>{t("thuongh")}</h3>
          <Link to="/manager-stores" className="input_custom">
            {t("ql_cua_hang")}
          </Link>
        </div>
        <div className="row py-2">
          <div className="col-4 d-flex align-items-center gap-30">
            <p>{t("ten_thuong_hieu")}</p>
            <p>{company?.company?.titleCompany}</p>
          </div>
          <div className="col-4 d-flex align-items-center gap-30">
            <p>{t("sdt")}</p>
            <p>{company?.company?.phone}</p>
          </div>
          <div className="col-4 d-flex align-items-center gap-30">
            <p>{t("email")}</p>
            <p>{company?.company?.email}</p>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 d-flex align-items-center gap-30">
            <p>{t("linh_vuc")}</p>
            <p>{company?.company?.linhvuc}</p>
          </div>
          <div className="col-4 d-flex align-items-center gap-30">
            <p>{t("nguoi_phu_trach")}</p>
            <p>{company?.company?.phutrach}</p>
          </div>
          <div className="col-4 d-flex align-items-center gap-30">
            <p>{t("name")}</p>
            <p>{company?.company?.idName}</p>
          </div>
        </div>
      </div>
      <hr className="bg-white" />
      <div className="">
        <div className="col-lg-6 col-md-12" style={{ zIndex: 1 }}>
          <h1 className=" display-4" style={{ fontSize: "50px",fontWeight:"500" }}>
            {t("ql_du_an")}
          </h1>
          <p
            style={{ fontSize: "40px" }}
            className="py-4 fs-5"
            dangerouslySetInnerHTML={{
              __html: t("time_luu_tru"),
            }}
          ></p>
        </div>
      </div>
      <hr className="bg-white" />
      <div className="accordion" id="accordionExample">
        {IdEx && (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseIdEx"
                aria-expanded="true"
                aria-controls="collapseIdEx"
              >
                {t("ten_du_an")}
              </button>
            </h2>
            <div
              id="collapseIdEx"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("nd_dang_ky")}</strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">
                      {t("thoi_gian_mong_muon")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("tien_do")}</strong>
                  </div>
                </div>
                {IdEx.map((item, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-4">
                        <p>{t("dang_ky_trai_nghiem")}</p>
                      </div>
                      <div className="col-4">
                        <p>
                          {item.schedule?.startDate && item.schedule?.endDate
                            ? `${moment(item.schedule.startDate).format('DD/MM/YYYY')} ~ ${moment(item.schedule.endDate).format('DD/MM/YYYY')}`
                            : 'N/A'}
                        </p>
                      </div>
                      <div className="col-4 d-flex gap-30">
                        <p>{t("hoan_thanh")}</p>
                        <Link to={`/manager-register/${item._id}`}>{t("xem_kq")}</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {IdPrice && (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseIdPrice"
                aria-expanded="false"
                aria-controls="collapseIdPrice"
              >
                {t("ten_du_an")}
              </button>
            </h2>
            <div
              id="collapseIdPrice"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("nd_dang_ky")}</strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">
                      {t("thoi_gian_mong_muon")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("tien_do")}</strong>
                  </div>
                </div>
                {IdPrice.map((item, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-4">
                        <p>{t("bao_gia")}</p>
                      </div>
                      <div className="col-4">
                        <p>
                          {item.schedule?.startDate && item.schedule?.endDate
                            ? `${moment(item.schedule.startDate).format('DD/MM/YYYY')} ~ ${moment(item.schedule.endDate).format('DD/MM/YYYY')}`
                            : 'N/A'}
                        </p>
                      </div>
                      <div className="col-4 d-flex gap-30">
                        <p>{t("hoan_thanh")}</p>
                        <Link to={`/manager-bao-gia/${item._id}`}>{t("xem_kq")}</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {IdLive && (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseIdLive"
                aria-expanded="false"
                aria-controls="collapseIdLive"
              >
                {t("ten_du_an")}
              </button>
            </h2>
            <div
              id="collapseIdLive"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("nd_dang_ky")}</strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">
                      {t("thoi_gian_mong_muon")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("tien_do")}</strong>
                  </div>
                </div>
                {IdLive.map((item, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-4">
                        <p>{t("live_commerce")}</p>
                      </div>
                      <div className="col-4">
                        <p>
                          {item.schedule?.startDate && item.schedule?.endDate
                            ? `${moment(item.schedule.startDate).format('DD/MM/YYYY')} ~ ${moment(item.schedule.endDate).format('DD/MM/YYYY')}`
                            : 'N/A'}
                        </p>
                      </div>
                      <div className="col-4 d-flex gap-30">
                        <p>{t("hoan_thanh")}</p>
                        <Link to={`/manager-live/${item._id}`}>{t("xem_kq")}</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {IdPublic && (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseIdPublic"
                aria-expanded="false"
                aria-controls="collapseIdPublic"
              >
                {t("ten_du_an")}
              </button>
            </h2>
            <div
              id="collapseIdPublic"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("nd_dang_ky")}</strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">
                      {t("thoi_gian_mong_muon")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("tien_do")}</strong>
                  </div>
                </div>
                {IdPublic.map((item, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-4">
                        <p>{t("cong_bo_san_pham_header")}</p>
                      </div>
                      <div className="col-4">
                        <p>
                          {item.createdAt
                            ? `${moment(item.createdAt).format('DD/MM/YYYY')}`
                            : 'N/A'}
                        </p>
                      </div>
                      <div className="col-4 d-flex gap-30">
                        <p>{t("hoan_thanh")}</p>
                        <Link to={`/manager-public-product/${item._id}`}>{t("xem_kq")}</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {IdMaketing && (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseIdMaketing"
                aria-expanded="false"
                aria-controls="collapseIdMaketing"
              >
                {t("ten_du_an")}
              </button>
            </h2>
            <div
              id="collapseIdMaketing"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="row">
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("nd_dang_ky")}</strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">
                      {t("thoi_gian_mong_muon")}
                    </strong>
                  </div>
                  <div className="col-4">
                    <strong className="d-block pb-3">{t("tien_do")}</strong>
                  </div>
                </div>
                {IdMaketing.map((item, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-4">
                        <p>{t("influencer_marketing_header")}</p>
                      </div>
                      <div className="col-4">
                        <p>
                          {item.schedule?.startDate && item.schedule?.endDate
                            ? `${moment(item.schedule.startDate).format('DD/MM/YYYY')} ~ ${moment(item.schedule.endDate).format('DD/MM/YYYY')}`
                            : 'N/A'}
                        </p>
                      </div>
                      <div className="col-4 d-flex gap-30">
                        <p>{t("hoan_thanh")}</p>
                        <Link to={`/manager-maketing/${item._id}`}>{t("xem_kq")}</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
