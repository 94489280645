import React, { useState } from "react";
import { storeOffline, storeOnline } from "../data/data";
import InputChoiceNT from "../components/InputChoiceNT";
import { t } from "i18next";
import { Link } from "react-router-dom";

export default function ManagerStore({ t }) {
  const [selectedChoiceNT, setSelectedChoiceNT] = useState("");
  return (
    <section className="text-white container py-4">
      <div className="py-4">
        <h1>{t("ql_cua_hang")}</h1>
      </div>
      <div className="p-4">
        <div className="py-3">
          <h3>{t("them_cua_hang")}</h3>
        </div>
        <div className="mb-5">
          <h4 className="py-3">{t("online")}</h4>
          <div className="d-flex gap-30">
            {storeOnline.map((e, index) => (
              <InputChoiceNT
                title={e.title}
                key={index}
                img={e.img}
                type="checkbox"
                value={e.value}
                onChange={(e) => {
                  setSelectedChoiceNT(e.target.value);
                }}
              />
            ))}
          </div>
        </div>
        <div className="">
          <h4 className="py-3">{t("offline")}</h4>
          <div className="d-flex flex-wrap gap-30">
            {storeOffline.map((e, index) => (
              <InputChoiceNT
                title={e.title}
                key={index}
                img={e.img}
                type="checkbox"
                value={e.value}
                onChange={(e) => {
                  setSelectedChoiceNT(e.target.value);
                }}
              />
            ))}
          </div>
        </div>
        <div className="d-flex align-items-center mt-5">
          <Link to="/">
            <button className="input_custom">{t("bochon")}</button>
          </Link>
          <Link to="/manager-stores">
            <button className="btn_upload">{t("them")}</button>
          </Link>
        </div>
      </div>
    </section>
  );
}
