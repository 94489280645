import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/on-1.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { login } from "../features/users/userSlice";
import live from "../images/live.jpg";
import tiktok from "../images/tiktok.png";
import ins from "../images/ins.png";
import { toast } from "react-toastify";
export default function Login({ t }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userSchema = Yup.object().shape({
    idName: Yup.string().required(t("yc_id")),
    password: Yup.string().required(t("yc_mk")),
  });
  const formik = useFormik({
    initialValues: {
      idName: "",
      password: "",
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch("https://api.viety.net/api/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const data = await response.json();

        if (!response.ok) {
          if (data.message === "Mật khẩu sai") {
            toast.error(t("mks"));
          } else toast.error(t("idKtt"));
          return;
        }
        toast.success(t("login"));
        dispatch(login(values));
        setTimeout(() => {
          navigate("/");
        }, 300);
        console.log(data);
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again.");
      }
    },
  });
  const userState = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userState !== null) {
      navigate("/");
    }
  }, [userState]);
  return (
    <>
      <div
        className="container py-5 mt-5"
        style={{
          position: "relative",
          zIndex: 10000,
        }}
      >
        <div className="text-white py-5 mx-auto" style={{ width: "500px" }}>
          <div className="text-center">
            <img src={logo} width="400px" alt="" />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label htmlFor="exampleInputEmail1" className="form-label">
                ID
              </label>
              <input
                type="text"
                className="form-control input_custom"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={formik.values.idName}
                onChange={formik.handleChange("idName")}
                onBlur={formik.handleBlur("idName")}
              />
              <div className="error mb-3">
                {formik.touched.idName && formik.errors.idName ? (
                  <div>{formik.errors.idName}</div>
                ) : null}
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control input_custom"
                id="exampleInputPassword1"
                value={formik.values.password}
                onChange={formik.handleChange("password")}
                onBlur={formik.handleBlur("password")}
              />
              <div className="error mb-3">
                {formik.touched.password && formik.errors.password ? (
                  <div>{formik.errors.password}</div>
                ) : null}
              </div>
            </div>
            <div
              className="d-flex mb-3"
              style={{
                fontSize: "12px",
              }}
            >
              <Link to="/register" className="text-white d-block">
                {t("dk_tv")}
              </Link>
              <p className="mb-0 mx-2"> | </p>
              <Link to="/forgot-id" className="text-white d-block">
                {t("quenID")}
              </Link>
              <p className="mb-0 mx-2"> / </p>
              <Link to="/forgot-pass" className="text-white d-block">
                {t("qmk")}
              </Link>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn_submit"
                style={{
                  zIndex: 99999999,
                }}
              >
                {t("login")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: "30px",
          zIndex: 9999,
        }}
      >
        <div>
          <img src={ins} alt="" />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "-240px",
            right: "20px",
          }}
        >
          <img src={tiktok} alt="" />
        </div>
      </div>
      <div>
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: "10px",
            zIndex: 9999,
          }}
        >
          <img src={live} alt="" />
        </div>
      </div>
    </>
  );
}
