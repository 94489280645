import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resources from "./translation";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "ko", // Ngôn ngữ mặc định là tiếng Hàn
    detection: {
      order: ["localStorage", "cookie", "path", "htmlTag", "subdomain"], // Thay đổi thứ tự để ưu tiên localStorage
      caches: ["localStorage"], // Lưu vào localStorage
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
