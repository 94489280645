import React, { useEffect, useState } from "react";
import { Table, Select, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getAllMaketing,
  updateProcessMaketing,
} from "../features/maketing/maketingSlice";

const { Option } = Select;
const { Search } = Input;

export default function AdminRegisterMaketing({ t }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const columns = [
    { title: "No.", dataIndex: "key" },
    {
      title: t("lich_trinh"),
      dataIndex: "schedule",
    },
    {
      title: t("tien_trinh"),
      dataIndex: "process",
      filters: [
        { text: t("cho"), value: "Chờ" },
        {
          text: t("dang_tien_hanh"),
          value: "Đang tiến hành",
        },
        {
          text: t("hoan_thanh"),
          value: "Hoàn thành",
        },
      ],
      onFilter: (value, record) => {
        const processValue = record.process.props.defaultValue;
        return processValue ? processValue.includes(value) : false;
      },
    },
    {
      title: t("ten_du_an"),
      dataIndex: "titleProject",
    },
    {
      title: t("sdt"),
      dataIndex: "phone",
    },
    {
      title: t("mail"),
      dataIndex: "email",
    },
    {
      title: t("ten_sp"),
      dataIndex: "titleProduct",
    },
    {
      title: t("loai_sp"),
      dataIndex: "typeProduct",
    },
    {
      title: t("trong_luong"),
      dataIndex: "trongluong",
    },
    { title: t("hsd"), dataIndex: "HSD" },
    {
      title: t("hoa_hong"),
      dataIndex: "hoahong",
    },
    {
      title: t("gia_tien_hq"),
      dataIndex: "priceHQ",
    },
    {
      title: t("gia_tien_vn"),
      dataIndex: "priceVN",
    },
    {
      title: t("lich_live"),
      dataIndex: "live",
    },
  ];
  useEffect(() => {
    dispatch(getAllMaketing());
    window.scroll(0, 0);
  }, [dispatch]);

  const res = useSelector((state) => state.maketing.maketing.getAll);

  const handleOnchangeOrder = (id, value) => {
    const data = {
      _id: id,
      status: value,
    };
    dispatch(updateProcessMaketing(data));
    setTimeout(() => {
      dispatch(getAllMaketing());
    }, 300);
  };

  useEffect(() => {
    if (res) {
      const formattedData = res.map((item, index) => ({
        key: index + 1,
        schedule: `${moment(item?.schedule?.startDate).format(
          "DD/MM/YYYY"
        )} ~ ${moment(item?.schedule?.endDate).format("DD/MM/YYYY")}`,
        process: (
          <Select
            style={{ width: "130px" }}
            defaultValue={item?.process}
            onChange={(value) => handleOnchangeOrder(item?._id, value)}
          >
            <Option value="Chờ">{t("cho")}</Option>
            <Option value="Đang tiến hành">{t("dang_tien_hanh")}</Option>
            <Option value="Hoàn thành">{t("hoan_thanh")}</Option>
          </Select>
        ),
        titleProject: item?.titleProject,
        phone: item?.registerBy?.mobile,
        email: item?.registerBy?.email,
        titleProduct: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{` ${index + 1}: ${e?.titleProduct}`}</p>
            ))}
          </>
        ),
        typeProduct: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{`${index + 1}: ${e?.typeProduct}`}</p>
            ))}
          </>
        ),
        trongluong: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{`${index + 1}: ${e?.trongluong}`}</p>
            ))}
          </>
        ),
        HSD: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{` ${index + 1}: ${e?.HSD}`}</p>
            ))}
          </>
        ),
        hoahong: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{`${index + 1}: ${e?.hoahong}`}</p>
            ))}
          </>
        ),
        priceHQ: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{`${index + 1}: ${e?.priceHQ}`}</p>
            ))}
          </>
        ),
        priceVN: (
          <>
            {item?.infoProduct?.map((e, index) => (
              <p>{`${index + 1}: ${e?.priceVN}`}</p>
            ))}
          </>
        ),
        live: `${moment(item?.schedule?.live).format("DD/MM/YYYY")}`,
      }));
      setData(formattedData);
    }
  }, [res]);

  const handleSearch = (value) => {
    setSearchText(value.toLowerCase());
  };

  const filteredData = data.filter(
    (item) =>
      item.titleProject.toLowerCase().includes(searchText) ||
      item.titleProduct.toLowerCase().includes(searchText) ||
      item.typeProduct.toLowerCase().includes(searchText)
  );

  return (
    <div className="container py-5">
      <div className="py-5 text-white">
        <h1>{t("influencer_marketing_header")}</h1>
      </div>
      <div className="mt-3 mb-3">
        <Search
          placeholder="Tìm kiếm theo tên dự án, tên sản phẩm, loại sản phẩm"
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          enterButton
        />
      </div>
      <div className="mt-5">
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={filteredData}
        />
      </div>
    </div>
  );
}
