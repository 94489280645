import React from "react";

export default function InputLableCustom({
  type,
  value,
  onChange,
  onBlur,
  title,
  defaultValue,
  id,
  placeholder,
}) {
  return (
    <>
      <div className="d-flex align-items-center">
        <label className="me-3 w30" htmlFor="startTime">
          {title}
        </label>
        <div className="w70">
          <input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            defaultValue={defaultValue}
            id={id}
            placeholder={placeholder}
            className="input_custom w75"
          />
        </div>
      </div>
    </>
  );
}
