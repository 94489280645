const resources = {
  ko: {
    translation: {
      login:"성공적으로 로그인",
      kq_dg:"평가 결과",
      link_dg:"리뷰 링크",
      link_kq:"결과 링크",
      check:"보다",
      edit:"편집하다",
      linh_vuc:"필드",
      save:"저장되었습니다. 마이페이지에서 진행상황을 확인해주세요",
      them: "추가",
      ntctt:"모든 정보를 입력해주세요",
      dang_gui_OTP:"OTP가 전송되고 있습니다. 잠시만 기다려주세요!",
      dang_ky: "신청서 제출",
      bao_gia: "실시간 견적",
      trang_cua_toi: "마이 페이지",
      vdn: "숏폼 컨텐츠",
      about: "기업 소개서",
      xoa_san_pham:"제품 삭제",
      login: "로그인",
      viet_nam_thi_truong: "K뷰티의 새로운 주력마켓 베트남!",
      cung_viety: "비엣티와 <br /> 수출을 함께 하세요!",
      nhan_bao_gia: "실시간 견적 받기",
      xuat_khau_k_beauty: "베트남에 한번 가보지 않고도,</br> K-뷰티 수출 성공!",
      de_dang_trao_doi:
        "비엣티와ㅏ 함께 한국 사무실에서도 손쉽게! </br> k뷰티를 수출해보세요.",
      lien_ket_voi_115_nguoi:
        "베트남 국내 1위 SNS의 <br />  인기 인플루언서 115명 항시 제휴!",
      chi_hop_tac:
        "팔로워 10,000명 이상의 인기 인플루언서만을 선별하여 제휴하여 <br /> 강력한 효과를 자랑합니다.",
      thong_tin_dich_vu: "서비스 안내",
      cong_bo_san_pham: "베트남 내 위생신고",
      logo:"심벌 마크",
      tien_hanh_xuat_khau:
        "수출을 진행하기 위해 당사가 귀하를 대신하여 제품 신고 및 공고에 대한 필수 절차를 수행해 드립니다. 우리는 현지에서 제품 발표 절차를 신고하고 수행할 것이며, 이는 베트남에서 제품을 직접 판매하는 구매자나 사업체를 통해 이루어져야 합니다",
      hoat_dong_trai_nghiem: "테스터 운영",
      neu_khong_chac:
        "아직 내 제품이 베트남에서 경쟁력있는 상품인지 확신이 없다면? 테스터를 운영해보세요. 현지인으로만 이루어진 테스터 위탁운영으로 내 제품의 경쟁력을 알아보세요.",
      ban_hang_ky_gui: "판매 / 입점 위탁",
      chung_toi_uy_thac:
        "베트남 내  온,오프라인 판매를 위탁해드려요.베트남 현지에서 실시간으로 엄선하는 플랫폼을 통해 제품을 판매해 보세요.",
      influencer_marketing: "인플루언서 마케팅",
      tham_gia_tiep_thi:
        "베트남 국내 이용자 1위 SNS앱의 인기 인플루언서와 직접 연계하여 인플루언서 마케팅에 참여해보세요.",
      live_commerce: "라이브 커머스",
      tien_hanh_live:
        "베트남 국내 시청자 1위 매체를 통해 라이브 커머스를 진행해 보세요. 현지 인기 쇼호스트 들을 통해 실시간으로 판매됩니다.",
      bao_gia_tai_thoi_diem: "실시간 견적",
      hay_nhan_bao_gia:
        "견적부터 받고 문의 하자! 실시간 견적으로 문의 없이도 AI가 대략적인 견적을 내드려요.",
      nguoi_dai_dien: "대표자 : 이재석  |  개인정보 관리책임자 : 이재석",
      tel: " Tel. 064-725-5413 | 주소- 제주특별자치도 제주시 천수로6길 25, 2층 1011호 | e mail - rheejaeseok@gmail.com",
      so_dkkd:
        " 사업자등록번호 : 458-34-00543 | 통신판매등록번호 : 0000-서울강남-0000",
      dieu_khoan: "이용약관 개인정보 처리방침 이메일주소무단수집거부",
      dang_ky_trai_nghiem: "테스터 신청",
      cong_bo_san_pham_header: "위생신고",
      ban_hang_ky_gui_header: "판매/입점 위탁",
      influencer_marketing_header: "인플루언서 마케팅",
      live_commerce_header: "라이브커머스",
      xin_chao: "안녕하세요,",
      ban_muon_dang_ky:
        "베트남 현지 테스터를 신청하고 싶으신가요? 스터 모집에 필요한 기본 정보를 입력 해주세요.",
      truy_xuat: "불러오기",
      truy_xuat_thong_tin: "*실시간 견적에 입력하신 상품 정보를 불러옵니다. ",
      thong_tin_du_an: "프로젝트 정보",
      ten_du_an: "프로젝트 명",
      nhap_thong_tin_sp: "상품 정보 입력",
      them_san_pham: "상품 추가 +",
      thong_tin_thanh_vien:"회원정보",
      thong_tin_sp: "상품 정보",
      thong_tin_sp1: "상품 정보",
      ten_thuong_hieu: "회사 이름",
      trong_luong: "상품 무게",
      loai_sp: "상품 종류",
      the_loai: "조직",
      theloai_:"질감(액체, 농축액, 젤, 왁스...)",
      ten_sp: "상품 명",
      them_ha: "상품 사진",
      i4_muctieu: "타겟 정보 입력",
      choise:"원하는 현지 매체를 선택해 주세요.",
      vui_long_chon:
        "원하는 타겟정보를 선택해 주세요. 상품 별로 타겟이 다르다면 각각 다른 신청서에 작성해주세요.",
      nhom_tuoi: "대상 연령대*",
      treem: "유아",
      u: "대",
      gioitinh: "대상 성별*",
      nu: "여",
      nam: "남",
      khong_phan_biet: "무관",
      quy_mo_quy_pham: "모집 규모 및 범위",
      vui_long_nhap: "모집을 원하는 인원수와 범위를 입력해 주세요.",
      so_luong: "인원 수* ",
      pham_vi: "체험범위*",
      review: "체험 + SNS 리뷰",
      lich_trinh: "희망 일정",
      vui_long_nhap_khoan_tg: "진행을  원하시는 일정을 입력해 주세요",
      thoi_gian_bat_dau: "00년 00월 00일",
      thoi_gian_ket_thuc: "00년 00월 00일",
      chon_mot_so:
        "아직 채워지지 않은 항목이 있어요. 모든 항목을 기입 후 제출해 주세요.",
      gui_don: "신청서 제출하기",
      luu_nhap: "임시 저장",
      gia_cai: "가격/개",
      hsd: "유통기한",
      hinh_nen_trang: "누끼 사진",
      tai_len: "업로드",
      i4_thuonghieu: "브랜드 정보",
      ten_cty: "회사 명",
      sdt: "연락처",
      nguoi_phu_trach: "담당자 명",
      mail: "이메일",
      gpkd: "사업자 등록증",
      so_to: "수입신고 번호",
      lua_chon_cua_hang: "브랜드 입점 선택사항",
      chon_nen_tang: "*다중 선택 가능.",
      online: "온라인 매장",
      offline: "오프라인 매장",
      ban_muon_uy_quyen:
        "베트남 수출에 꼭 필요한 위생신고를 위탁진행하고 싶으신가요? 필요 정보를 입력해 주세요.",
      tai_lieu_can_thiet: "필요 서류",
      vui_long_tai_len: "*서류 파일은 모두 PDF로 업로드 부탁드립니다.",
      ten_tai_lieu: "서류 명",
      trang_thai: "처리 현황",
      dang_tai: "파일",
      phap_nhan: "법인등기",
      giay_uy_quyen: "위임장",
      giay_kinh_doanh: "사업자등록증",
      bang_thanh_phan: "전성분 표",
      xac_nhan_lsq: "본부 영사 확인서",
      giay_chung_nhan: "제조 판매 증명서",
      da_nhan: "수납완료",
      vui_long_chon_lv: "원하는 현지 매체를 선택해 주세요.",
      linh_vuc: "포스팅 분야",
      sl_follow: "인플루언서 팔로우 수",
      ban_quyen: "2차 저작권",
      vui_long_chon_pv: "매체에 노출 희망하는 범위를 정해 주세요",
      xh_don_gian: "단순 노출(약 1분 노출. 언급 없음)",
      gt_sx: "제품 소개 (제품을 약 5분 가량 소개)",
      nd_sd: "제품 활용 복합 컨텐츠 (타 제품과 함께 노출)",
      sd_sd_1: "제품 활용 단일 컨텐츠 (제품 단독 컨텐츠 1개 제작)",
      sl_mong_muon: "희망 횟수",
      nhap_sl_live: "방송 진행 희망 횟수를 적어주세요",
      sl_live: "방송 희망 횟수",
      dv_bao_gia:
        "비엣티의  실시간 AI 견적 서비스 입니다.수출을 원하시는 상품의 정보를 입력해주세요.",
      dv_bao_hanh: "진행하고자 하는 서비스",
      co_the_chon: "*원하는 서비스가 여러 개이신 경우, 복수체크해주세요.",
      tao_moi: "신규 작성",
      cua_hang_dai_ly: "입점 대행",
      hinh_thai: "입점 희망 형태",
      so_nguoi_tn: "인원 수",
      tham_gia: "가입",
      thuong_hieu: "입점 브랜드",
      nen_tang: "입점 플랫폼",
      vdtc: "일반 영상 컨텐츠",
      hatc: "일반 사진 컨텐츠",
      tong_danh_thu: "전체 매출",
      chao_mung_ban: "비엣티에 오신 것을 환영합니다.",
      cung_viety_xk_vn: "비엣티와 함께 베트남 수출을 경험하세요",
      ten: "이름*",
      ql_cua_hang: "계정 관리",
      nhap_lai: "재입력*",
      dk_tv: "가입신청",
      qmk: "비밀번호 찾기",
      tieptheo: "다음",
      ban_qmk: "비밀번호를 잃어버리셨나요?",
      nhap_ma: "인증번호",
      da_xm: "*인증되었습니다.",
      quenID: "아이디",
      ban_quenID: "아이디를 잃어버리셨나요?",
      vui_long_chu_y: "*아이디/비밀번호 찾기 시 사용되므로 오류에 주의하세요.",
      ID_da_xem: "조회된 ID",
      tai_khoan: "계정",
      doan: "조립",
      dogiadung: "합성식품",
      sach: "건강식품",
      mypham:"M화장품",
      tonghop:"인조",
      tptt:"일반음식",
      tpcn:"기능성식품",
      skincare: "피부 관리",
      chua_nhan: "미등록",
      gia: " FOB 가격",
      gia_tien_hq: "본사 가격",
      gia_tien_vn: "베트남 환율",
      vl_nhapso: "번호를 입력해주세요",
      hoa_hong: "장미",
      lich_live: "실시간 일정",
      tg_live: "실시간",
      dang_xuat: "로그 아웃",
      xn_otp: "OTP 확인",
      ql_du_an: "프로젝트 관리 ",
      time_luu_tru:
        "진행중이신 프로젝트를 확인하고 이어서 작성하세요. </br> *임시저장 내용 보관 기간 6개월",
      tiep_tuc_chinh_sua: "새로고침",
      tong_cong_3da: "합계 예 ",
      duan:" 프로젝트",
      nd_dang_ky: "신청 내용",
      thoi_gian_mong_muon: "희망 기간",
      kb_cb_sp: "위생신고 및 등기 위탁",
      hoan_thanh: "진행 완료",
      thieu_tl: "2개 서류 미접수",
      tien_do: "진행현황",
      xem_kq: "결과 보기",
      bosung_tl: "자료 보완하기",
      tham_gia_day: "가입",
      tg_lan_dau: "최초 입점일",
      sl_san_pham: "등록 상품 갯수",
      danh_thu_thang: "당월 매출",
      them_cua_hang: "추가 입점 요청",
      bochon: "취소",
      them: "추가",
      thoigian: "진행 기간 ",
      kq_du_an: "프로젝트 결과",
      loai_da: "프로젝트 종류",
      so_nguoi_xem: "시청자 수",
      hang_da_ban: "시청시간",
      tg_xem: "시청시간",
      hang_ton_kho: "남은 수량",
      luoc_share: "공유하기 수",
      ht_qb: "홍보 형태",
      cua_hang: "가게",
      hd_tg: "참가계약",
      kq_bao_gia: "견적결과",
      nd_bao_gia: "내용 인용",
      quy_mo: "규모",
      ql_san_pham: "제품 관리",
      toan_bo: "총",
      cap_nhap: "업데이트",
      yc_nhap_ten_da: "프로젝트 이름을 입력해야 합니다",
      yc_nhap_thuong_hieu: "브랜드 입력 필요",
      yc_nhap_ten_sp: "제품명을 입력해야 합니다",
      yc_nhap_loai_sp: "제품 유형을 입력해야 합니다",
      yc_nhap_trong_luong: "체중 입력이 필요합니다",
      yc_nhap_the_loai: "제품 구조 입력 요청",
      yc_nhap_so_luong: "수량을 입력해야 합니다",
      trolen: "이상",
      tn_rv_nb: "체험 + 내부 리뷰",
      tn_SNS: "체험 + SNS 리뷰",
      yc_nhap_ten_cty: "회사명을 입력해야 합니다",
      chua_dung_email: "잘못된 이메일 형식",
      yc_nhap_email: "이메일 항목이 필요합니다",
      yc_nhap_sdt: "전화번호를 입력해야 합니다",
      yc_nhap_npt: "담당자 입력요청",
      yc_nhap_gia_tien: "가격 입력 요청",
      yc_nhap_sl_nhap_khau: "수입수량 입력 필수",
      yc_chon_hang_sd: "사용등급 선택 필수",
      yc_nhap_FOB: "FOB 항목이 필요합니다",
      yc_nhap_tien_vn: "베트남 통화 가격 입력 요청",
      yc_nhap_tien_hq: "원화 가격 입력 요청",
      tiktok: "틱톡",
      ins: "인스타그램",
      fb: "페이스북",
      yt: "유튜브",
      lottemart: "롯데 마트",
      nhathuoc: "지역 약국",
      yc_nhap_hoa_hong: "수수료율을 입력해야 합니다",
      lamdep: "뷰티",
      doisong: "라이프 스타일",
      thoitrang: "패션",
      dadang: "다양성",
      muoi_nam: "1만~5만명",
      nam_muoi: "5만~10만명",
      muoi_2muoi: "10만~20만명",
      nammuoi_tram: "50만~100만명",
      tramtrolen: "100만 이상",
      thang: "1개월",
      thang3: "3개월",
      thang6: "6개월",
      khsd: "사용안함",
      xhdg: "단순 노출(약 1분 노출. 언급 없음)",
      gtsp: "제품 소개 (제품을 약 5분 가량 소개)",
      ndsd: "제품 활용 복합 컨텐츠 (타 제품과 함께 노출)",
      ndsd1: "제품 활용 단일 컨텐츠 (제품 단독 컨텐츠 1개 제작)",
      email_khong_hop: "이메일은 유효해야 합니다",
      yc_id: "ID가 필요합니다",
      yc_mk: "비밀번호를 입력해야 합니다",
      yc_ten: "이름을 입력해야 합니다",
      mk_khong_khop: "비밀번호 오류",
      yc_otp: "OTP 코드 입력이 필요합니다",
      otp_sai: "OTP 코드가 잘못되었습니다. 다시 입력해 주세요",
      haitram_nam: "20만~50만명",
      yc_trai_nghiem: "경험한 인원수를 입력해야 합니다",
      yc_sl_sp: "제품 수량을 입력해야 합니다",
      yc_gc: "가격/품목을 입력해야 합니다",
      ban_chua_chon_anh: "아직 사진을 선택하지 않았습니다",
      ban_chua_dn: "로그인되어 있지 않습니다",
      onlineee: "온라인",
      ban_chinh: "원래의",
      ntn: "체험단",
      oflineee: "오프라인",
      mieuta:
        "*숏폼 컨텐츠란? 숏폼 컨텐츠란 1분 내외의 짧은 세로형 영상 컨텐츠를 말합니다.",
      ban_nhap: "초안",
      tao_cu: "오래된 것을 창조하다",
      td_tong_the: "전체 진행상황  -  65%",
      tien_trinh: "프로세스",
      hinh_anh: "영상",
      cho: "기다리다",
      dang_tien_hanh: "과정에서",
      store_online: "온라인 스토어",
      store_offline: "오프라인 매장",
      dk_tc: "성공적으로 생성되었습니다",
      dk_tb: "생성 실패",
      bhkg:"위탁판매",
      upload_failed:"이미지 업로드 실패",
      sl:"제품 수량",
      name:"이름",
      sdtDN:"로그인 전화번호",
      cfPass:"비밀번호 확인",
      mk:"비밀번호",
      tv:"회원정보",
      mks:"얘기하는거같아",
      idKtt:"잘못된 이름",
      knk:"수입신고번호",
      thuongh:"입점브랜드"
    },
  },
  vi: {
    translation: {
      theloai_:"Kết cấu (lỏng, cô đặc, gel, sáp...)",
      knk:"Số tờ khai nhập khẩu",
      mks:"Mật khẩu không tồn tại",
      idKtt:"IdName sai",
      tv:"Thông tin thành viên",
      cfPass:"Confirm Password",
      mk:"Mật khẩu",
      sdtDN:"SĐT đăng nhập",
      choise:"vui lòng lựa chọn nền tảng mong muốn",
      login:"Đăng nhập thành công",
      kq_dg:"Kết quả đánh giá",
      link_dg:"Link đánh giá",
      link_kq:"Link kết quả",
      check:"Xem",
      edit:"Chỉnh sửa",
      linh_vuc:"Lĩnh vực",
      thong_tin_thanh_vien:"Thông tin thành viên",
      save:"Đã lưu. Vui lòng kiểm tra tiến trình trên Trang của tôi",
      dang_gui_OTP:"OTP đang được gửi, chờ trong giây lát !",
      upload_failed:"Tải ảnh thất bại",
      dk_tc: "Tạo thành công",
      dk_tb: "Tạo thất bại",
      store_online: "Store Online",
      store_offline: "Store Offline",
      cho: "Chờ",
      dang_tien_hanh: "Đang tiến hành",
      tien_trinh: "Tiến trình",
      hinh_anh: "Hình ảnh",
      td_tong_the: "Tiến độ tiến hành tổng thể  -  65%",
      ban_nhap: "Bản nháp",
      xoa_san_pham:"Xoá sản phẩm",
      mieuta:
        "*Video ngắn là? Video ngắn, nằm dọc màn hình, dài khoảng 1 phút.",
      vdn: "Video ngắn",
      vdtc: "Video tiêu chuẩn",
      hatc: "Hình ảnh tiêu chuẩn",
      oflineee: "Offline",
      ntn: "Nhóm trải nghiệm",
      onlineee: "Online",
      ban_chinh: "Bản chính",
      ban_chua_dn: "Bạn chưa đăng nhập",
      yc_gc: "Yêu cầu nhập giá/cái",
      yc_sl_sp: "Yêu cầu nhập số lượng sản phẩm",
      yc_trai_nghiem: "Yêu cầu nhập số người trải nghiệm",
      ban_chua_chon_anh: "Bạn chưa chọn ảnh",
      them: "Thêm",
      otp_sai: "Mã OTP sai. Vui lòng nhập lại",
      yc_otp: "Yêu cầu nhập mã OTP",
      mk_khong_khop: "Mật khẩu không khớp",
      yc_ten: "Yêu cầu nhập tên",
      yc_mk: "Yêu cầu nhập mật khẩu",
      email_khong_hop: "Email phải hợp lệ",
      yc_id: "Yêu cầu id",
      xhdg: "Xuất hiện đơn giản (Xuất hiện khoảng 1 phút, không đề cập đến)",
      gtsp: "Giới thiệu sản phẩm (giới thiệu sản phẩm khoảng 5 phút)",
      ndsd: "Nội dung sử dụng tổng hợp nhiều sản phẩm (Xuất hiện với các sản phẩm khác)",
      ndsd1:
        "Nội dung sử dụng duy nhất độc quyền 1 sản phẩm (sản xuất nội dung dành riêng cho sản phẩm)",
      thang: "1 tháng",
      thang3: "3 tháng",
      thang6: "6 tháng",
      khsd: "Không sử dụng",
      muoi_nam: "10k-50k người",
      nam_muoi: "50k-100k người",
      muoi_2muoi: "100k-200k người",
      nammuoi_tram: "500k-1M người",
      haitram_nam: "200k-500k người",
      tramtrolen: "Trên 1M người",
      lamdep: "Làm đẹp",
      doisong: "Đời sống",
      thoitrang: "Thời trang",
      dadang: "Đa dạng",
      lottemart: "Lotte Mart",
      nhathuoc: "Nhà thuốc",
      tiktok: "Tiktok Shop",
      ins: "Instagram",
      fb: "Facebook",
      yt: "Youtube",
      yc_nhap_hoa_hong: "Yêu cầu nhập phần trăm hoa hồng",
      yc_nhap_ten_cty: "Yêu cầu nhập tên công ty",
      chua_dung_email: "Chưa đúng định dạng email",
      yc_nhap_email: "Yêu cầu nhập email",
      yc_nhap_sdt: "Yêu cầu nhập số điện thoại",
      yc_nhap_npt: "Yêu cầu nhập người phụ trách",
      yc_nhap_gia_tien: "Yêu cầu nhập giá tiền",
      yc_nhap_sl_nhap_khau: "Yêu cầu nhập số lượng nhập khẩu",
      yc_chon_hang_sd: "Yêu cầu chọn hạng sử dụng",
      yc_nhap_FOB: "Yêu cầU nhập FOB",
      yc_nhap_tien_vn: "Yêu cầu nhập giá tiền Việt Nam",
      yc_nhap_tien_hq: "Yêu cầu nhập giá tiền Hàn Quốc",
      tn_rv_nb: "Trải nghiệm + review nội bộ",
      tn_SNS: "Trải nghiệm + SNS review",
      trolen: "trở lên",
      yc_nhap_ten_da: "Yêu cầu nhập tên dự án",
      yc_nhap_thuong_hieu: "Yêu cầu nhập thương hiệu",
      yc_nhap_ten_sp: "Yêu cầu nhập tên sản phẩm",
      yc_nhap_loai_sp: "Yêu cầu nhập loại sản phẩm",
      yc_nhap_trong_luong: "Yêu cầu nhập trọng lượng",
      yc_nhap_the_loai: "Yêu cầu nhập kết cấu sản phẩm",
      yc_nhap_so_luong: "Yêu cầu nhập số lượng",
      toan_bo: "Toàn bộ",
      cap_nhap: "Cập nhập",
      ql_san_pham: "Quản lý sản phẩm",
      quy_mo: "Quy mô",
      nd_bao_gia: "Nội dung báo giá",
      kq_bao_gia: "Kết quả báo giá",
      hd_tg: "Hợp đồng tham gia",
      cua_hang: "Cửa hàng",
      ht_qb: "Hình thái quảng bá",
      hang_ton_kho: "Hàng tồn kho",
      luoc_share: "Lượt chia sẽ",
      hang_da_ban: "Hàng đã bán",
      tg_xem: "Thời gian xem",
      sl:"Số lượng sản phẩm",
      so_nguoi_xem: "Số người xem",
      loai_da: "Loại dự án",
      logo:"Logo",
      kq_du_an: "Kết quả dự án",
      bochon: "Bỏ chọn",
      them: "Thêm",
      thoigian: "Thời gian",
      them_cua_hang: "Thêm cửa hàng",
      tong_danh_thu: "Tổng danh thu",
      nen_tang: "Nền tảng",
      danh_thu_thang: "Doanh thu tháng",
      sl_san_pham: "Số lượng sản phẩm",
      ql_cua_hang: "Quản lý tài khoản",
      tg_lan_dau: "Tham gia lần đầu",
      tham_gia_day: "Tham gia",
      ql_du_an: "Quản lý dự án",
      time_luu_tru:
        "Vui lòng kiểm tra dự án bạn đang thực hiện và tiếp tục nhập thông tin. <br /> *Thời gian lưu trữ nội dung nháp tạm thời: 6 tháng",
      tiep_tuc_chinh_sua: "Tiếp tục chỉnh sửa",
      tong_cong_3da: "Tổng cộng có ",
      duan:" dự án",
      nd_dang_ky: "Nội dung đăng ký",
      thoi_gian_mong_muon: "Khoảng thời gian",
      kb_cb_sp: "Khai báo và công bố sản phẩm",
      hoan_thanh: "Đã hoàn thành",
      thieu_tl: "Thiếu 2 tài liệu",
      tien_do: "Tiến độ",
      xem_kq: "Xem kết quả",
      bosung_tl: "Bổ xung tài liệu",
      xn_otp: "Xác nhận OTP",
      dang_xuat: "Đăng xuất",
      tg_live: "Thời gian live",
      lich_live: "Lịch live",
      hoa_hong: "Hoa hồng",
      gia_tien_vn: " Giá tiền VN",
      vl_nhapso: "Vui lòng nhập số",
      gia_tien_hq: "Giá tiền HQ",
      gia: "Giá FOB",
      chua_nhan: "Chưa nhận",
      skincare: "Skincare",
      doan: "Makeup",
      mypham:"Mỹ phẩm",
      tonghop:"Tổng hợp",
      ntctt:"Vui lòng nhập tất cả thông tin",
      tptt:"Thực phẩm thông thường",
      tpcn:"Thực phẩm chức năng",
      dogiadung: "Thực phẩm tổng hợp",
      sach: "Thực phẩm sức khoẻ",
      tai_khoan: "tài khoản",
      dang_ky: "Đăng ký",
      bao_gia: "Báo giá",
      trang_cua_toi: "Trang của tôi",
      about: "About Viety",
      login: "Login",
      viet_nam_thi_truong:
        "Việt Nam, <br /> thị trường chủ lực mới của K-Beauty!",
      cung_viety: " Cùng Viety <br /> tiến đến Việt Nam!",
      nhan_bao_gia: "Nhận báo giá ngay",
      xuat_khau_k_beauty:
        "Xuất khẩu K-beauty thành công! <br /> Dù chưa từng đến Việt Nam!",
      de_dang_trao_doi:
        "Dễ dàng trao đổi tại văn phòng Hàn Quốc với Viety! Hãy thử sức xuất khẩu K-beauty.",
      lien_ket_voi_115_nguoi:
        "Luôn liên kết với 115 người có tầm ảnh hưởng <br /> nhất thông qua SNS số 1 Việt Nam!",
      chi_hop_tac:
        "Chỉ hợp tác và tuyển chọn những người có tầm ảnh hưởng với hơn 10.000 người <br /> theo dõi do đó chúng tôi tự hào về những hiệu quả mạnh mẽ.",
      thong_tin_dich_vu: "Thông tin Dịch vụ",
      cong_bo_san_pham: "Công bố sản phẩm tại Việt Nam",
      tien_hanh_xuat_khau:
        "Để tiến hành xuất khẩu, chúng tôi sẽ thay bạn tiến hành các quy trình bắt buộc về khai báo và công bố sản phẩm. Chúng tôi sẽ khai báo và tiến hành thủ tục công bố sản phẩm tại địa phương, việc này phải được thực hiện thông qua buyer hoặc doanh nghiệp trực tiếp buôn bán sản phẩm tại Việt Nam.",
      hoat_dong_trai_nghiem: "Hoạt động trải nghiệm",
      neu_khong_chac:
        "Nếu không chắc sản phẩm của mình có cạnh tranh được ở thị trường Việt Nam hay không? Hãy tham gia hoạt động trải nghiệm sản phẩm. Tìm hiểu khả năng cạnh tranh sản phẩm của bạn bằng cách để người tại địa phương trực tiếp sử dụng, trải nghiệm sản phẩm của bạn.",
      ban_hang_ky_gui: "Bán hàng/ Ký gửi",
      chung_toi_uy_thac:
        "Chúng tôi ủy thác bán hàng Online và Offline tại Việt Nam.Bán sản phẩm của bạn thông qua các nền tảng được lựa chọn cẩn trọng tại Việt Nam.",
      influencer_marketing: "Influencer Marketing",
      tham_gia_tiep_thi:
        "Tham gia tiếp thị người có tầm ảnh hưởng (Influencer Marketing) bằng cách liên kết trực tiếp với những Influencer thông qua ứng dụng SNS số 1 Việt Nam về số lượng người dùng.",
      live_commerce: "Live commerce",
      bhkg:"Bán hàng ký gửi",
      tien_hanh_live:
        "Tiến hành Live commerce thông qua các phương tiện truyền thông có lượng người xem lớn nhất tại Việt Nam. Đây là hình thức bán hàng online theo thời gian thực thông qua những người live nổi tiếng ở địa phương.",
      bao_gia_tai_thoi_diem: "Báo giá tại thời điểm yêu cầu",
      hay_nhan_bao_gia:
        "Hãy nhận báo giá và hỏi đáp! AI cung cấp báo giá sơ bộ nhanh mà không cần bất kỳ yêu cầu nào.",
      nguoi_dai_dien:
        " Người đại diện: Jaeseok Rhee | Người quản lý thông tin cá nhân: Jaeseok Rhee",
      tel: " Tel. 064-725-5413 |Địa chỉ - Phòng 1011, tầng 2, 25 Cheonsu-ro 6-gil, Jeju-si, Jeju-do | e mail - rheejaeseok@gmail.com",
      so_dkkd:
        " Số ĐKKD : 458-34-00543 | Số đăng ký đặt hàng qua thư : 0000-Seoul Gangnam-0000",
      dieu_khoan:
        "Điều khoản sử dụng và từ chối thu thập địa chỉ email trái phép",
      dang_ky_trai_nghiem: "Đăng ký trải nghiệm",
      cong_bo_san_pham_header: "Công bố sản phẩm",
      ban_hang_ky_gui_header: "Bán hàng/Ký gửi",
      influencer_marketing_header: "Influencer Marketing",
      live_commerce_header: "Live Commerce",
      xin_chao: "Xin chào",
      ban_muon_dang_ky:
        "Bạn có muốn đăng ký thành người  <br /> trải nghiệm sản phẩm tại Việt Nam không? <br /> Vui lòng nhập các thông tin cơ bản được yêu <br /> cầu tại mục tuyển chọn.",
      truy_xuat: "Truy xuất",
      truy_xuat_thong_tin:
        " *Truy xuất thông tin sản phẩm đã nhập trong báo giá",
      thong_tin_du_an: "Thông tin dự án",
      ten_du_an: "Tên dự án",
      nhap_thong_tin_sp: "Nhập thông tin sản phẩm",
      them_san_pham: "Thêm sản phẩm +",
      thong_tin_sp: "Thông tin sản phẩm",
      thong_tin_sp1: "Thông tin sản phẩm",
      ten_thuong_hieu: "Tên công ty",
      trong_luong: "Trọng lượng",
      loai_sp: "Loại sản phẩm",
      the_loai: "Kết cấu",
      ten_sp: "Tên sản phẩm",
      them_ha: "Thêm hình ảnh",
      i4_muctieu: "Nhập thông tin mục tiêu",
      vui_long_chon:
        "Vui lòng chọn mục tiêu mong muốn. Nếu mục tiêu của mỗi sản phẩm khác nhau, vui lòng điền mẫu đăng ký riêng cho từng sản phẩm.",
      nhom_tuoi: "Nhóm tuổi*",
      treem: "Trẻ em",
      u: "Tuổi ",
      gioitinh: "Giới tính*",
      nu: "Nữ",
      nam: "Nam",
      khong_phan_biet: "Không phân biệt",
      quy_mo_quy_pham: "Quy mô phạm vi",
      vui_long_nhap:
        "Vui lòng nhập số lượng người bạn muốn tuyển chọn và phạm vi đăng bài trải nghiệm.",
      so_luong: "Số lượng*",
      pham_vi: "Phạm vi*",
      review: "체험 + SNS 리뷰",
      lich_trinh: "Lịch trình mong muốn",
      vui_long_nhap_khoan_tg:
        "Vui lòng nhập khoản thời gian bạn muốn tiến hành.",
      thoi_gian_bat_dau: "Thời gian bắt đầu",
      thoi_gian_ket_thuc: "Thời gian kết thúc",
      chon_mot_so:
        "Còn một số mục chưa điền. Vui lòng điền vào tất cả các mục và gửi.",
      gui_don: "Gửi đơn đăng ký",
      luu_nhap: "Lưu nháp",
      gia_cai: "Giá/Cái",
      hsd: "HSD",
      hinh_nen_trang: "Hình nền trắng",
      tai_len: "Tải lên",
      name:"Tên",
      i4_thuonghieu: "Thông tin thương hiệu",
      ten_cty: "Tên công ty",
      sdt: "Số điện thoại",
      thuongh:"Thương hiệu",
      nguoi_phu_trach: "Người phụ trách",
      mail: "Email",
      gpkd: "GPKD",
      so_to: "Số tờ",
      lua_chon_cua_hang: "Lựa chọn cửa hàng thương hiệu",
      chon_nen_tang: "Vui lòng lựa chọn nên tảng mong muốn",
      online: "Cửa hàng Online",
      offline: "Cửa hàng Offline",
      ban_muon_uy_quyen:
        " Bạn muốn ủy quyền tiến hành <br /> công bố sản phẩm vô cùng cần thiết trong <br /> quá trình xuất khẩu sang Việt Nam? <br /> Vui lòng nhập các thông tin cần thiết.",
      tai_lieu_can_thiet: "Tài liệu cần thiết",
      vui_long_tai_len:
        "*Vui lòng tải lên tất cả các tệp tài liệu dưới dạng PDF.",
      ten_tai_lieu: "Tên tài liệu",
      trang_thai: "Trạng thái xử lý",
      dang_tai: "Đăng tải",
      phap_nhan: "Đăng ký pháp nhân",
      giay_uy_quyen: "Giấy ủy quyền",
      giay_kinh_doanh: "Giấy đăng ký kinh doanh",
      bang_thanh_phan: "Bảng thành phần",
      xac_nhan_lsq: " Xác nhận của lãnh sự quán",
      giay_chung_nhan: "Giấy chứng nhận bán hàng/sản xuất",
      da_nhan: "Đã nhận",
      vui_long_chon_lv: "원하는 현지 매체를 선택해 주세요.",
      linh_vuc: "Lĩnh vực",
      sl_follow: "Số lượng người theo dõi",
      ban_quyen: "Bản quyền",
      vui_long_chon_pv: "Vui lòng chọn phạm vi",
      xh_don_gian: "단순 노출(약 1분 노출. 언급 없음)",
      gt_sx: "제품 소개 (제품을 약 5분 가량 소개)",
      nd_sd:
        " Nội dung sử dụng tổng hợp nhiều sản phẩm (Xuất hiện với các sản phẩm khác)",
      sd_sd_1: "제품 활용 단일 컨텐츠 (제품 단독 컨텐츠 1개 제작)",
      sl_mong_muon: "Số lần mong muốn",
      nhap_sl_live: "Vui lòng nhập số lần Live mong muốn",
      sl_live: "số lần Live",
      dv_bao_gia:
        " Dịch vụ báo giá AI của Viety. <br /> Vui lòng nhập thông tin sản phẩm <br /> bạn muốn xuất khẩu.",
      dv_bao_hanh: "Dịch vụ bạn muốn tiến hành",
      co_the_chon: "*Có thể chọn nhiều dịch vụ đồng thời.",
      tao_moi: "Tạo mới",
      tao_cu: "Tạo cũ",
      cua_hang_dai_ly: "Cửa hàng đại lý",
      hinh_thai: "Hình thái mong muốn",
      so_nguoi_tn: "Số người trải nghiệm",
      chao_mung_ban: "Chào mừng bạn đến với Viety.",
      cung_viety_xk_vn: "Cùng Viety trải nghiệm xuất khẩu Việt Nam!",
      ten: "Tên",
      nhap_lai: "Nhập lại *",
      dk_tv: "Đăng ký thành viên",
      qmk: "Quên mật khẩu",
      tieptheo: "Tiếp theo",
      ban_qmk: "Bạn quên mật khẩu",
      nhap_ma: "Nhập mã",
      da_xm: "Đã xác minh",
      quenID: "Quên ID",
      ban_quenID: "Bạn quên id",
      vui_long_chu_y: "*Vui lòng chú ý thông báo khi Quên ID / Mật khẩu.",
      ID_da_xem: "ID đã xem",
    },
  },
};

export default resources;
