import React, { useEffect } from 'react'
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { FaAngleDown } from "react-icons/fa";
import {
    delImg,
    resetImages,
    uploadImg,
  } from "../features/upload/uploadSlice";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from 'axios';
import { base_url } from '../utils/base_url';
import { config } from '../utils/axiosconfig';
import { useParams } from 'react-router-dom';
import { getIdcompany, updatecompany } from '../features/company/companySlice';
export default function MyInfoPage({t}) {
    const {id} = useParams()
    let userSchema = Yup.object().shape({
        titleCompany: Yup.string().required(t("yc_nhap_ten_cty")),
        phoneCompany: Yup.string().required(t("yc_nhap_sdt")),
        phutrach: Yup.string().required(t("yc_nhap_npt")),
        emailCompany: Yup.string()
            .email(t("chua_dung_email"))
            .required(t("yc_nhap_email")),
      });
      useEffect(() => {
        window.scroll(0, 0);
      }, []);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getIdcompany(id))
    },[id,dispatch])
    const imgState = useSelector((state) => state.upload.images);
    const auth = useSelector((state) => state.auth.user);
    const idCompany = useSelector(state=>state.company.id)
    const img = [];
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
          titleCompany: idCompany?.company?.titleCompany || "",
          linhvuc:idCompany?.company?.linhvuc ||  "Tổng hợp",
          phoneCompany: idCompany?.company?.phoneCompany || "",
          phutrach:idCompany?.company?.phutrach || "",
          images:idCompany?.company?.images || "",
          emailCompany: idCompany?.company?.emailCompany ||"",
            
          idName: auth?.idName,
          password: auth?.password,
          cfPassword: auth?.password,
          name: auth?.name,
          email: auth?.email,
          phone: auth?.mobile,
        },
        validationSchema: userSchema,
        onSubmit: async (values) => {
            console.log('Submitting values:', values);
            if(id){
                
                let a = {
                    id : id,
                    data :{
                        ...formik.values
                    }
                }
                dispatch(updatecompany(a))
                setTimeout(()=>{
                    formik.resetForm();
                    dispatch(resetImages());
                    dispatch(getIdcompany(id))
                    toast.success(t('save'));
                },300)
            }else{
                if(imgState?.length  < 1){
                    toast.warning(t("ban_chua_chon_anh"))
                }else{
                    try {
                        const response = await axios.post(`${base_url}company/add-company`, values, config, {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        if (response.data.success) {
                            formik.resetForm();
                            dispatch(resetImages());
                            toast.success(t('save'));
                        } else {
                            toast.error(t('dk_tb'));
                        }
                    } catch (error) {
                        console.error(error);
                        toast.error(t('dk_tb'));
                    }
                }
            }
        },
      });
    imgState?.forEach((i) => {
        img.push({
        public_id: i.public_id,
        url: i.url,
        });
    });
    useEffect(() => {
        if(id!=undefined)
            formik.values.images =  idCompany?.company?.images ;
        if(imgState.length > 0){
            formik.values.images =  img;
        }
      }, [img,imgState]);
     
    return (
    <div className='text-white' style={{background:"#333333"}}>
        <div className='container py-5'>
            <form onSubmit={formik.handleSubmit}>
                <div className='py-5'>
                    <h3 className="py-4" style={{fontSize:"50px"}}>
                        {t("tv")}
                    </h3>
                    <div className="row pt-5 pb-4">
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor={`brand`}
                        >
                            {t("ten_thuong_hieu")}
                        </label>
                        <div className="w70">
                            <input
                            type="text"
                            placeholder={t("ten_thuong_hieu")}
                            className="input_custom_ad w100"
                            value={formik.values.titleCompany}
                            onChange={formik.handleChange(`titleCompany`)}
                            onBlur={formik.handleBlur(`titleCompany`)}
                            name={`titleCompany`}
                            />
                            {formik.touched?.titleCompany &&
                            formik.errors?.titleCompany ? (
                            <div className="error mb-3">
                                {formik.errors.titleCompany}
                            </div>
                            ) : null}
                        </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-start align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor={`linhvuc`}
                        >
                            {t("loai_sp")}
                        </label>
                        <div
                            className="w70"
                            style={{
                            position: "relative",
                            }}
                        >
                            <select
                            className="form-select input_custom_ad w100"
                            aria-label="Default select example"
                            defaultValue={"Skincare"}
                            style={{
                                borderRadius: "24px",
                                background: "#1a1a1a",
                                color: "white",
                                outline: "none",
                                border: "2px solid #fff",
                            }}
                            value={formik.values.linhvuc}
                            onChange={formik.handleChange('linhvuc')}
                            onBlur={formik.handleBlur('linhvuc')}
                            name={`linhvuc`}
                            >
                            <option value={t("tonghop")}>{t("tonghop")}</option>
                            <option value={t("tptt")}>{t("tptt")}</option>
                            <option value={t("mypham")}>{t("mypham")}</option>
                            <option value={t("tpcn")}>{t("tpcn")}</option>
                            </select>
                            <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                right: "1rem", // Adjust as needed
                                transform: "translateY(-50%)",
                                pointerEvents: "none", // Allow clicks to pass through to the select
                                color: "#fff", // Match the color with the select
                            }}
                            >
                            <FaAngleDown />
                            </div>
                        </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor={"sdt"}
                        >
                            {t("sdt")}
                        </label>
                        <div className="w70">
                            <input
                            type="text"
                            placeholder={t("sdt")}
                            className="input_custom_ad w100"
                            value={formik.values.phoneCompany}
                            onChange={formik.handleChange(
                                `phoneCompany`
                            )}
                            onBlur={formik.handleBlur(
                                `phoneCompany`
                            )}
                            name={"sdt"}
                            />
                            {formik.touched?.phoneCompany &&
                            formik.errors?.phoneCompany ? (
                            <div className="error mb-3">
                                {formik.errors.phoneCompany}
                            </div>
                            ) : null}
                        </div>
                        </div>
                    </div>
                    <div className="row pb-4">
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor={`phutrach`}
                        >
                            {t("nguoi_phu_trach")}
                        </label>
                        <div className="w70">
                            <input
                            type="text"
                            placeholder={t("nguoi_phu_trach")}
                            className="input_custom_ad w100"
                            value={formik.values.phutrach}
                            onChange={formik.handleChange(
                                `phutrach`
                            )}
                            onBlur={formik.handleBlur(
                                `phutrach`
                            )}
                            name={`phutrach`}
                            />
                            {formik.touched?.phutrach &&
                            formik.errors?.phutrach ? (
                            <div className="error mb-3">
                                {formik.errors.phutrach}
                            </div>
                            ) : null}
                        </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor={`email`}
                        >
                            {t("email")}
                        </label>
                        <div className="w70">
                        <input
                            type="email"
                            name='email'
                            className="input_custom_ad w100"
                            value={formik.values.emailCompany}
                            onChange={formik.handleChange(`emailCompany`)}
                            onBlur={formik.handleBlur(`emailCompany`)}
                            />
                            {formik.touched?.emailCompany &&
                            formik.errors?.emailCompany ? (
                            <div className="error mb-3">
                                {formik.errors.emailCompany}
                            </div>
                            ) : null}
                        </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor={`imageProduct`}
                        >
                            {t("giay_kinh_doanh")}
                        </label>
                        <div
                            className="w70 d-flex justify-content-between"
                            style={{
                            height: "100%",
                            }}
                        >
                            <div
                            className="img_post w70 d-flex align-items-center justify-content-between text-center"
                            style={{
                                height: "100%",
                            }}
                            >
                            <section className="image-section">
                                {imgState &&
                                imgState.map((i, j) => (
                                    <div className="position-relative" key={j}>
                                    <button
                                        type="button"
                                        onClick={() => dispatch(delImg(i.public_id))}
                                        className="btn-close position-absolute"
                                        style={{
                                        top: "10px",
                                        right: "10px",
                                        }}
                                    ></button>
                                    <img
                                        src={i.url}
                                        alt=""
                                        width={200}
                                        height={200}
                                    />
                                    </div>
                                ))}
                            </section>
                            </div>
                            <Dropzone
                            onDrop={(acceptedFiles) => {
                                if (auth !== null) {
                                    dispatch(uploadImg(acceptedFiles))
                                } else {
                                toast.warning(t("ban_chua_dn"));
                                }
                            }}
                            >
                            {({ getRootProps, getInputProps }) => (
                                <div className="">
                                <div {...getRootProps()} className="upload-button">
                                    <input {...getInputProps()} />
                                    <button type="button" className="btn_upload">
                                    {t("tai_len")}
                                    </button>
                                </div>
                                </div>
                            )}
                            </Dropzone>
                        </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row pb-4 mt-5">
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor={"ID"}
                        >
                            ID
                        </label>
                        <div className="w70">
                            <input
                            type="text"
                            placeholder={"ID"} 
                            disabled
                            className="input_custom_ad w100 disable"
                            value={formik.values.idName}
                            />
                        </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor="password"
                        >
                            {t("mk")}
                        </label>
                        <div className="w70">
                            <input
                            disabled
                            type="password"
                            className="input_custom_ad w100 disable"
                            value={formik.values.password}
                            />
                        </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor="Confirm Password"
                        >
                            {t("cfPass")}
                        </label>
                        <div className="w70">
                            <input
                            type="password"
                            disabled
                            className="input_custom_ad w100 disable"
                            value={formik.values.password}
                            />
                        </div>
                        </div>
                    </div>
                    <div className="row pb-4">
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor={"Name"}
                        >
                            {t("name")}
                        </label>
                        <div className="w70">
                            <input
                            type="text"
                            disabled
                            className="input_custom_ad w100 disable"
                            value={formik.values.name}
                            />
                        </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor="email"
                        >
                            {t("mail")}
                        </label>
                        <div className="w70">
                            <input
                            type="email"
                            disabled
                            className="input_custom_ad w100 disable"
                            value={formik.values.email}
                            />
                        </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-start  align-items-center mb-3">
                        <label
                            className="me-3 w30"
                            htmlFor="SDT đăng nhập"
                        >
                            {t("sdtDN")}
                        </label>
                        <div className="w70">
                            <input
                            type="number"
                            disabled
                            className="input_custom_ad w100 disable"
                            value={formik.values.phone}
                            />
                        </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex gap-3 mt-3">
                    <button
                        style={{ width: "15%" }}
                        className="btn_submit"
                        onClick={() => {
                        }}
                        type="submit"
                    >
                        {t("gui_don")}
                    </button>
                </div>
            </form>
        </div>
    </div>
  )
}
