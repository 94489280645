import { useFormik } from "formik";
import React, {
  useState,
  useEffect,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { updatePass } from "../features/users/userSlice";
import live from "../images/live.jpg";
import tiktok from "../images/tiktok.png";
import ins from "../images/ins.png";
import { useNavigate } from "react-router-dom";
let userSchema = Yup.object().shape({
  password: Yup.string().required(
    "Yêu cầu nhập mật khẩu"
  ),
});
export default function ResetPassword({
  t,
}) {
  const navigate = useNavigate()
  const [cfPass, setCfPass] =
    useState("");
  const dispatch = useDispatch();
  const user = useSelector(
    (state) =>
      state.auth.forgotPass?.getUser
  );
  sessionStorage.setItem(
    "forgotpass",
    JSON.stringify(user)
  );
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: userSchema,
    onSubmit: (values) => {
      const getuser = JSON.parse(
        sessionStorage.getItem(
          "forgotpass"
        )
      );
      if (values.password !== cfPass) {
        toast.warning(
          t("mk_khong_khop")
        );
      } else {
        let data = {
          _id: user?._id,
          password: values,
        };
        dispatch(updatePass(data));
        setTimeout(() => {
          navigate('/login')
        }, 300);
      }
    },
  });
  // useEffect(() => {
  //   document.body.style.overflowY = "hidden";

  //   return () => {
  //     document.body.style.overflowY = "auto";
  //   };
  // }, []);
  return (
    <>
      <div
        className="container text-white py-5 mt-5"
        style={{
          position: "relative",
          zIndex: 10000,
        }}>
        <div className="pb-5">
          <h1>{t("qmk")}</h1>
          <p
            className="mb-3"
            style={{
              fontSize: "18px",
            }}>
            {t("ban_qmk")}
          </p>
        </div>
        <form
          onSubmit={
            formik.handleSubmit
          }>
          <div className="row">
            <div className="col-4">
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={
                    formik.values
                      .password
                  }
                  onChange={formik.handleChange(
                    "password"
                  )}
                  onBlur={formik.handleBlur(
                    "password"
                  )}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label">
                  {t("nhap_lai")}{" "}
                  password*
                </label>
                <input
                  type="password"
                  className="form-control input_custom"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={cfPass}
                  onChange={(e) =>
                    setCfPass(
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className="col-4"></div>
          </div>
          <div className="d-flex justify-content-start mt-3">
            <button
              type="submit"
              className="btn_submit">
              {t("tieptheo")}
            </button>
          </div>
        </form>
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: "30px",
          zIndex: 9999,
        }}>
        <div>
          <img src={ins} alt="" />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "-240px",
            right: "20px",
          }}>
          <img src={tiktok} alt="" />
        </div>
      </div>
    </>
  );
}
