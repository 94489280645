import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import maketingService from "./maketingService";

export const addMaketing = createAsyncThunk(
  "maketing/maketing",
  async (data, thunkApi) => {
    try {
      return await maketingService.addMaketing(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getAllMaketing = createAsyncThunk(
  "maketing/get-all",
  async (thunkApi) => {
    try {
      return await maketingService.getAllMaketing();
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getIdMaketing = createAsyncThunk(
  "maketing/get-id",
  async (id,thunkApi) => {
    try {
      return await maketingService.getIdMaketing(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const updateProcessMaketing = createAsyncThunk(
  "maketing/update",
  async (data, thunkApi) => {
    try {
      return await maketingService.updateProcess(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
const initialState = {
  maketing: [],
  idMaketing:[],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const maketingSlice = createSlice({
  name: "maketing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addMaketing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMaketing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(addMaketing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(getAllMaketing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMaketing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.maketing = action.payload;
      })
      .addCase(getAllMaketing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.maketing = null;
      })

      .addCase(getIdMaketing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIdMaketing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.idMaketing = action.payload;
      })
      .addCase(getIdMaketing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.idMaketing = null;
      })


      .addCase(updateProcessMaketing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProcessMaketing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
     
      })
      .addCase(updateProcessMaketing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
       
      });
  },
});
export const { resetImages } = maketingSlice.actions;
export default maketingSlice.reducer;
